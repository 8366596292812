import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import Select from 'react-select'

import {Accordion, Card} from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faMobileAlt, faSearch } from '@fortawesome/free-solid-svg-icons'

import {conselhos, ufs, consultaTempos} from '../../_constants'
import {getEnd, onlyNumbers, myMask, formatDateDMY} from '../functions'

import Selection from '../../js/selection'

import Horarios from './horarios'
import Datetime from 'react-datetime'
import moment from 'moment'

require("moment/min/locales.min");
moment.locale('pt-br')

//import Axios from 'axios'
//Axios.defaults.withCredentials = true

const convertDataToSelectReact = (obj, value, label) => { 
    let labels = []
    obj.map(i => {labels.push({value: i[value] || i._id, label: i[label]}); return true })
    return labels
}
const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : { ...base, backgroundColor: '#f2921d' };
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
  };

export class FormDoctor extends Component {
    state={
        aba             :   1,
        abaHorario      :   0,
        _id             :   "",
        nome            :   "",
        cpf             :   "",
        nascimento      :   null,
        telefone        :   "",
        celular         :   "",
        crm             :   "",
        conselhoTipo    :   "",
        conselhoDoc     :   "",
        plano           :   [],
        especialidade   :   [],
        endereco        :   {
                cep         :   "",
                rua         :   "",
                numero      :   "",
                bairro      :   "",
                cidade      :   "",
                estado      :   "",
                obs         :   ""
            },
        empresa         :   {
                razao       :   "",
                cnpj        :   "",
                estadual    :   "",
                municipal   :   "",
                fundacao    :   null,
                endereco    :   {
                    cep         :   "",
                    rua         :   "",
                    numero      :   "",
                    bairro      :   "",
                    cidade      :   "",
                    estado      :   "",
                    obs         :   ""
                }
            },
        horarios            :   {
                encaixe         :   false,
                tempoConsulta   :   30,
                dias            :   [0, 2, 2, 2, 2, 2, 2],
                intervaloIni    :   [null, null, null, null, null, null, null],
                intervaloFim    :   [null, null, null, null, null, null, null],
                horaIni         :   '2019-01-01T07:45:00',
                horaFim         :   '2019-01-01T22:00:00',
                intervalos      :   []
            },
        conselhos       :   conselhos,
        masks           :   {telefone:true, celular:false}, //true = telefone e false=celular
    }
    componentDidMount(){
        const {data} = this.props
        if(data){
            let stt             = {...data},
                plano           = [],
                especialidade   = [],
                e;
            stt["masks"] = this.state.masks

            stt["aba"]=1

            for(e in stt.especialidade){
                especialidade.push({value:stt.especialidade[e], label:stt.especialidade[e]})
            }
            for(e in stt.plano){
                plano.push({value:stt.plano[e], label:stt.plano[e]})
            }
            stt.especialidade = especialidade

            for(e in stt.planos){
                plano.push({value:stt.planos[e], label:stt.planos[e]})
            }
            stt.plano = plano
            if(!stt.horarios["intervaloIni"]) stt.horarios["intervaloIni"]=["", "", "", "", "", "",""]
            if(!stt.horarios["intervaloFim"]) stt.horarios["intervaloFim"]=["", "", "", "", "", "",""]
            //console.log(stt)
            this.setState(stt)
        }
        Selection.create({
            // Class for the selection-area
            class: 'selection',
        
            // All elements in this container can be selected
            selectables: ['.box-wrap > div'],
        
            // The container is also the boundary in this case
            boundaries: ['.box-wrap']
        })
    }

    handleTypePhone = (event) => {
        let stt             = this.state,
            {masks}         = stt,
            {name, value}   = event.currentTarget;

        masks[name]     = value==="1"
        stt.masks[name]!==masks[name] && this.setState({masks})
        
        //delete stt.masks
        this.props.funcUpd({...stt})
    }

    onEncaixeClickOLD = event => {
        const {name} = event.currentTarget
        let encaixe = name==="sim" ? true : false
        if(this.state.encaixe !== encaixe) {
            this.setState({encaixe})
            this.props.funcUpd({...this.state, encaixe })
        }
    }

    onEncaixeClick = event => {
        const {name} = event.currentTarget
        let encaixe = name==="sim" ? true : false
        if(this.state.horarios.encaixe !== encaixe) {
            this.setState({horarios:{...this.state.horarios, encaixe}})
            this.props.funcUpd({...this.state, horarios:{...this.state.horarios, encaixe} })
        }
    }

    onInputChange = (event) => {
        let {name, value, alt} = event.target,
            obj = name.split(".")
        
        if(alt==="tel")         value = onlyNumbers(value) //.replace(/\(|\)|\s|-/g,'')
        else if(name==="cpf")   value = onlyNumbers(value) //.replace(/\.|-/g,'')
        else if(name==="crm")   value = onlyNumbers(value) //.replace(/\./g,'')


        //console.log(name,value, alt)
        if(obj.length===1){
            this.setState({ [name]: value });
            this.props.funcUpd({...this.state, [name]: value })
        }else if(obj.length===2){
            let stt = this.state,
                item = {...stt[obj[0]]}

            item[obj[1]] = value
            
            this.setState({ [obj[0]]: item });
            this.props.funcUpd({...this.state, [obj[0]]: item })
        }else if(obj.length===3){
            let stt     = this.state,
                item    = {...stt[obj[0]]},
                sub     = {...item[obj[1]]}

            sub[obj[2]] = value
            item = {...item, [obj[1]]: sub }
            
            this.setState({ [obj[0]]: item });
            this.props.funcUpd({...this.state, [obj[0]]: item })
        }
        
    }

    onEspecialidadesChange = (value, { name }) => { //{action, removedValue, }
        this.setState({ [name]: value });
        this.props.funcUpd({...this.state, [name]: value })
    }

    onPlanosChange = (value, { name }) => { //{action, removedValue, }
        this.setState({ [name]: value });
        this.props.funcUpd({...this.state, [name]: value })
    }

    handleAbaClick = event => {
        const {name} = event.target
        this.setState({aba: name*1})
    }

    handleAbaQuadroClick = event => {
        const {name} = event.target
        this.setState({abaHorario: name*1})
    }


    handleCepClick = event => {
        const {name, value} = event.currentTarget,
                app         = this,
                stt         = app.state;
               
        if(name.length && onlyNumbers(value).length === 8){
            const getEnder = async () => {
                let endereco  = await getEnd(value),
                    obj         = name.split(".")
                
                if(obj.length===1){
                    app.setState({[name]:{...stt[name],...endereco}})
                    this.props.funcUpd({...stt, [name]:{...stt[name],...endereco}})
                }else if(obj.length===2){
                    let item    = {...stt[obj[0]]},
                        sub     = {...item[obj[1]],...endereco} 
                        
                    app.setState({[obj[0]]:{...item, [obj[1]]: sub}})
                    this.props.funcUpd({...stt, [obj[0]]:{...item, [obj[1]]: sub} })
                }
            }
            getEnder()
        }

    }
    
    handleIntervaloChange = (event) => {
        const {name, value} = event.currentTarget,
            stt = this.state,
            parts = name.split(".")
        let {horarios} = stt,
            item =  horarios[parts[0]]
        //console.log(horarios,parts,value)
        item[parts[1]*1]=value

        this.setState({horarios:{...horarios, [parts[0]]: item }})

        //console.log(name, value)
    }
    
    handleHoraIniChangeOLD =  event => {
        const value = event && event.value ? event.value._d : ""
        let {intervalos} = this.state.horarios,
            {temp}       = intervalos,
            idx          = event.input.props.className.split('.');
        
        //console.log(idx, event)
        if(idx.length>2){
            idx = idx[2] * 1
            temp[idx]={...temp[idx], ini: value}
            //temp =[{...temp[idx], ini: value}]
            intervalos = {...intervalos, temp}

            this.setState({horarios:{...this.state.horarios, intervalos }})
            this.props.funcUpd({...this.state, horarios:{...this.state.horarios, intervalos }})
        }
    }

    handleHoraFimChangeOLD =  event => {
        const value = event && event.value ? event.value._d : this.state.horarios.horaFim
        let {intervalos} = this.state.horarios,
            {temp}       = intervalos,
            idx          = event.input.props.className.split('.');
            
        console.log(idx, event)
        if(idx.length>2){
            idx = idx[2] * 1
            temp[idx]={...temp[idx], fim: value}
            //temp =[{...temp[idx], fim: value}]
            intervalos = {...intervalos, temp}

            this.setState({horarios:{...this.state.horarios, intervalos }})
            this.props.funcUpd({...this.state, horarios:{...this.state.horarios, intervalos}})
        }
    }

    handleTempDataChange =  event => {
        const value = event && event.value ? event.value._d : ""
        let {intervalos} = this.state.horarios,
            {temp}       = intervalos,
            idx          = event.input.props.className.split('.');
        
        //console.log(idx, value)
        if(idx.length>3){
            let field = idx[3]
            idx = idx[2] * 1
            temp[idx]={...temp[idx], [field]: value}
            intervalos = {...intervalos, temp}

            this.setState({horarios:{...this.state.horarios, intervalos }})
            this.props.funcUpd({...this.state, horarios:{...this.state.horarios, intervalos }})
        }
    }

    onTipoHorarioChange =  (obj,{name}) => {
        const value = obj
        let {intervalos} = this.state.horarios,
            {temp}       = intervalos,
            idx          = name.split(".")[2]*1;
        //delete value.idx
        console.log(idx, value)
        temp[idx]={...temp[idx], tipo: value}
        //temp =[{...temp[idx], tipo: value}]
        intervalos = {...intervalos, temp}

        this.setState({horarios:{...this.state.horarios, intervalos}})
        this.props.funcUpd({...this.state, horarios:{...this.state.horarios, intervalos}})
    }

    /*onTipoHorarioChangeOLD =  obj => { console.log(obj)
        const value = obj
        let {intervalos} = this.state.horarios,
            {temp}       = intervalos;
        
        temp =[{...temp[0], tipo: value}]
        intervalos = {...intervalos, temp}

        this.setState({horarios:{...this.state.horarios, intervalos}})
        this.props.funcUpd({...this.state, horarios:{...this.state.horarios, intervalos}})
    }
    handleNascimentoChange = event => { console.log(event)
        const {value, input} = event,
            {props} = input,
            {mask, className} = props;

        let nascimento //= this.state.nascimento
        if(value._isValid){
            if(value._d){
                nascimento = value._d
            }
        }else{
            nascimento = myMask(value,mask)
        }
        this.setState({nascimento})
        this.props.funcUpd({...this.state, nascimento})
    }
    */
    handleDateChange = event => {
        const {value, input} = event,
            {props} = input,
            {mask, name} = props;

        let dt
        if(value._isValid){
            if(value._d){
                dt = formatDateDMY(value._d)
            }
        }else{
            dt = myMask(value,mask)
        }
        this.setState({[name]: dt})
        this.props.funcUpd({...this.state, [name]: dt})
    }
    
    handleFundacaoChange = event => {
        this.setState({empresa: {...this.state.empresa, fundacao: event && event._isValid ? event._d : this.state.empresa.fundacao}})
        this.props.funcUpd({...this.state, empresa: {...this.state.empresa, fundacao: event && event._isValid ? event._d : this.state.empresa.fundacao}})
    }

    onEstadoChange = sel => {
        this.setState({endereco:{...this.state.endereco, estado: sel.label}})
        this.props.funcUpd({...this.state, endereco:{...this.state.endereco, estado: sel.label}})
    }

    onTempoChange = sel => {
        this.setState({horarios:{...this.state.horarios, tempoConsulta: sel.value}})
        this.props.funcUpd({...this.state, horarios:{...this.state.horarios, tempoConsulta: sel.value}})
    }

    onEmpresaEstadoChange = sel => {
        this.setState({empresa:{...this.state.empresa, endereco: {...this.state.endereco, estado: sel.label}}})
        this.props.funcUpd({...this.state, empresa:{...this.state.empresa, endereco: {...this.state.endereco, estado: sel.label}}})
    }

    handleDayClick = event => {
        const {name} = event.currentTarget,
            stt     = this.state
        let {dias}  = stt.horarios
        dias[name*1] = dias[name*1]+1 > 2 ? 0 : dias[name*1]+1
        this.setState({horarios:{...stt.horarios, dias}})
        this.props.funcUpd({...stt, horarios:{...stt.horarios, dias}})
    }

    weekDayClass = val => val ? (val===1 ? "warning" : "success") : "danger"

    handleHorariosUpd_OLD = (item, action) => {
        const stt           = this.state,
            {horarios}      =   stt;
        let {intervalos}    = horarios,
            alteracao       = [];
        if(intervalos.length){
            alteracao = intervalos.filter(i => i.id !== item.id)
            if(action==="UPD") alteracao.push(item)
            intervalos = alteracao
        }else intervalos = [item]
        
        console.log(item, intervalos)
        //return
        //console.log(intervalos)
        this.setState({horarios:{...horarios, intervalos}})
        this.props.funcUpd({...stt, horarios:{...stt.horarios, intervalos}})
    }

    handleHorariosUpdFixo = (item, action, index=0) => {
        const stt           = this.state,
            {horarios}      =   stt;
        let {intervalos}    = horarios,
            fixo            = intervalos.fixo || {},
            {range}         = fixo && fixo[index] ? fixo[index] : {range:[]},
            alteracao       = [];
        if(range && range.length){
            alteracao = range.filter(i => i.id !== item.id)
            if(action==="UPD") alteracao.push(item)
            range = alteracao
        }else range = [item]
        fixo[index] = {range}
        intervalos = {...intervalos, fixo}
        
        this.setState({horarios:{...horarios, intervalos}})
        this.props.funcUpd({...stt, horarios:{...stt.horarios, intervalos}})
    }

    handleHorariosUpdTemp = (item, action, index=0) => {
        const stt           = this.state,
            {horarios}      = stt;
        let {intervalos}    = horarios,
            temp            = intervalos.temp || {},
            {range}         = temp && temp[index] ? temp[index] : {range:[]},
            alteracao       = [];
        if(range && range.length){
            alteracao = range.filter(i => i.id !== item.id)
            if(action==="UPD") alteracao.push(item)
            range = alteracao
        }else range = [item]
        temp[index] = {...temp[index],range} 
        intervalos = {...intervalos, temp}

        this.setState({horarios:{...horarios, intervalos}})
        this.props.funcUpd({...stt, horarios:{...stt.horarios, intervalos}})
    }
/*
    handleHorariosOn = (range) => {
        let stt             = this.state,
            {horarios}      = stt,
            {intervalos}    = horarios;

            intervalos      = {...intervalos, fixo: [{range}]}
            
        this.setState({horarios:{...horarios, intervalos}})
        this.props.funcUpd({...stt, horarios:{...stt.horarios, intervalos}})
    }

    handleHorariosOff = (range) => {
        let stt             = this.state,
            {horarios}      = stt,
            {intervalos}    = horarios;

            intervalos      = {...intervalos, temp: [{range, ini: stt.horarios.horaIni, fim: stt.horarios.horaFim, tipo: stt.horarios.tipo}]}
            
        this.setState({horarios:{...horarios, intervalos}})
        this.props.funcUpd({...stt, horarios:{...stt.horarios, intervalos}})
    }
*/

    render() {
        const { lists, err }    = this.props,
            {   nome,
                cpf,
                nascimento,
                telefone,
                celular,
                especialidade,
                plano,
                crm,
                conselhos,
                conselhoTipo,
                conselhoDoc,
                aba,
                abaHorario,
                endereco,
                empresa,
                horarios,
                masks         } = this.state,
            abas                = [
                {id:1, func: ()=>{this.handleAbaClick({target:{name:1}})}, desc:"Dados Pessoais"},
                {id:2, func: ()=>{this.handleAbaClick({target:{name:2}})}, desc:"Dados de Atendimento"},
                {id:3, func: ()=>{this.handleAbaClick({target:{name:3}})}, desc:"Horários Contínuos"},
                {id:4, func: ()=>{this.handleAbaClick({target:{name:4}})}, desc:"Horários Temporários"},
                {id:5, func: ()=>{this.handleAbaClick({target:{name:5}})}, desc:"Endereço Residencial"},
                {id:6, func: ()=>{this.handleAbaClick({target:{name:6}})}, desc:"Dados Jurídicos"},
                {id:7, func: ()=>{this.handleAbaClick({target:{name:7}})}, desc:"Atestados"},
            ];

        //console.log("horarios.intervalos",horarios.intervalos)
        return (
            <form id="form" className="box flex">
                <div className="flex-width-25">
                    <ul className="menu-list">
                        { abas.map(a => <li key={a.id} onClick={a.func} className={"menu-list-item"+(aba===a.id ? " active" : "")}><b className="circle">{a.id}</b> {a.desc}</li>) }
                    </ul>
                </div>
                <div className="flex-width-75">
                    <div className={"box-bordered" + (aba===1 ? "" : " d-none")}>
                        <label className= {"col-xs-12 col-sm-6"+(err.nome ? " err": "")}>
                            <b>Nome<sup>*</sup></b>
                            <input
                                name                ="nome"
                                placeholder         =""
                                onChange            ={this.onInputChange}
                                value               ={nome}
                            />
                        </label>
                        <label className={"col-xs-12 col-sm-3"+(err.cpf ? " err": "")}>
                            <b>CPF<sup>*</sup></b>
                            <NumberFormat 
                                name                ="cpf"
                                alt                 ="text"
                                format              ={"###.###.###-##"}
                                onChange            ={this.onInputChange}
                                value               ={cpf}
                                //prefix              ="R$ "
                                //decimalSeparator    =","
                                //thousandSeparator   ={"."}
                            />
                        </label>
                        <label className={"col-xs-12 col-sm-3"+(err.nascimento ? " err": "")}>
                            <b>Data de Nascimento<sup></sup></b>
                            <Datetime 
                                timeFormat          ={false}
                                locale              ="pt-br" 
                                name                ="nascimento"
                                mask                ="99/99/9999"
                                inputProps          ={{maxLength:10}}
                                value               ={nascimento instanceof Date && !isNaN(nascimento.valueOf()) ? new Date(nascimento) : nascimento}
                                //timeConstraints     ={timeConfig}
                                onChange            ={this.handleDateChange}
                            />
                        </label>
                        <label className={"d-none col-xs-12 col-sm-1"+(err.nascimento ? " err": "")}>
                            <img width="60" height="60" />
                        </label>
                        <label className={"col-xs-12 col-sm-6"+(err.celular ? " err": "")}>
                            <b>Telefone<sup>*</sup></b>
                            <div className="input-group">
                                <div className="btns">
                                    <button
                                        type        ="button"
                                        name        ="celular"
                                        value         ="1"
                                        onClick     ={this.handleTypePhone}
                                        className   ={"btn "+(masks.celular?"btn-success":"btn-default")}
                                    >
                                        <FontAwesomeIcon icon={faPhoneAlt} />
                                    </button>
                                    <button
                                        type        ="button"
                                        name        ="celular"
                                        value         ="0"
                                        onClick     ={this.handleTypePhone}
                                        className   ={"btn "+(masks.celular?"btn-default":"btn-success")}
                                    >
                                        <FontAwesomeIcon icon={faMobileAlt} />
                                    </button>
                                </div>
                                <NumberFormat 
                                    name                ="celular"
                                    alt                 ="tel"
                                    format              ={ masks.celular ? "(##) ####-####" : "(##) # ####-####"}
                                    onChange            ={this.onInputChange}
                                    value               ={celular}
                                    className           ="form-control"
                                    //prefix              ="R$ "
                                    //decimalSeparator    =","
                                    //thousandSeparator   ={"."}
                                />
                            </div>
                        </label>
                        <label className={"col-xs-12 col-sm-6"}>
                            <b>Telefone<sup></sup></b>
                            <div className="input-group">
                                <div className="btns">
                                    <button
                                        type        ="button"
                                        name        ="telefone"
                                        value         ="1"
                                        onClick     ={this.handleTypePhone}
                                        className   ={"btn "+(masks.telefone?"btn-success":"btn-default")}
                                    >
                                        <FontAwesomeIcon icon={faPhoneAlt} />
                                    </button>
                                    <button
                                        type        ="button"
                                        name        ="telefone"
                                        value         ="0"
                                        onClick     ={this.handleTypePhone}
                                        className   ={"btn "+(masks.telefone?"btn-default":"btn-success")}
                                    >
                                        <FontAwesomeIcon icon={faMobileAlt} />
                                    </button>
                                </div>
                                <NumberFormat 
                                    name                ="telefone"
                                    alt                 ="tel"
                                    format              ={ masks.telefone ? "(##) ####-####":"(##) # ####-####"}
                                    onChange            ={this.onInputChange}
                                    value               ={telefone}
                                    //prefix              ="R$ "
                                    //decimalSeparator    =","
                                    //thousandSeparator   ={"."}
                                    className="form-control"
                                />
                            </div>
                        </label>
                    </div>
                
                    
                    <div className={"box-bordered" + (aba===2 ? "" : " d-none")}>
                        <label className= {"col-xs-12 col-sm-6"+(err.especialidade ? " err": "")}>
                            <b>Especialidades<sup>*</sup></b>
                            <Select
                                isMulti
                                value                   ={especialidade}
                                //isClearable           ={this.state.value.some(v => !v.isFixed)}
                                styles                  ={styles}
                                onChange                ={this.onEspecialidadesChange}
                                placeholder             ="Selecione..."
                                name                    ="especialidade"
                                className               ="mySelect"
                                classNamePrefix         ="_"
                                noOptionsMessage        ={() => "Opções não disponíveis"}
                                options                 ={convertDataToSelectReact(lists.especialidades,"label","label")}
                            />
                        </label>
                        <label className= {"col-xs-6 col-sm-4"+(err.conselhoTipo ? " err": "")}>
                            <b>Conselho<sup></sup></b>
                            <Select
                                value                   ={conselhoTipo && conselhoTipo.label ? conselhoTipo : {label:"CRM"}}
                                //isClearable           ={this.state.value.some(v => !v.isFixed)}
                                styles                  ={styles}
                                onChange                ={this.onEspecialidadesChange}
                                placeholder             ="Selecione..."
                                name                    ="conselhoTipo"
                                className               ="mySelect"
                                classNamePrefix         ="_"
                                noOptionsMessage        ={() => "Opções não disponíveis"}
                                options                 ={convertDataToSelectReact(conselhos,"label","label")}
                            />
                        </label>
                        <label className={"col-xs-6 col-sm-2"+(err.conselhoDoc ? " err": "")}>
                            <b>{conselhoTipo && conselhoTipo.label ? conselhoTipo.label : "CRM"}<sup></sup></b>
                            <NumberFormat 
                                name                ="conselhoDoc"
                                alt                 ="text"
                                //format              ={telefone.length<10 ? "(##) ####-####":"(##) # ####-####"}
                                onChange            ={this.onInputChange}
                                value               ={conselhoDoc || crm}
                                //prefix              ="R$ "
                                decimalSeparator    =","
                                thousandSeparator   ={"."}
                            />
                        </label>
                        
                        <label className= {"col-xs-12 col-sm-8"+(err.plano ? " err": "")}>
                            <b>Planos<sup></sup></b>
                            <Select
                                isMulti
                                value                   ={plano}
                                //isClearable           ={this.state.value.some(v => !v.isFixed)}
                                styles                  ={styles}
                                onChange                ={this.onPlanosChange}
                                placeholder             ="Selecione..."
                                name                    ="plano"
                                className               ="mySelect"
                                classNamePrefix         ="_"
                                noOptionsMessage        ={() => "Opções não disponíveis"}
                                options                 ={convertDataToSelectReact(lists.planos,"label","label")}
                            />
                        </label>
                        
                        <div className= {"gLabel col-xs-6 col-sm-2"}>
                            <b>Permite encaixe?</b>
                            <div className="btn-group btn-group-justified" role="group" aria-label="encaixe">
                                <div className="btn-group"><button
                                    type="button"
                                    name="sim"
                                    onClick={this.onEncaixeClick}
                                    className={"btn " + (horarios.encaixe? "btn-success" : " btn-default")}>SIM</button></div>
                                <div className="btn-group">
                                <button
                                    type="button"
                                    name="nao"
                                    onClick={this.onEncaixeClick}
                                    className={"btn " + (horarios.encaixe? "btn-default" : "btn-danger")}>NÃO</button>
                                </div>
                            </div>
                        </div>

                        <label className= {"col-xs-6 col-sm-2 "+(err.horarios && err.horarios.tempoConsulta ? " err": "")}>
                            <b>Tempo<sup></sup></b>
                            <Select
                                value                   ={horarios.tempoConsulta? {label: horarios.tempoConsulta+" min"}: ""}
                                //isClearable           ={this.state.value.some(v => !v.isFixed)}
                                styles                  ={styles}
                                onChange                ={this.onTempoChange}
                                placeholder             ="Selecione..."
                                name                    ="horarios.tempoConsulta"
                                className               ="mySelect"
                                classNamePrefix         ="_"
                                noOptionsMessage        ={() => "Opções não disponíveis"}
                                options                 ={consultaTempos}
                            />
                        </label>

                    </div>
                
                    <div className={"box-bordered" + (aba===3 ? "" : " d-none")}>
                        <Horarios funcUpd={this.handleHorariosUpdFixo} dados={horarios.intervalos.fixo && horarios.intervalos.fixo[0] && horarios.intervalos.fixo[0].range ? horarios.intervalos.fixo[0].range:[]} idx={0}/>
                    </div>

                    <div className={"box-bordered" + (aba===4 ? "" : " d-none")}>
                        <div className="row">
                            <label className={"col-xs-12 col-sm-4"+(err.horarios && err.horarios.horaIni ? " err": "")}>
                                <b>Início<sup></sup></b>
                                <Datetime 
                                    timeFormat          ={false}
                                    closeOnSelect       ={true}
                                    locale              ="pt-br" 
                                    className           ="horarios.temp.0.ini"
                                    value               ={horarios && horarios.intervalos.temp && horarios.intervalos.temp[0] && horarios.intervalos.temp[0].ini!=="" ? new Date(horarios.intervalos.temp[0].ini) : ""}
                                    //timeConstraints     ={timeConfig}
                                    onChange            ={this.handleTempDataChange}
                                />
                            </label>

                            <label className={"col-xs-12 col-sm-4"+(err.horarios && err.horarios.horaFim ? " err": "")}>
                                <b>Término<sup></sup></b>
                                <Datetime 
                                    timeFormat          ={false}
                                    closeOnSelect       ={true}
                                    locale              ="pt-br" 
                                    className           ="horarios.temp.0.fim"
                                    value               ={horarios && horarios.intervalos.temp && horarios.intervalos.temp[0] && horarios.intervalos.temp[0].fim !=="" ? new Date(horarios.intervalos.temp[0].fim) : ""}
                                    //timeConstraints     ={timeConfig}
                                    onChange            ={this.handleTempDataChange}
                                />
                            </label>

                            <label className={"col-xs-12 col-sm-4"+(err.horarios && err.horarios.horaFim ? " err": "")}>
                                <b>Tipo<sup></sup></b>
                                <Select
                                    value                   ={horarios && horarios.intervalos.temp && horarios.intervalos.temp[0] ? horarios.intervalos.temp[0].tipo : ""}
                                    isClearable             ={true}
                                    styles                  ={styles}
                                    onChange                ={this.onTipoHorarioChange}
                                    placeholder             ="Selecione..."
                                    name                    ="horarios.temp.0.tipo"
                                    className               ="mySelect"
                                    classNamePrefix         ="_"
                                    noOptionsMessage        ={() => "Opções não disponíveis"}
                                    options                 ={[{value:1, label:"Disponibilidade"},{value:0, label:"Indisponibilidade"}]}
                                />
                            </label>
                        </div>
                        <br/>
                        <Horarios funcUpd={this.handleHorariosUpdTemp} dados={horarios.intervalos.temp && horarios.intervalos.temp[0] && horarios.intervalos.temp[0].range ? horarios.intervalos.temp[0].range : []} idx={0}/>
                    </div>

                    <div className={"box-bordered" + (aba===5 ? "" : " d-none")}>
                        <label className= {"col-xs-12 col-sm-2"+(err.endereco && err.endereco.cep ? " err": "")}>
                            <b>CEP<sup></sup></b>
                            <div className="iGroup">
                                <NumberFormat 
                                    className           ="form-control"
                                    name                ="endereco.cep"
                                    alt                 ="text"
                                    format              ={"#####-###"}
                                    onChange            ={this.onInputChange}
                                    value               ={endereco.cep}
                                />
                                <button type="button" name="endereco" className="input-group-addon fRight" onClick={this.handleCepClick} value={endereco.cep}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                            </div>
                        </label>
                        
                        <label className= {"col-xs-12 col-sm-8"+(err.endereco && err.endereco.rua ? " err": "")}>
                            <b>Rua<sup></sup></b>
                            <input
                                name                ="endereco.rua"
                                placeholder         =""
                                onChange            ={this.onInputChange}
                                value               ={endereco.rua}
                            />
                        </label>
                        
                        <label className= {"col-xs-12 col-sm-2"+(err.endereco && err.endereco.numero ? " err": "")}>
                            <b>Número<sup></sup></b>
                            <input
                                name                ="endereco.numero"
                                placeholder         =""
                                onChange            ={this.onInputChange}
                                value               ={endereco.numero}
                            />
                        </label>
                        
                        <label className= {"col-xs-12"+(err.endereco && err.endereco.obs ? " err": "")}>
                            <b>Complemento<sup></sup></b>
                            <input
                                name                ="endereco.obs"
                                placeholder         =""
                                onChange            ={this.onInputChange}
                                value               ={endereco.obs}
                            />
                        </label>
                        
                        <label className= {"col-xs-12 col-sm-5"+(err.endereco && err.endereco.bairro ? " err": "")}>
                            <b>Bairro<sup></sup></b>
                            <input
                                name                ="endereco.bairro"
                                placeholder         =""
                                onChange            ={this.onInputChange}
                                value               ={endereco.bairro}
                            />
                        </label>
                        
                        <label className= {"col-xs-12 col-sm-5"+(err.endereco && err.endereco.cidade ? " err": "")}>
                            <b>Cidade<sup></sup></b>
                            <input
                                name                ="endereco.cidade"
                                placeholder         =""
                                onChange            ={this.onInputChange}
                                value               ={endereco.cidade}
                            />
                        </label>
                        
                        <label className= {"col-xs-12 col-sm-2"+(err.endereco && err.endereco.estado ? " err": "")}>
                            <b>Estado<sup></sup></b>
                            <Select
                                value                   ={endereco && endereco.estado? {label:endereco.estado} : ""}
                                //isClearable           ={this.state.value.some(v => !v.isFixed)}
                                styles                  ={styles}
                                onChange                ={this.onEstadoChange}
                                placeholder             ="Selecione..."
                                name                    ="conselhoTipo"
                                className               ="mySelect"
                                classNamePrefix         ="_"
                                noOptionsMessage        ={() => "Opções não disponíveis"}
                                options                 ={convertDataToSelectReact(ufs,"label","label")}
                            />
                        </label>
                    </div>
                
                    <div className={"box-bordered" + (aba===6 ? "" : " d-none")}>
                        <label className= {"col-xs-12 col-sm-6"+(err.empresa && err.empresa.razao ? " err": "")}>
                            <b>Razão Social<sup></sup></b>
                            <input
                                name                ="empresa.razao"
                                placeholder         =""
                                onChange            ={this.onInputChange}
                                value               ={empresa.razao}
                            />
                        </label>

                        <label className={"col-xs-12 col-sm-3"+(err.empresa && err.empresa.cnpj ? " err": "")}>
                            <b>CNPJ<sup></sup></b>
                            <NumberFormat 
                                name                ="empresa.cnpj"
                                alt                 ="text"
                                format              ={"##.###.###/####-##"}
                                onChange            ={this.onInputChange}
                                value               ={empresa.cnpj}
                            />
                        </label>

                        <label className={"col-xs-12 col-sm-3"+(err.empresa && err.empresa.fundacao ? " err": "")}>
                            <b>Data de Fundação<sup></sup></b>
                            <Datetime 
                                timeFormat          ={false}
                                locale              ="pt-br" 
                                className           ="fundacao"
                                value               ={empresa.fundacao? new Date(empresa.fundacao) : ""}
                                //timeConstraints     ={timeConfig}
                                onChange            ={this.handleFundacaoChange}
                            />
                        </label>

                        <label className= {"col-xs-12 col-sm-6"+(err.empresa && err.empresa.estadual ? " err": "")}>
                            <b>Inscrição Estadual<sup></sup></b>
                            <input
                                name                ="empresa.estadual"
                                placeholder         =""
                                onChange            ={this.onInputChange}
                                value               ={empresa.estadual}
                            />
                        </label>
                        
                        <label className= {"col-xs-12 col-sm-6"+(err.empresa && err.empresa.municipal ? " err": "")}>
                            <b>Inscrição Municipal<sup></sup></b>
                            <input
                                name                ="empresa.municipal"
                                placeholder         =""
                                onChange            ={this.onInputChange}
                                value               ={empresa.municipal}
                            />
                        </label>
                        
                        <label className= {"col-xs-12 col-sm-2"+(err.empresa && err.empresa.endereco && err.empresa.endereco.cep ? " err": "")}>
                            <b>CEP<sup></sup></b>
                            <div className="iGroup">
                                <NumberFormat 
                                    className           ="form-control"
                                    name                ="empresa.endereco.cep"
                                    alt                 ="text"
                                    format              ={"#####-###"}
                                    onChange            ={this.onInputChange}
                                    value               ={empresa.endereco.cep}
                                />
                                <button type="button" name="empresa.endereco" className="input-group-addon fRight" onClick={this.handleCepClick} value={empresa.endereco.cep}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                            </div>
                        </label>
                        
                        <label className= {"col-xs-12 col-sm-8"+(err.empresa && err.empresa.endereco && err.empresa.endereco.rua ? " err": "")}>
                            <b>Rua<sup></sup></b>
                            <input
                                name                ="empresa.endereco.rua"
                                placeholder         =""
                                onChange            ={this.onInputChange}
                                value               ={empresa.endereco.rua}
                            />
                        </label>
                        
                        <label className= {"col-xs-12 col-sm-2"+(err.empresa && err.empresa.endereco && err.empresa.endereco.numero ? " err": "")}>
                            <b>Número<sup></sup></b>
                            <input
                                name                ="empresa.endereco.numero"
                                placeholder         =""
                                onChange            ={this.onInputChange}
                                value               ={empresa.endereco.numero}
                            />
                        </label>
                        
                        <label className= {"col-xs-12"+(err.empresa && err.empresa.endereco && err.empresa.endereco.obs ? " err": "")}>
                            <b>Complemento<sup></sup></b>
                            <input
                                name                ="empresa.endereco.obs"
                                placeholder         =""
                                onChange            ={this.onInputChange}
                                value               ={empresa.endereco.obs}
                            />
                        </label>
                        
                        <label className= {"col-xs-12 col-sm-5"+(err.empresa && err.empresa.endereco && err.empresa.endereco.bairro ? " err": "")}>
                            <b>Bairro<sup></sup></b>
                            <input
                                name                ="empresa.endereco.bairro"
                                placeholder         =""
                                onChange            ={this.onInputChange}
                                value               ={empresa.endereco.bairro}
                            />
                        </label>
                        
                        <label className= {"col-xs-12 col-sm-5"+(err.empresa && err.empresa.endereco && err.empresa.endereco.cidade ? " err": "")}>
                            <b>Cidade<sup></sup></b>
                            <input
                                name                ="empresa.endereco.cidade"
                                placeholder         =""
                                onChange            ={this.onInputChange}
                                value               ={empresa.endereco.cidade}
                            />
                        </label>
                        
                        <label className= {"col-xs-12 col-sm-2"+(err.empresa && err.empresa.endereco && err.empresa.endereco.estado ? " err": "")}>
                            <b>Estado<sup></sup></b>
                            <Select
                                value                   ={empresa && empresa.endereco && empresa.endereco.estado? {label:empresa.endereco.estado} : ""}
                                //isClearable           ={this.state.value.some(v => !v.isFixed)}
                                styles                  ={styles}
                                onChange                ={this.onEmpresaEstadoChange}
                                placeholder             ="Selecione..."
                                name                    ="conselhoTipo"
                                className               ="mySelect"
                                classNamePrefix         ="_"
                                noOptionsMessage        ={() => "Opções não disponíveis"}
                                options                 ={convertDataToSelectReact(ufs,"label","label")}
                            />
                        </label>
                    </div>

                    <div className={"box-bordered" + (aba===7 ? "" : " d-none")}>

                    </div>

                </div>
            </form>
        )
    }
}

export default FormDoctor
/*
                <Accordion defaultActiveKey="0" className="w-100">
                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as="div" variant="link" eventKey="0">
                            <strong>Dados Pessoais</strong>
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <label className= {"col-xs-12 col-sm-6"+(err.nome ? " err": "")}>
                                <b>Nome<sup>*</sup></b>
                                <input
                                    name                ="nome"
                                    placeholder         =""
                                    onChange            ={this.onInputChange}
                                    value               ={nome}
                                />
                            </label>
                            <label className={"col-xs-12 col-sm-3"+(err.cpf ? " err": "")}>
                                <b>CPF<sup>*</sup></b>
                                <NumberFormat 
                                    name                ="cpf"
                                    alt                 ="text"
                                    format              ={"###.###.###-##"}
                                    onChange            ={this.onInputChange}
                                    value               ={cpf}
                                    //prefix              ="R$ "
                                    //decimalSeparator    =","
                                    //thousandSeparator   ={"."}
                                />
                            </label>
                            <label className={"col-xs-12 col-sm-2"+(err.nascimento ? " err": "")}>
                                <b>Data de Nascimento<sup></sup></b>
                                <Datetime 
                                    timeFormat          ={false}
                                    locale              ="pt-br" 
                                    name                ="nascimento"
                                    mask                ="99/99/9999"
                                    inputProps          ={{maxLength:10}}
                                    value               ={nascimento instanceof Date && !isNaN(nascimento.valueOf()) ? new Date(nascimento) : nascimento}
                                    //timeConstraints     ={timeConfig}
                                    onChange            ={this.handleDateChange}
                                />
                            </label>
                            <label className={"col-xs-12 col-sm-1"+(err.nascimento ? " err": "")}>
                                <img width="60" height="60" />
                            </label>
                            <label className={"col-xs-12 col-sm-6"+(err.celular ? " err": "")}>
                                <b>Telefone<sup>*</sup></b>
                                <div className="input-group">
                                    <div className="btns">
                                        <button
                                            type        ="button"
                                            name        ="celular"
                                            value         ="1"
                                            onClick     ={this.handleTypePhone}
                                            className   ={"btn "+(masks.celular?"btn-success":"btn-default")}
                                        >
                                            <FontAwesomeIcon icon={faPhoneAlt} />
                                        </button>
                                        <button
                                            type        ="button"
                                            name        ="celular"
                                            value         ="0"
                                            onClick     ={this.handleTypePhone}
                                            className   ={"btn "+(masks.celular?"btn-default":"btn-success")}
                                        >
                                            <FontAwesomeIcon icon={faMobileAlt} />
                                        </button>
                                    </div>
                                    <NumberFormat 
                                        name                ="celular"
                                        alt                 ="tel"
                                        format              ={ masks.celular ? "(##) ####-####" : "(##) # ####-####"}
                                        onChange            ={this.onInputChange}
                                        value               ={celular}
                                        className           ="form-control"
                                        //prefix              ="R$ "
                                        //decimalSeparator    =","
                                        //thousandSeparator   ={"."}
                                    />
                                </div>
                            </label>
                            <label className={"col-xs-12 col-sm-6"}>
                                <b>Telefone<sup></sup></b>
                                <div className="input-group">
                                    <div className="btns">
                                        <button
                                            type        ="button"
                                            name        ="telefone"
                                            value         ="1"
                                            onClick     ={this.handleTypePhone}
                                            className   ={"btn "+(masks.telefone?"btn-success":"btn-default")}
                                        >
                                            <FontAwesomeIcon icon={faPhoneAlt} />
                                        </button>
                                        <button
                                            type        ="button"
                                            name        ="telefone"
                                            value         ="0"
                                            onClick     ={this.handleTypePhone}
                                            className   ={"btn "+(masks.telefone?"btn-default":"btn-success")}
                                        >
                                            <FontAwesomeIcon icon={faMobileAlt} />
                                        </button>
                                    </div>
                                    <NumberFormat 
                                        name                ="telefone"
                                        alt                 ="tel"
                                        format              ={ masks.telefone ? "(##) ####-####":"(##) # ####-####"}
                                        onChange            ={this.onInputChange}
                                        value               ={telefone}
                                        //prefix              ="R$ "
                                        //decimalSeparator    =","
                                        //thousandSeparator   ={"."}
                                        className="form-control"
                                    />
                                </div>
                            </label>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as="div" variant="link" eventKey="1" className="w-100">
                            <strong>Dados de Atendimento</strong>
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <label className= {"col-xs-12 col-sm-6"+(err.especialidade ? " err": "")}>
                                    <b>Especialidades<sup>*</sup></b>
                                    <Select
                                        isMulti
                                        value                   ={especialidade}
                                        //isClearable           ={this.state.value.some(v => !v.isFixed)}
                                        styles                  ={styles}
                                        onChange                ={this.onEspecialidadesChange}
                                        placeholder             ="Selecione..."
                                        name                    ="especialidade"
                                        className               ="mySelect"
                                        classNamePrefix         ="_"
                                        noOptionsMessage        ={() => "Opções não disponíveis"}
                                        options                 ={convertDataToSelectReact(lists.especialidades,"label","label")}
                                    />
                                </label>
                                <label className= {"col-xs-6 col-sm-4"+(err.conselhoTipo ? " err": "")}>
                                    <b>Conselho<sup></sup></b>
                                    <Select
                                        value                   ={conselhoTipo && conselhoTipo.label ? conselhoTipo : {label:"CRM"}}
                                        //isClearable           ={this.state.value.some(v => !v.isFixed)}
                                        styles                  ={styles}
                                        onChange                ={this.onEspecialidadesChange}
                                        placeholder             ="Selecione..."
                                        name                    ="conselhoTipo"
                                        className               ="mySelect"
                                        classNamePrefix         ="_"
                                        noOptionsMessage        ={() => "Opções não disponíveis"}
                                        options                 ={convertDataToSelectReact(conselhos,"label","label")}
                                    />
                                </label>
                                <label className={"col-xs-6 col-sm-2"+(err.conselhoDoc ? " err": "")}>
                                    <b>{conselhoTipo && conselhoTipo.label ? conselhoTipo.label : "CRM"}<sup></sup></b>
                                    <NumberFormat 
                                        name                ="conselhoDoc"
                                        alt                 ="text"
                                        //format              ={telefone.length<10 ? "(##) ####-####":"(##) # ####-####"}
                                        onChange            ={this.onInputChange}
                                        value               ={conselhoDoc || crm}
                                        //prefix              ="R$ "
                                        decimalSeparator    =","
                                        thousandSeparator   ={"."}
                                    />
                                </label>
                                
                                <label className= {"col-xs-12 col-sm-8"+(err.plano ? " err": "")}>
                                    <b>Planos<sup></sup></b>
                                    <Select
                                        isMulti
                                        value                   ={plano}
                                        //isClearable           ={this.state.value.some(v => !v.isFixed)}
                                        styles                  ={styles}
                                        onChange                ={this.onPlanosChange}
                                        placeholder             ="Selecione..."
                                        name                    ="plano"
                                        className               ="mySelect"
                                        classNamePrefix         ="_"
                                        noOptionsMessage        ={() => "Opções não disponíveis"}
                                        options                 ={convertDataToSelectReact(lists.planos,"label","label")}
                                    />
                                </label>
                                
                                <div className= {"gLabel col-xs-6 col-sm-2"}>
                                    <b>Permite encaixe?</b>
                                    <div className="btn-group btn-group-justified" role="group" aria-label="encaixe">
                                        <div className="btn-group"><button
                                            type="button"
                                            name="sim"
                                            onClick={this.onEncaixeClick}
                                            className={"btn " + (horarios.encaixe? "btn-success" : " btn-default")}>SIM</button></div>
                                        <div className="btn-group">
                                        <button
                                            type="button"
                                            name="nao"
                                            onClick={this.onEncaixeClick}
                                            className={"btn " + (horarios.encaixe? "btn-default" : "btn-danger")}>NÃO</button>
                                        </div>
                                    </div>
                                </div>

                                <label className= {"col-xs-6 col-sm-2 "+(err.horarios && err.horarios.tempoConsulta ? " err": "")}>
                                    <b>Tempo<sup></sup></b>
                                    <Select
                                        value                   ={horarios.tempoConsulta? {label: horarios.tempoConsulta+" min"}: ""}
                                        //isClearable           ={this.state.value.some(v => !v.isFixed)}
                                        styles                  ={styles}
                                        onChange                ={this.onTempoChange}
                                        placeholder             ="Selecione..."
                                        name                    ="horarios.tempoConsulta"
                                        className               ="mySelect"
                                        classNamePrefix         ="_"
                                        noOptionsMessage        ={() => "Opções não disponíveis"}
                                        options                 ={consultaTempos}
                                    />
                                </label>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as="div" variant="link" eventKey="3" className="w-100">
                                <strong>Horário Contínuos</strong>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body>
                                <Horarios funcUpd={this.handleHorariosUpdFixo} dados={horarios.intervalos.fixo && horarios.intervalos.fixo[0] && horarios.intervalos.fixo[0].range ? horarios.intervalos.fixo[0].range:[]} idx={0}/>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as="div" variant="link" eventKey="4" className="w-100">
                                <strong>Horários Temporários</strong>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                            <Card.Body>
                                <div className="row">
                                    <label className={"col-xs-12 col-sm-4"+(err.horarios && err.horarios.horaIni ? " err": "")}>
                                        <b>Início<sup></sup></b>
                                        <Datetime 
                                            timeFormat          ={false}
                                            closeOnSelect       ={true}
                                            locale              ="pt-br" 
                                            className           ="horarios.temp.0.ini"
                                            value               ={horarios && horarios.intervalos.temp && horarios.intervalos.temp[0] && horarios.intervalos.temp[0].ini!=="" ? new Date(horarios.intervalos.temp[0].ini) : ""}
                                            //timeConstraints     ={timeConfig}
                                            onChange            ={this.handleTempDataChange}
                                        />
                                    </label>

                                    <label className={"col-xs-12 col-sm-4"+(err.horarios && err.horarios.horaFim ? " err": "")}>
                                        <b>Término<sup></sup></b>
                                        <Datetime 
                                            timeFormat          ={false}
                                            closeOnSelect       ={true}
                                            locale              ="pt-br" 
                                            className           ="horarios.temp.0.fim"
                                            value               ={horarios && horarios.intervalos.temp && horarios.intervalos.temp[0] && horarios.intervalos.temp[0].fim !=="" ? new Date(horarios.intervalos.temp[0].fim) : ""}
                                            //timeConstraints     ={timeConfig}
                                            onChange            ={this.handleTempDataChange}
                                        />
                                    </label>

                                    <label className={"col-xs-12 col-sm-4"+(err.horarios && err.horarios.horaFim ? " err": "")}>
                                        <b>Tipo<sup></sup></b>
                                        <Select
                                            value                   ={horarios && horarios.intervalos.temp && horarios.intervalos.temp[0] ? horarios.intervalos.temp[0].tipo : ""}
                                            isClearable             ={true}
                                            styles                  ={styles}
                                            onChange                ={this.onTipoHorarioChange}
                                            placeholder             ="Selecione..."
                                            name                    ="horarios.temp.0.tipo"
                                            className               ="mySelect"
                                            classNamePrefix         ="_"
                                            noOptionsMessage        ={() => "Opções não disponíveis"}
                                            options                 ={[{value:1, label:"Disponibilidade"},{value:0, label:"Indisponibilidade"}]}
                                        />
                                    </label>
                                </div>
                                <br/>
                                <Horarios funcUpd={this.handleHorariosUpdTemp} dados={horarios.intervalos.temp && horarios.intervalos.temp[0] && horarios.intervalos.temp[0].range ? horarios.intervalos.temp[0].range : []} idx={0}/>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as="div" variant="link" eventKey="2" className="w-100">
                                <strong>Endereço Residencial</strong>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                <label className= {"col-xs-12 col-sm-2"+(err.endereco && err.endereco.cep ? " err": "")}>
                                    <b>CEP<sup></sup></b>
                                    <div className="iGroup">
                                        <NumberFormat 
                                            className           ="form-control"
                                            name                ="endereco.cep"
                                            alt                 ="text"
                                            format              ={"#####-###"}
                                            onChange            ={this.onInputChange}
                                            value               ={endereco.cep}
                                        />
                                        <button type="button" name="endereco" className="input-group-addon fRight" onClick={this.handleCepClick} value={endereco.cep}>
                                            <FontAwesomeIcon icon={faSearch} />
                                        </button>
                                    </div>
                                </label>
                                
                                <label className= {"col-xs-12 col-sm-8"+(err.endereco && err.endereco.rua ? " err": "")}>
                                    <b>Rua<sup></sup></b>
                                    <input
                                        name                ="endereco.rua"
                                        placeholder         =""
                                        onChange            ={this.onInputChange}
                                        value               ={endereco.rua}
                                    />
                                </label>
                                
                                <label className= {"col-xs-12 col-sm-2"+(err.endereco && err.endereco.numero ? " err": "")}>
                                    <b>Número<sup></sup></b>
                                    <input
                                        name                ="endereco.numero"
                                        placeholder         =""
                                        onChange            ={this.onInputChange}
                                        value               ={endereco.numero}
                                    />
                                </label>
                                
                                <label className= {"col-xs-12"+(err.endereco && err.endereco.obs ? " err": "")}>
                                    <b>Complemento<sup></sup></b>
                                    <input
                                        name                ="endereco.obs"
                                        placeholder         =""
                                        onChange            ={this.onInputChange}
                                        value               ={endereco.obs}
                                    />
                                </label>
                                
                                <label className= {"col-xs-12 col-sm-5"+(err.endereco && err.endereco.bairro ? " err": "")}>
                                    <b>Bairro<sup></sup></b>
                                    <input
                                        name                ="endereco.bairro"
                                        placeholder         =""
                                        onChange            ={this.onInputChange}
                                        value               ={endereco.bairro}
                                    />
                                </label>
                                
                                <label className= {"col-xs-12 col-sm-5"+(err.endereco && err.endereco.cidade ? " err": "")}>
                                    <b>Cidade<sup></sup></b>
                                    <input
                                        name                ="endereco.cidade"
                                        placeholder         =""
                                        onChange            ={this.onInputChange}
                                        value               ={endereco.cidade}
                                    />
                                </label>
                                
                                <label className= {"col-xs-12 col-sm-2"+(err.endereco && err.endereco.estado ? " err": "")}>
                                    <b>Estado<sup></sup></b>
                                    <Select
                                        value                   ={endereco && endereco.estado? {label:endereco.estado} : ""}
                                        //isClearable           ={this.state.value.some(v => !v.isFixed)}
                                        styles                  ={styles}
                                        onChange                ={this.onEstadoChange}
                                        placeholder             ="Selecione..."
                                        name                    ="conselhoTipo"
                                        className               ="mySelect"
                                        classNamePrefix         ="_"
                                        noOptionsMessage        ={() => "Opções não disponíveis"}
                                        options                 ={convertDataToSelectReact(ufs,"label","label")}
                                    />
                                </label>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as="div" variant="link" eventKey="5" className="w-100">
                                <strong>Dados Jurídicos</strong>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="5">
                            <Card.Body>
                                <label className= {"col-xs-12 col-sm-6"+(err.empresa && err.empresa.razao ? " err": "")}>
                                    <b>Razão Social<sup></sup></b>
                                    <input
                                        name                ="empresa.razao"
                                        placeholder         =""
                                        onChange            ={this.onInputChange}
                                        value               ={empresa.razao}
                                    />
                                </label>

                                <label className={"col-xs-12 col-sm-3"+(err.empresa && err.empresa.cnpj ? " err": "")}>
                                    <b>CNPJ<sup></sup></b>
                                    <NumberFormat 
                                        name                ="empresa.cnpj"
                                        alt                 ="text"
                                        format              ={"##.###.###/####-##"}
                                        onChange            ={this.onInputChange}
                                        value               ={empresa.cnpj}
                                    />
                                </label>

                                <label className={"col-xs-12 col-sm-3"+(err.empresa && err.empresa.fundacao ? " err": "")}>
                                    <b>Data de Fundação<sup></sup></b>
                                    <Datetime 
                                        timeFormat          ={false}
                                        locale              ="pt-br" 
                                        className           ="fundacao"
                                        value               ={empresa.fundacao? new Date(empresa.fundacao) : ""}
                                        //timeConstraints     ={timeConfig}
                                        onChange            ={this.handleFundacaoChange}
                                    />
                                </label>

                                <label className= {"col-xs-12 col-sm-6"+(err.empresa && err.empresa.estadual ? " err": "")}>
                                    <b>Inscrição Estadual<sup></sup></b>
                                    <input
                                        name                ="empresa.estadual"
                                        placeholder         =""
                                        onChange            ={this.onInputChange}
                                        value               ={empresa.estadual}
                                    />
                                </label>
                                
                                <label className= {"col-xs-12 col-sm-6"+(err.empresa && err.empresa.municipal ? " err": "")}>
                                    <b>Inscrição Municipal<sup></sup></b>
                                    <input
                                        name                ="empresa.municipal"
                                        placeholder         =""
                                        onChange            ={this.onInputChange}
                                        value               ={empresa.municipal}
                                    />
                                </label>
                                
                                <label className= {"col-xs-12 col-sm-2"+(err.empresa && err.empresa.endereco && err.empresa.endereco.cep ? " err": "")}>
                                    <b>CEP<sup></sup></b>
                                    <div className="iGroup">
                                        <NumberFormat 
                                            className           ="form-control"
                                            name                ="empresa.endereco.cep"
                                            alt                 ="text"
                                            format              ={"#####-###"}
                                            onChange            ={this.onInputChange}
                                            value               ={empresa.endereco.cep}
                                        />
                                        <button type="button" name="empresa.endereco" className="input-group-addon fRight" onClick={this.handleCepClick} value={empresa.endereco.cep}>
                                            <FontAwesomeIcon icon={faSearch} />
                                        </button>
                                    </div>
                                </label>
                                
                                <label className= {"col-xs-12 col-sm-8"+(err.empresa && err.empresa.endereco && err.empresa.endereco.rua ? " err": "")}>
                                    <b>Rua<sup></sup></b>
                                    <input
                                        name                ="empresa.endereco.rua"
                                        placeholder         =""
                                        onChange            ={this.onInputChange}
                                        value               ={empresa.endereco.rua}
                                    />
                                </label>
                                
                                <label className= {"col-xs-12 col-sm-2"+(err.empresa && err.empresa.endereco && err.empresa.endereco.numero ? " err": "")}>
                                    <b>Número<sup></sup></b>
                                    <input
                                        name                ="empresa.endereco.numero"
                                        placeholder         =""
                                        onChange            ={this.onInputChange}
                                        value               ={empresa.endereco.numero}
                                    />
                                </label>
                                
                                <label className= {"col-xs-12"+(err.empresa && err.empresa.endereco && err.empresa.endereco.obs ? " err": "")}>
                                    <b>Complemento<sup></sup></b>
                                    <input
                                        name                ="empresa.endereco.obs"
                                        placeholder         =""
                                        onChange            ={this.onInputChange}
                                        value               ={empresa.endereco.obs}
                                    />
                                </label>
                                
                                <label className= {"col-xs-12 col-sm-5"+(err.empresa && err.empresa.endereco && err.empresa.endereco.bairro ? " err": "")}>
                                    <b>Bairro<sup></sup></b>
                                    <input
                                        name                ="empresa.endereco.bairro"
                                        placeholder         =""
                                        onChange            ={this.onInputChange}
                                        value               ={empresa.endereco.bairro}
                                    />
                                </label>
                                
                                <label className= {"col-xs-12 col-sm-5"+(err.empresa && err.empresa.endereco && err.empresa.endereco.cidade ? " err": "")}>
                                    <b>Cidade<sup></sup></b>
                                    <input
                                        name                ="empresa.endereco.cidade"
                                        placeholder         =""
                                        onChange            ={this.onInputChange}
                                        value               ={empresa.endereco.cidade}
                                    />
                                </label>
                                
                                <label className= {"col-xs-12 col-sm-2"+(err.empresa && err.empresa.endereco && err.empresa.endereco.estado ? " err": "")}>
                                    <b>Estado<sup></sup></b>
                                    <Select
                                        value                   ={empresa && empresa.endereco && empresa.endereco.estado? {label:empresa.endereco.estado} : ""}
                                        //isClearable           ={this.state.value.some(v => !v.isFixed)}
                                        styles                  ={styles}
                                        onChange                ={this.onEmpresaEstadoChange}
                                        placeholder             ="Selecione..."
                                        name                    ="conselhoTipo"
                                        className               ="mySelect"
                                        classNamePrefix         ="_"
                                        noOptionsMessage        ={() => "Opções não disponíveis"}
                                        options                 ={convertDataToSelectReact(ufs,"label","label")}
                                    />
                                </label>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as="div" variant="link" eventKey="6" className="w-100">
                                <strong>Atestados</strong>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="6">
                            <Card.Body>

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as="div" variant="link" eventKey="7" className="w-100">
                                <strong>Configurações</strong>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="7">
                            <Card.Body>

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>


*/

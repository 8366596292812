import Axios from 'axios'
import {myServer, weekDay, mesesAbrev, appVersion} from '../../_constants'

import Swal from 'sweetalert2'
import '../sweetAlert.scss'

export const getRecurrency = (type, start, end) => {
	let days = [start], step= new Date(start);
	while(step.getTime()<end.getTime()){
		switch(type){
			case "Semanal":
			case "Semanalmente":
				step.setDate(step.getDate()+7)
				if(step.getTime()<end.getTime())
					days.push(new Date(step))
				break
			case "Quinzenal":
			case "Quinzenalmente":
				step.setDate(step.getDate()+14)
				if(step.getTime()<end.getTime())
					days.push(new Date(step))
				break
			case "Mensal":
			case "Mensalmente":
				const mes = step.getMonth()
				step.setDate(step.getDate()+28)
				if(mes===step.getMonth())
					step.setDate(step.getDate()+7)
				if(step.getTime()<end.getTime())
					days.push(new Date(step))
				break
			default:
		}
	}
	return days
}

export function getElementOffset(el) {
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}
export function copyObj(mainObj){ return {...mainObj} }

export function varName(obj) { return Object.keys(obj)[0] } // obj = {var}

export async function appVer() {
    Axios.defaults.withCredentials = true
	const {data} = await Axios.get(`${myServer}/agenda/appver`)
    return data
}

export const getInicials = txt => {
	let words = txt.trim().split(' ')
	if(words.length>1)
		return words[0].substr(0,1)+words[1].substr(0,1)
	return txt.substr(0, 2)
}
export function checkAppVerReload(ver){
	console.log("serv",ver, "local",appVersion)
	if(appVersion!==ver){
		Swal.fire({
			title: 'Sua aplicação está desatualizada',
			text: "Deseja atualizar?",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sim, atualize!',
			cancelButtonText: 'Não, obrigado!'
		  }).then((result) => {
			if (result.value) {
			  document.location.reload(true)
			}
		  })
	}
}

Axios.defaults.withCredentials = true
const ID = (txt) => {return document.getElementById(txt)}
export function myCanvas(obj){//manipulação de imagens
	this.obj=obj;
	this.ctx = obj.getContext("2d");
	this.larg=obj.width;
	this.altu=obj.height;
	this.angulo=90*Math.PI/180.0;
	this.imgId="";
	this.clone="";
	this.rate= 0.75;
	this.escala=1.0;
	this.rot=false;
	this.Limpar = function(){	
		this.ctx.clearRect(0, 0, this.larg, this.altu);
		this.ctx.fillStyle = "rgba(255, 255, 255, 1.0)";
		this.ctx.fillRect(0, 0, this.larg, this.altu);
	}
	this.LimparB = function(){	
		this.ctx.clearRect(0, 0, this.larg, this.altu);
	}
	this.Clonar = function(){
		if(this.clone.length>0){
			let img = obj.toDataURL("image/jpeg",this.rate);
			ID(this.clone).src=img;
		}
	}
	this.Excluir = function(){
		//let flag=false;
		if(this.imgId.length>0){
			this.LimparB(obj);
			//$('#'+this.imgId).parent().remove();
			this.imgId="";
			//flag=true;
		}
	}
	this.Salvar = function(){
		var flag=false; 
		if(this.imgId.length>0){
            let img = this.obj.toDataURL("image/jpeg",this.rate);
            ID(this.imgId).src=img
            ID(this.imgId).width=640
            ID(this.imgId).height=480
			//$("#"+this.imgId).removeClass('img1');
			//if(this.imgId!="big")$("#"+this.imgId).addClass('img');
			this.LimparB();
			this.Clonar()
			flag=true;
		}
		return flag;
	}
	this.src=function(){return this.obj.toDataURL("image/jpeg",this.rate);}
	this.Restaurar = function(){
		if(this.imgId.length>0){
			this.Limpar();
			this.ctx.drawImage(ID(this.imgId),0,0);
			this.Clonar();
		}
	}
	this.endRot = function(passo,tmpImg){
		this.Limpar();	
		this.ctx.save();
		this.ctx.translate(this.larg/2,this.altu/2);
		this.ctx.rotate(this.angulo*passo);
		this.ctx.drawImage(tmpImg,-this.larg/2,-this.altu/2);
		this.ctx.restore();
		this.Clonar();
		this.rot=false;
	}
	this.Rotacionar = function(passo){
		let img = obj.toDataURL("image/jpeg",this.rate),
		    tmpImg = new Image(),
		    canvas=this;
		this.rot=true;
		tmpImg.src = img;
		tmpImg.onload = function() { canvas.endRot(passo,this); }		
	}
	this.Rotacionar90 = function(){	this.Rotacionar(1.0); }	
	this.Rotacionar180 = function(){ this.Rotacionar(2.0); }
	this.Expandir2 = function(){
		if(this.imgId.length>0){ 
			let img = ID(this.imgId),
			    lar= img.width,
			    alt= img.height,
			    dX=0,
                dY=0;  //console.log(lar,alt,img.offsetWidth ,img.offsetHeight)
			if((lar*1.0/alt)>=(this.larg*1.0/this.altu)){ this.escala=this.larg*1.0/lar; dY=(this.altu-alt*this.escala)/2/this.escala;}
			else{ this.escala=this.altu*1.0/alt; dX=(this.larg-lar*this.escala)/2/this.escala; }
			this.Limpar();		
			this.ctx.save();
			this.ctx.scale(this.escala, this.escala);
			this.ctx.drawImage(img,dX,dY);
			this.ctx.restore();
			this.Clonar();
		}
	}
	this.Expandir = function(){
		if(this.imgId.length>0){
			let img = ID(this.imgId),
			    cvs = document.createElement("canvas"),
			    lar= img.width,
			    alt= img.height,
			    dX=0,
                dY=0,
                i=0;			
			this.Limpar();		
			cvs.width=lar;
			cvs.height=alt;
			if(lar>alt){ 
				let newAlt=alt*this.larg/lar;
				dY=(this.altu-newAlt)/2;
				dX=0;
				this.ctx.drawImage(img,0,0,lar,alt,dX,dY,this.larg,newAlt);
				for(i=1;i<this.larg;i++){
					let c=this.ctx.getImageData(i, this.altu*0.75, 1, 1).data;
					if(c[0]!==255 || c[1]!==255 || c[2]!==255){break;}
				}
				if(i===this.larg){
					this.ctx.drawImage(img,0,0,lar,alt,dX,dY,this.larg,newAlt*2);
				}
			}else{
				let newLarg=this.altu*lar/alt;
				dX=(this.larg-newLarg)/2;
				dY=0;
				this.ctx.drawImage(img,0,0,lar,alt,dX,dY,newLarg,this.altu);
				for(i=1;i<this.larg;i++){
					let c=this.ctx.getImageData(i,this.altu*0.75, 1, 1).data;
					if(c[0]!==255 || c[1]!==255 || c[2]!==255){break;}
				}
				if(i===this.larg){
					this.ctx.drawImage(img,0,0,lar,alt,dX,dY,newLarg,this.altu*2);
				}
			}
			this.Clonar();
		}
	}
	this.Editar = function(){
		if(this.imgId.length>0){
			this.Limpar();
			this.ctx.drawImage(ID(this.imgId),0,0);
			this.Clonar();
		}
	}
}

// IN USE

export function myMask(value, mask){
    const val   = value.substr(0,mask.length).split(""),
        msk     = mask.split("");

    let res = "",
        index = 0,
        prox  = false;
    val.map((v) => {
        do{
            if(msk[index]==="9"){
                const re = /[0-9]/
                if(re.test(v)){
                    index++
                    res+=v
                }
                prox  = false;
            }else{
                if(v===msk[index]){
                    res+=v
                    prox  = false;
                }else{
                    try{
                        if(index<msk.length){
                            res+=msk[index] 
                            prox  = true;
                        }else{
                            prox  = false;
                        }
                    }catch(e){
                        prox  = false;
                    }
                }
                index++
            }
        }while(prox)
    })
    return res
}

export const onlyNumbers = (txt) => txt.replace(/(?![0-9])./g,"")

export async function getEnd(cep){
		Axios.defaults.withCredentials = false
		let v		= onlyNumbers(cep),
			resp	= await Axios.get( window.location.protocol+'//viacep.com.br/ws/' + v +'/json/'),
			endereco= {}
		if(resp.status === 200){
			const {data} = resp
			endereco = {
				cep         :   data.cep,
				rua         :   data.logradouro,
				bairro      :   data.bairro,
				cidade      :   data.localidade,
				estado      :   data.uf
			}
		}
		Axios.defaults.withCredentials = true
		return endereco
}
export async function isLogged() {
    const resp = await Axios.post(`${myServer}/agenda/user/logged`,{})
    const {user} = resp.data
    return user
}
export async function logOut() {
    const resp = await Axios.get(`${myServer}/agenda/user/logout`)
    return resp
}
export function convertDataToSelectReact(obj,addDesc=""){
    let labels = []
    obj.map(i => {
		try{
			let comp = ""
			if(addDesc.length) comp = " - "+addDesc.toUpperCase()+": "+ i[addDesc]
			labels.push({value: i.codigo || i._id, label: (i.nome || i.desc) + comp})
		}catch(e){}
		return true
	})
    return labels
}

export function formatDate(date) { 
	const monthNames = [
	  "jan", "fev", "mar",
	  "abr", "mai", "jun",
	  "jul", "ago", "set",
	  "out", "nov", "dez"
	];
  
	const day = ("0"+date.getDate()).substr(-2);
	const monthIndex = date.getMonth();
	const time = ("0"+date.getHours()).substr(-2)+':'+("0"+date.getMinutes()).substr(-2);
  
	return day + '/' + monthNames[monthIndex] + ' ' + time;
}


export function formatDateHM(date) {
	const dt = new Date(date),
		time = ("0"+dt.getHours()).substr(-2)+':'+("0"+dt.getMinutes()).substr(-2);
  
	return time;
}

export function formatDateDMY(date) { 
	const monthNames = [
	  "jan", "fev", "mar",
	  "abr", "mai", "jun",
	  "jul", "ago", "set",
	  "out", "nov", "dez"
	];
  
	const day	= ("0"+date.getDate()).substr(-2);
	const month	= ("0"+(date.getMonth()+1)).substr(-2);
	const year	= date.getFullYear();
  
	return day + '/' + month + '/' + year;
}

export function dateInterval(dtIni, dtFim) {
	const start = new Date(dtIni),
			end = new Date(dtFim), 
			dt  = `${('0'+start.getHours()).substr(-2)}:${('0'+start.getMinutes()).substr(-2)} às ${('0'+end.getHours()).substr(-2)}:${('0'+end.getMinutes()).substr(-2)} - <b style="font-size:0.9em">${weekDay[start.getDay()]} ${('0'+start.getDate()).substr(-2)} ${mesesAbrev[start.getMonth()].toUpperCase()}</b>`;
	return dt
}

export function validPhone(fone){
	var f=fone.replace(/[^0-9]/gm,''), re =/^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/; return re.test(f) && f.length>11 }

//---------------------------

/*export function simulate(element, eventName)
{	console.log(element, eventName)
    var options = extend(defaultOptions, arguments[2] || {});
    var oEvent, eventType = null;

    for (var name in eventMatchers)
    {
        if (eventMatchers[name].test(eventName)) { eventType = name; break; }
    }

    if (!eventType)
        throw new SyntaxError('Only HTMLEvents and MouseEvents interfaces are supported');

    if (document.createEvent)
    {
        oEvent = document.createEvent(eventType);
        if (eventType == 'HTMLEvents')
        {
            oEvent.initEvent(eventName, options.bubbles, options.cancelable);
        }
        else
        {
            oEvent.initMouseEvent(eventName, options.bubbles, options.cancelable, document.defaultView,
            options.button, options.pointerX, options.pointerY, options.pointerX, options.pointerY,
            options.ctrlKey, options.altKey, options.shiftKey, options.metaKey, options.button, element);
        }
        element.dispatchEvent(oEvent);
    }
    else
    {
        options.clientX = options.pointerX;
        options.clientY = options.pointerY;
        var evt = document.createEventObject();
        oEvent = extend(evt, options);
        element.fireEvent('on' + eventName, oEvent);
    }
    return element;
}





function extend(destination, source) {
    for (var property in source)
      destination[property] = source[property];
    return destination;
}

var eventMatchers = {
    'HTMLEvents': /^(?:load|unload|abort|error|select|change|submit|reset|focus|blur|resize|scroll)$/,
    'MouseEvents': /^(?:click|dblclick|mouse(?:down|up|over|move|out))$/
}
var defaultOptions = {
    pointerX: 0,
    pointerY: 0,
    button: 0,
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    metaKey: false,
    bubbles: true,
    cancelable: true
}
*/

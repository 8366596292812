import React, { Component } from 'react'
import Axios from 'axios'
import SelectR from 'react-select'
import CreatableSelect from 'react-select/creatable'

//import { withSwalInstance } from 'sweetalert2-react';
import swal from 'sweetalert2';
import '../sweetAlert.scss'

//import TextField from '@material-ui/core/TextField'
import {validPhone, convertDataToSelectReact, myMask} from '../functions'
import {myServer, eventStatus, timeSteps} from '../../_constants'

import './datetime.scss'

import Datetime from 'react-datetime'
import moment from 'moment'
require("moment/min/locales.min");
moment.locale('pt-br')

//require('react-datetime')

Axios.defaults.withCredentials = true

export class insertTask extends Component {
    state={
        dtIni:              null,
        hrIni:              null,
        dtFim:              null,
        prestador:          null,
        especialidade:      null,
        paciente:           null,
        consulta:           null,
        observacao:         "",
        status:             {label:"Agendamento"},
        tempo:              {value: 30, label: '30 min'},
        err:                {},
        especialidades:     [],
        pacientes:          [],
        prestadores:        [],
        planos:             [],
        tipos:              eventStatus,
        intervalos:         timeSteps,
        flags:              {
            submit:             true,
            validado:           false,
            inserted:           false,
            enviando:           false
        }
    }

    componentDidMount(){
        let stt       = this.status,
            pps         = this.props,
             {  id,
                dataHora,
                dados,
                submit,
                hrIni,
                dtIni } = pps,
            dt          = new Date(),
            y           = dt.getFullYear(),
            M           = (dt.getMonth()+1),
            d           = dt.getDate(),
            h           = dt.getHours(),
            m           = dt.getMinutes(),
            a           = m + 5 - m % 5,
            dtNow       = new Date(y+'-'+M+'-'+d+' '+( a<60 ? h : (h<23 ? h+1 : 0))+':'+(a<60 ? a : 0) );
        let prestadores = [],
            pacientes   = [],
            planos      =  [{label:"Particular"}];
        
        const espec = async () => {
            let resp = await Axios.get(`${myServer}/agenda/pacientes/list/all`)
            pacientes = resp.data.item ? resp.data.item : []
            //resp = await Axios.get(`${myServer}/agenda/planos/list`) //resp.data.item ? convertDataToSelectReact(resp.data.item.) : []
            //resp.data.item && resp.data.item.map(i=> planos.push({label:i.nome}))

            if(pps && pps.prestadores && pps.prestadores.length){
                prestadores = pps.prestadores
            }else{
                resp  = await Axios.get(`${myServer}/agenda/prestadores/list/all`)
                prestadores = resp.data.item ? resp.data.item : []
            }
            if(id && id.length){
                resp  = await Axios.get(`${myServer}/agenda/compromisso/${id}`)

                const prest = prestadores.filter(p=> resp.data.agenda.prestador.id===p._id)
                if(prest.length){  prest[0].plano.map(p => planos.push({label:p})) }
                
                let a = resp.data.agenda,
                    t = ((new Date(a.dtFim)).getTime() - (new Date(a.dtIni)).getTime())/60000,
                    s = {
                        id: id,
                        consulta: {label: a.consulta},
                        status: {label: a.status},
                        especialidade: {label: a.especialidade},
                        paciente: {value: a.paciente.id, label: a.paciente.nome}, //+ ' - CPF: '+a.paciente.cpf
                        prestador: {value: a.prestador.id, label: a.prestador.nome + ' - CRM: '+a.prestador.crm},
                        observacao: a.observacao,
                        dtIni: new Date(dataHora ? dataHora : a.dtIni),
                        hrIni: new Date(dataHora ? dataHora : a.dtIni),
                        tempo: {value: t, label: t<60 ? t+" min" : "1 hora" },
                        planos, prestadores, pacientes, ...dados, flags:{...stt, submit}
                    }
                //console.log(resp.data.agenda, s)
                this.setState(s)
            }else{
                console.log("pps.prestadores",pps.prestadores)
                dados["prestador"] = {value: pps.prestadores[0]._id, label: pps.prestadores[0].nome + ' - CRM: '+pps.prestadores[0].crm}
                dados["especialidade"] = {label: pps.prestadores[0].especialidade[0]}
                dados && dados.planos && dados.planos.length && dados.planos.map(p => planos.push({label: p}) ) 
                dados["planos"]=planos
                planos=[]; pps.prestadores[0].especialidade.map(p => planos.push({label: p}) ) 
                dados["especialidades"] = planos
                planos=pps.prestadores[0].horarios.tempoConsulta
                dados["tempo"]={value: planos, label: planos<60 ? planos+" min" : "1 hora"}
                if(!dados.consulta) dados["consulta"] = {label:"Particular"}
                
                if(dtIni && hrIni)  this.setState({dtIni, hrIni, prestadores, pacientes,...dados, flags:{...stt, submit}})
                else{
                    dtIni = dados.dtIni && dados.dtIni != "Invalid Date" ? dados.dtIni : dtNow
                    hrIni = dtIni
                    this.setState({prestadores, pacientes,...dados, dtIni, hrIni, flags:{...stt, submit}})
                }    
            }
            
        }
        espec()

    }

    handleDoctorChange = obj => {
        try{
            let { prestadores }   = this.state,
                    prestador       = prestadores.filter(p => obj.value === p._id.toString());
            let   especialidades    = [], 
                  {especialidade}   = this.state;
            
            if(prestador.length && prestador[0].especialidade){
                prestador[0].especialidade.map(e => {
                    especialidades.push({label:e})
                })
                especialidade = especialidades[0]
            }
            prestador = obj
            console.log(obj)
            this.setState({especialidades, especialidade, prestador})
        }catch(e){
            this.setState({especialidades:[], especialidade:null})
        }
    }

    handleSelectRChange = (obj, event) => {
        const {name} = event
        this.setState({[name]:obj})
    }
    setInputTelChange = () =>{
        const el = document.getElementsByClassName('swal2-input')[0]
        el.setAttribute("maxlength", 16)
        el.addEventListener("keyup", this.addInputTelMask, false);
        
    }
    addInputTelMask = () => {
        const el = document.getElementsByClassName('swal2-input')[0]
        el.value = myMask(el.value,"(99) 9 9999-9999")
        //console.log("mask",el.value, myMask(el.value,"(99) 9 9999-9999"))
    }
    handleClientChange = (obj,{action}) => { //console.log(obj, action)
        try{
            let {   pacientes,
                    consulta    }   = this.state
                
            if(action==="create-option"){
                let paciente = obj
                this.setState({paciente})
                swal.mixin({
                    input: 'tel',
                    type:'question',
                    confirmButtonText: 'OK',
                    onOpen: this.setInputTelChange,
                    showCancelButton: false,
                    progressSteps: [],
                    inputValidator: (value) => {
                        if (!validPhone('55'+value)) {
                          return 'Telefone inválido!'
                        }
                      }
                  }).queue([
                    'Telefone do Paciente'
                  ]).then((result) => {
                    if (result.value) {
                        const tel = result.value[0].replace(/[^0-9]/gm,''),
                            pcnt = {
                                nome:       obj.label,
                                telefone:   tel.length===10 ? tel : "", 
                                celular:    tel.length===10 ? "" : tel,
                                mail:       "",
                                cpf:        "",
                                particular: true,
                                plano:      []
                            },
                            insert = async () => {
                                const {data} = await Axios.post(`${myServer}/agenda/paciente/insert`, pcnt)
                                if(data.paciente && data.paciente==="Ok"){
                                    const p = data.item
                                    let pacientes = this.state.pacientes, temp = []
                                        paciente = {value: p._id, label: p.nome} //+' - CPF: '+p.cpf
                                    temp.push(p)
                                    pacientes.map(i => temp.push(i))
                                    pacientes = temp
                                    this.setState({pacientes,paciente})
                                }
                            }
                        insert()
                    }
                  })
                //console.log()
                document.getElementsByClassName('swal2-input')[0].style.textAlign='center'
            }else{
                let paciente        = pacientes.filter(p => obj.value === p._id.toString()),
                    planos          = [];
                
                if(paciente.length && paciente[0].plano){
                    paciente[0].plano.map(e => {
                        e.toLowerCase() !== "particular" && planos.push({label:e})
                    })
                }
                paciente = obj
                planos.push({label:'Particular'})
                //consulta = planos[0]
                this.setState({paciente})
            }
        }catch(e){
            this.setState({consulta:null})
        }
    }

    handleIniDTchange = event => this.setState({dtIni: event && event.value._isValid ? event.value._d : this.state.dtIni})

    handleTAchange = (event) => {
        const { name, value} = event.target
        this.setState({[name]:value})
    }

    handleIniHRchange = event =>{
        if(event && event._isValid){
            let hr = event._d.toString().substr(16,8),
                dt = new Date(this.state.dtIni.toString().replace(/..\:..\:../,hr))
            this.setState({hrIni: event._d, dtIni: dt})
        }
    }

    insertInAgenda =  obj => {
        const   stt = this.state,
                pps = this.props;
                
        pps.funcSetWait(true)
        const insert = async () => {
            try{
                const {data} = await Axios.post(`${myServer}/agenda/compromisso/insert`,obj)
                if(data && data.agenda){
                    this.setState({flags:{...stt.flags, validado:false, inserted:true}})
                    pps.funcSuccess(data.agenda)
                }
            }catch(e){}
            pps.funcSetWait(false)
        }
        insert()
    }
    
    updateAgenda =  obj => {
        const   stt = this.state,
                pps = this.props;
                
        pps.funcSetWait(true)
        const insert = async () => {
            try{
                const {data} = await Axios.post(`${myServer}/agenda/compromisso/update/${stt.id}`,obj)
                if(data && data.agenda){
                    this.setState({flags:{...stt.flags, validado:false, inserted:true}})
                    pps.funcSuccess({...obj, '_id':stt.id })
                }
            }catch(e){}
            pps.funcSetWait(false)
        }
        insert()
    }
    handleSubmit = () => {
        this.setState({err:{},flags:{...this.state.flags, inserted:false, validado:false}})

        let stt = this.state,
            {
                id,
                pacientes,
                prestadores,
                consulta,
                dtIni,
                //hrIni,
                especialidade,
                observacao,
                paciente,
                prestador,
                status,
                tempo       }   = stt,
            dtFim               = new Date(),
            data                = {},
            err                 = {},
            ok                  = true;

        consulta        = consulta && consulta.label            ? consulta.label        : ""
        especialidade   = especialidade && especialidade.label  ? especialidade.label   : ""
        status          = status && status.label                ? status.label          : ""        

        
        if(paciente && paciente.value){
            paciente = pacientes.filter(p => paciente.value === p._id.toString())[0]
            paciente = {id: paciente._id, nome: paciente.nome, cpf: paciente.cpf}
        }else paciente = null

        if(prestador && prestador.value){
            prestador = prestadores.filter(p => prestador.value === p._id.toString())[0]
            prestador = {id: prestador._id, nome: prestador.nome, crm: prestador.crm}
        }else prestador = null

        // verificação do preenchimento dos campos
        if(consulta==="")       err["consulta"]         = true
        if(especialidade==="")  err["especialidade"]    = true
        if(status==="")         err["status"]           = true
        if(paciente===null)     err["paciente"]         = true
        if(prestador===null)    err["prestador"]        = true
        if(tempo===null)        err["tempo"]            = true
        if(dtIni===null)        err["dtIni"]            = true
        //if(hrIni===null)        err["hrIni"]            = true

        //VERIFICANDO EXISTÊNCIA DE ERROS
        for(let e in err){ ok=false; break }

        if(ok){
            dtFim = new Date(dtIni.getTime()+tempo.value*60000)
            data = {
                consulta,
                especialidade,
                status,
                paciente,
                prestador,
                dtIni,
                dtFim,
                observacao
            }
            if(id && id.length) this.updateAgenda(data)
            else this.insertInAgenda(data)
        }else {
            this.setState({err, flags:{...this.state.flags, validado:true}})
            swal.fire({
                //position: 'top-start',
                type: 'error',
                title: 'Verifique os campos obrigatórios',
                showConfirmButton: false,
                timer: 3000
            })
        }

        
    }

    render() {
        //const {prestadores}     = this.props
        const { especialidades,
                planos,
                intervalos,
                pacientes,
                paciente,
                tipos,
                status,
                tempo,
                observacao,
                especialidade,
                consulta, 
                dtIni,
                //hrIni,
                //prestador,
                //prestadores,
                id,
                err,
                flags     }     = this.state
        const timeConfig        ={
            hours: { min: 7, max: 21 },
            minutes: { step: 5 }
        }
        //console.log(prestador,prestadores)
        if(flags.inserted)
            swal.fire({
                //position: 'top-start',
                type: 'success',
                title: id && id.length ? 'Dados Atualizados' : 'Dados enviados',
                showConfirmButton: false,
                timer: 2000
            })

        
        return (
            <form id="form" className="box" style={{background:'#FFF', color:'#000', padding: '1em'}}>
                <label className={"col-xs-12 col-sm-6"+(flags.validado ? (err.paciente ? ' err' : ' ok') :'')}><b>Paciente<sup>*</sup></b>
                    <CreatableSelect
                        cashOptions
                        className           ="mySelect col-lg-3 text-left"
                        classNamePrefix     ="_"
                        placeholder         ="Selecione o Paciente"
                        value               ={paciente}
                        isSearchable        ={true}
                        isClearable         ={true}
                        onChange            ={this.handleClientChange}
                        noOptionsMessage    ={() => "Opções não disponíveis"}
                        options             ={convertDataToSelectReact(pacientes)} //,"cpf"
                    />
                </label>
                <label className={"col-xs-12 col-sm-6"+(flags.validado ? (err.consulta ? ' err' : ' ok') :'')}><b>Tipo de Consulta<sup>*</sup></b>
                    <SelectR
                        cashOptions
                        name                ="consulta"
                        className           ="mySelect col-lg-3 text-left"
                        classNamePrefix     ="_"
                        placeholder         ="Selecione o tipo da Consulta"
                        //isSearchable        ={true}
                        isClearable         ={true}
                        onChange            ={this.handleSelectRChange}
                        noOptionsMessage    ={() => "Opções não disponíveis"}
                        value               ={consulta}
                        options             ={planos}
                    />
                </label>
                
                <label className={"d-none col-xs-12 col-sm-6"+(flags.validado ? (err.status ? ' err' : ' ok') :'')}><b>Situação<sup>*</sup></b>
                    <SelectR
                        cashOptions
                        name                ="status"
                        className           ="mySelect col-lg-3 text-left"
                        classNamePrefix     ="_"
                        placeholder         ="Selecione o tipo da Consulta"
                        isClearable         ={true}
                        onChange            ={this.handleSelectRChange}
                        noOptionsMessage    ={() => "Opções não disponíveis"}
                        value               ={status}
                        options             ={tipos}
                    />
                </label>
                
                <label className={"d-none col-xs-12 col-sm-6"+(flags.validado ? (err.especialidade ? ' err' : ' ok') :'')}><b>Especialidade<sup>*</sup></b>
                    <SelectR
                        cashOptions
                        name                ="especialidade"
                        className           ="mySelect col-lg-3 text-left"
                        classNamePrefix     ="_"
                        placeholder         ="Selecione a Especialidade"
                        isSearchable        ={true}
                        isClearable         ={true}
                        onChange            ={this.handleSelectRChange}
                        noOptionsMessage    ={() => "Opções não disponíveis"}
                        value               ={especialidade}
                        options             ={especialidades} 
                    />
                </label>
                <label className={"col-xs-12 col-sm-6"+(flags.validado ? (err.dtIni ? ' err' : ' ok') :'')}><b>Data e Hora<sup>*</sup></b>
                    <Datetime 
                        timeFormat          ={true}
                        locale              ="pt-br" 
                        className           ="dtIni"
                        value               ={new Date(dtIni)}
                        timeConstraints     ={timeConfig}
                        onChange            ={this.handleIniDTchange}
                    />
                </label>

                <label className={"col-xs-12 col-sm-6"+(flags.validado ? (err.tempo ? ' err' : ' ok') :'')}><b>Tempo de Atendimento<sup>*</sup></b>
                    <SelectR
                        cashOptions
                        name                ="tempo"
                        className           ="mySelect col-lg-3 text-left"
                        classNamePrefix     ="_"
                        placeholder         ="Selecione o tempo de atendimento"
                        isSearchable        ={true}
                        isClearable         ={true}
                        value               ={tempo}
                        onChange            ={this.handleSelectRChange}
                        noOptionsMessage    ={() => "Opções não disponíveis"}
                        options             ={intervalos} />
                </label>
                <label className="col-xs-12"><b>Observações<sup></sup></b>
                    <textarea name="observacao" value={observacao} onChange={this.handleTAchange}/>
                </label>
                <label className="col-xs-12" style={{display: 'none'}}>
                    <button type="button" id="newAgenda" className="submit" onClick={this.handleSubmit}>CADASTRAR</button>
                </label>
            </form>
        )
    }
}

export default insertTask
/*

                <label className={"col-xs-12 col-sm-6"+(flags.validado ? (err.prestador ? ' err' : ' ok') :'')}><b>Prestador<sup>*</sup></b>
                    <SelectR
                        cashOptions
                        className           ="mySelect col-lg-3 text-left"
                        classNamePrefix     ="_"
                        placeholder         ="Selecione um Médico"
                        value               ={prestador}
                        isSearchable        ={true}
                        isClearable         ={true}
                        onChange            ={this.handleDoctorChange}
                        noOptionsMessage    ={() => "Opções não disponíveis"}
                        options             ={convertDataToSelectReact(prestadores, "crm")} />
                </label>
                
                <label className={"col-xs-12 col-sm-6"+(flags.validado ? (err.hrIni ? ' err' : ' ok') :'')}><b>Hora<sup>*</sup></b>
                    <Datetime 
                        dateFormat          ={false}
                        locale              ="pt-br" 
                        className           ="hrIni"
                        value               ={new Date(hrIni)}
                        timeConstraints     ={timeConfig}
                        onChange            ={this.handleIniHRchange}
                    />
                </label>
*/
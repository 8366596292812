import React, { Component } from 'react'
import Axios from 'axios'
import NumberFormat from 'react-number-format'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faEdit, faEye } from '@fortawesome/free-regular-svg-icons'

import TooltipTrigger from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";

import './itemsList.scss'
import * as Constant from '../../_constants'
import { Phone } from '@material-ui/icons'
import { Divider } from '@material-ui/core'

export class AvalList extends Component {
    state={
        doctors:    []
    }
    componentDidMount() {
        const listar = async () => {
            const {data} = await Axios.get(`${Constant.myServer}/agenda/prestadores/list/all`)
            if(data.prestadores==="Ok"){
                const doctors = data.item
                this.setState({doctors})
            }
        }
        listar()
    }
    
    showTollTip = (txt, btn) => {
        const tooltip = ({
            getTooltipProps,
            getArrowProps,
            tooltipRef,
            arrowRef,
            placement
         }) => {
            return (
            <div
                {...getTooltipProps({
                ref: tooltipRef,
                className: "tooltip-container" 
                })}
            >
                <div
                {...getArrowProps({
                    ref: arrowRef,
                    "data-placement": placement,
                    className: "tooltip-arrow"
                })}
                />
                <p>{txt}</p>
            </div>
            );
        }
        const trigger =  ({ getTriggerProps, triggerRef }) => {
            return (
                <span
                    {...getTriggerProps({
                    ref: triggerRef,
                    className: "trigger"
                    })}
                >
                   {btn}
                </span>
            );
        } 
    
        return(
            <TooltipTrigger  placement="top"  trigger="hover"  tooltip={tooltip}>
                {trigger}
            </TooltipTrigger>
        )
    }

    getEspecs = () => {
        const   pps         = this.props,
                stt         = this.state,
                {doctors}   = stt,
                filter      = pps.filter && pps.filter.label ? pps.filter.label : "";
        let     result     = <></>;
        
        if(doctors.length){
            result = (
                    <>
                    {
                        doctors.map(d => {
                            const espec = JSON.stringify(d.especialidade).replace(/"|\[|\]/g,'').replace(/,/g,', ')
                            if(filter==="" || espec.indexOf(filter)>-1)
                             return (
                                <div key={d._id} className="col-sm-12 col-md-6 col-lg-4 list-item">
                                    <div className="item" onClick={()=>{pps.funcEditDoctor(d._id)}}>
                                        <div className="btns d-none">
                                            { this.showTollTip("Excluir",   <button><FontAwesomeIcon icon={faTrashAlt} /></button>) }
                                            { this.showTollTip("Visualizar",<button ><FontAwesomeIcon icon={faEye} /></button>) }
                                            { this.showTollTip("Editar",    <button onClick={()=>{pps.funcEditDoctor(d._id)}}><FontAwesomeIcon icon={faEdit} /></button>) }
                                        </div>
                                        <div className="image">
                                            <img src="./img/person.png" alt="" className={"invert sem"}/>
                                        </div>
                                        <h1 className="titulo">
                                            <div>
                                                <small className="item-espec">{espec}</small> &nbsp;
                                                <small className="item-doc">CRM:
                                                    <b>
                                                        <NumberFormat 
                                                            value               ={d.crm}
                                                            decimalSeparator    =","
                                                            thousandSeparator   ={"."}
                                                            displayType         ={"text"}
                                                        />
                                                    </b>
                                                </small>
                                            </div>
                                            {d.nome}
                                            
                                        </h1>
                                        <div className="dados" style={{flexDirection: "column"}}>
                                            <div className="mt-1 mb-2"><Divider /></div>
                                            <div className="painel">
                                                <b className="icon"><Phone /></b>
                                                <NumberFormat 
                                                    value               ={d.telefone}
                                                    format              ={d.telefone.length===10 ? "(##) ####-####" :"(##) # ####-####"}
                                                    displayType         ={"text"}
                                                />
                                                <br/>
                                                <b className="icon"><Phone /></b>
                                                <NumberFormat 
                                                    value               ={d.celular}
                                                    format              ={d.celular.length===10 ? "(##) ####-####" :"(##) # ####-####"}
                                                    displayType         ={"text"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                         })
                    }
                </>
            )
        }
        return result
    }

    render() {
        return (
            <div id="itemsList" className="list-body">
                <div className="row">
                    {this.getEspecs()}
                </div>
            </div>
        )
    }
}

export default AvalList

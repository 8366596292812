import React, { Component } from 'react'
import Datetime from 'react-datetime'
import Select from 'react-select'
import {diasHorarios2} from '../../_constants'
import {myMask} from '../functions'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons'

export class Horarios extends Component {
    handleAddHorario =  () => {
        this.props.funcUpd({id: (new Date()).getTime(), day:-1, ini:"", fim:"", tipo: this.props.tipo}, "UPD")
    }

    handleDelHorario =  (event) => {
        const {name} = event.currentTarget
        this.props.funcUpd({id: name*1}, "DEL")
    }

    handleHoraChange = event =>{
        const {value, input} = event,
            {props} = input,
            {mask, name, className, id} = props;

        let item = this.props.dados.filter(d => d.id===className)
        
        if(value._isValid){
            item = item[0]
            if(value._d){
                item[name] = value._d.toString().substr(16,5)
            }else if(value._i){
                item[name] = value._i
            } 
            this.props.funcUpd(item, "UPD")
        }else{
            const nVal = myMask(value, mask)
            if(item.length){
                item = item[0]
                item[name] = nVal
                this.props.funcUpd(item, "UPD")
                
            }
        }
    }

    onDiasChange = (event,{name}) => {
        const pps = this.props;
        let item  = pps.dados.filter(d => d.id===name),
            {idx} = pps;
        //console.log(event, name, item)
        if(item.length){
            item = item[0]
            item["day"] = event && event.value ? event.value : ""
            pps.funcUpd(item, "UPD", idx)
        }
    }

    componentDidMount(){
        
    }
    render() {
        const   pps     = this.props,
                {dados,
                 idx } = pps;
        const timeConfig ={
            //hours: { min: 7, max: 21 },
            minutes: { step: 5 }
        }
        let dadosSel    = [],
            content     = <></>

        if(dados){
            dadosSel= dados
            //dadosSel = dados.filter(d => d.tipo===tipo)
        }//else dadosSel = [{id: (new Date()).getTime(), day:7, ini:"", fim:"", tipo}]
        
        
        content = dadosSel.sort((a,b)=>{
                if(a.day<b.day){
                    return -1
                }else if(a.day>b.day){
                    return 1
                }else{
                    if(a.ini<b.ini){
                        return -1
                    }else if(a.ini>b.ini){
                        return 1
                    }else{ return 0 }
                }
            }).map(d => {
            return(
                <div key={d.id} className="row">
                    <label className= {"col-xs-3 mb-0"}>
                        <Select
                            value                   ={diasHorarios2.filter(i => d.day===i.value)}
                            isClearable             ={true}
                            //styles                  ={styles}
                            onChange                ={this.onDiasChange}
                            placeholder             ="Selecione..."
                            name                    ={d.id}
                            className               ="mySelect text-left"
                            classNamePrefix         ="_"
                            noOptionsMessage        ={() => "Opções não disponíveis"}
                            options                 ={diasHorarios2}
                        />
                    </label>
                    <label className={"col-xs-1 mb-0"}><br/>
                        <b>DAS</b>
                    </label>
                    <label className={"col-xs-3 mb-0"}>
                        <Datetime 
                            dateFormat          ={false}
                            locale              ="pt-br" 
                            name                ="ini"
                            className           ={d.id}
                            closeOnSelect       ={true}
                            mask                ="99:99"
                            inputProps          ={{maxLength:5, value:d.ini}}
                            value               ={d.ini instanceof Date && !isNaN(d.ini.valueOf())  ? new Date(d.ini) : d.ini}
                            timeConstraints     ={timeConfig}
                            onChange            ={this.handleHoraChange}
                            //onKeyUp          ={this.handleKeyDownTime}
                        />
                    </label>
                    <label className={"col-xs-1 mb-0"}><br/>
                        <b>ÀS</b>
                    </label>
                    <label className={"col-xs-3 mb-0"}>
                        <Datetime 
                            dateFormat          ={false}
                            locale              ="pt-br" 
                            name                ="fim"
                            className           ={d.id}
                            closeOnSelect       ={true}
                            mask                ="99:99"
                            inputProps          ={{maxLength:5}}
                            value               ={d.fim instanceof Date && !isNaN(d.fim.valueOf())  ? new Date(d.fim) : d.fim }
                            timeConstraints     ={timeConfig}
                            onChange            ={this.handleHoraChange}
                        />
                    </label>
                    <label className={"col-xs-1 mb-0"}>
                        <button 
                            className   ="btNew ml-0 w-100"
                            type        ="button"
                            style       ={{minWidth:"auto"}}
                            onClick     ={this.handleDelHorario}
                            name        ={d.id}
                        >
                                <FontAwesomeIcon icon={faTimes}/>
                        </button>
                    </label>
                </div>
            )
        })
        
        //console.log(dados,content)

        return (
            <div>
                {content}
                <div className="row">
                    <label className={"col-xs-12"}><br/>
                        <button type="button" className="btNew w-100 ml-0" onClick={this.handleAddHorario}><FontAwesomeIcon icon={faPlus}/> Adicionar Intervalo</button>
                    </label>
                </div>
            </div>
        )
    }
}

export default Horarios

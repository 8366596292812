import React, { Component } from 'react'
import Axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'

import Dialog from '../dialog'
import Select from 'react-select';

import Client from '../forms/client'
import ListClients from './clientsList'

import * as Constant from '../../_constants'

Axios.defaults.withCredentials = true

export class Clients extends Component {
    state={
        filter      : "",
        client      : {},
        editClient  : false,
        updated     : false,
        inserted    : false,
        showDialog  : false,
        dados       : {},
        wait        : true,
        err         : {},
        redirect    : "",
        lists       : {
            planos  : []
        }
    }
    
    componentDidMount(){
        let labels = []
        const espec = async () => {
            const {data} = await Axios.get(`${Constant.myServer}/agenda/planos/list`)
            data.item.map(l => labels.push({label: l.nome}))
            this.setState({lists:{...this.state.lists,planos:labels}, wait:false})
        }
        espec()
    }

    handleClearSearch = () => this.setState({filter:""})

    handleFilterChange  = event =>{
        const {value}   = event.target,
            filter      = value ? value : ""
        this.setState({filter})
    }

    handleNovoClick     = () => this.setState({showDialog:true})
    
    hanleUpdState       = dados => this.setState({dados})

    hanleUpdClient      = client => this.setState({client, dados:client})

    dialogExit          = () => this.setState({showDialog:false, editClient:false})

    dialogConfirm       = () => {
        this.setState({wait:true, inserted:false, updated:false})
        if(!this.hasInvalidData()){
            let {dados} = this.state, plano = []

            for(let e in dados.plano){
                plano.push(dados.plano[e].label)
            }
            dados={...dados, plano}
            delete dados.masks
            const insert = async () => {
                const {data} = await Axios.post(`${Constant.myServer}/agenda/paciente/insert`,dados)
                if(data.paciente==="Ok"){
                    this.setState({wait:false, showDialog:false, inserted:true})
                }else this.setState({wait:false})
            }
            insert()
        }else this.setState({wait:false})
    }

    handleEditClient    = id => {
        const espec = async () => {
            const {data} = await Axios.get(`${Constant.myServer}/agenda/paciente/${id}/list`)
            if(data.paciente==="Ok"){
                this.setState({client:data.item[0], editClient:true, updated:false, dados:{}})
            }
        }
        espec()
    }

    handleSaveClient    = () => {
        let {dados} = this.state
        this.setState({wait:true, inserted:false, updated:false})
        if(!this.hasInvalidData()){
            let id      = dados._id, 
                plano   = []

            for(let e in dados.plano){
                plano.push(dados.plano[e].label)
            }
            dados={...dados, plano}
            delete dados.masks
            delete dados._id 
            
            const update = async () => {
                const {data} = await Axios.post(`${Constant.myServer}/agenda/paciente/${id}/update`,dados)
                if(data.paciente==="Ok"){
                    this.setState({wait:false, editClient:false, updated:true})
                }else this.setState({wait:false})
            }
            update()
        }else{
            if(!dados._id) this.setState({wait:false, editClient:false, err:{}})
            else this.setState({wait:false})
        }
    }

    // VALIDAÇÃO DO ENVIOD DE DADOS
    isValid = (val, numeric=false, length=true, trim=true) => {
        
        if(val===undefined) return false

        let v = trim && !numeric ? val.trim() : val,
            l = length ? (v.toString().length ? true: false): true,
            n = numeric ? (!isNaN(v)? true : false): true,
            r = l && n;
        return r
    }
    hasInvalidData = () => {
        let err     ={}, 
            hasErr  =false, 
            obj,  
            key,
            {
                cpf,
                plano,
                nome,
                particular,
                celular        } = this.state.dados;
        
        
        //AJUSTE: Substituição do texto do LABEL
        /*obj = {
            especialidade
        } 
        for (key in obj) 
            eval(`${key}=${key}.label.replace('Selecione...','')`) */
        

        //VALIDAÇÃO CAMPOS: TEXT
        obj = {
            cpf,
            nome,
            celular
        }
        for (key in obj)
            if(!this.isValid(obj[key]))
                err[key]=true
        
        
        //VALIDAÇÃO CAMPOS: NUMERIC
        /*obj = {
            valor
        }
        
        for (key in obj)
            if(!this.isValid(obj[key], true))
                err[key]=true*/

        //VALIDAÇÃO: Específicas
        if(!particular && (!plano || plano.length===0))
            err["plano"]=true
        
        //Verificação da existência de erro
        for (key in err)
            if (key) { hasErr=true; break }
            
        hasErr && this.setState({err})
        //console.log(err)
        return hasErr
    }

    render() {
        const   stt             = this.state,
                { lists,
                  filter,
                  client,
                  err,
                  editClient,
                  updated,
                  inserted,
                  wait }        = stt;


        let     config      ={
                                showCancel:     true,
                                showConfirm:    true,
                                showClose:      true,
                                cancelText:     "Cancelar",
                                confirmText:    "Cadastrar",
                                titleText:      <> &nbsp; <FontAwesomeIcon icon={faUsers} /> &nbsp; Novo Paciente </>,
                                classAdd:       "col-xs-12 col-sm-11 col-md-10 col-lg-9"
                            },
                cntt        =stt.showDialog ? <Client lists={lists} err={err} funcUpd={this.hanleUpdState} /> : <></>,
                dlg         =stt.showDialog ? <Dialog config={config} content={cntt} exit={this.dialogExit} confirm={this.dialogConfirm}/> : null,
                content     = lists && lists.planos && lists.planos.length
                                ? <ListClients filter={filter} funcEditDoctor={this.handleEditClient} />
                                : <></>;
        //console.log(filter)
        if(editClient){
            config = {
                showCancel:     true,
                showConfirm:    true,
                showClose:      true,
                cancelText:     "Cancelar",
                confirmText:    "Atualizar",
                titleText:      <> &nbsp; Edição de Paciente </>,
                classAdd:       "col-xs-12 col-sm-11 col-md-10 col-lg-9"
            }
            cntt        = <Client lists={lists} err={err} data={client} funcUpd={this.hanleUpdClient} />
            dlg         = <Dialog config={config} content={cntt}  exit={this.dialogExit} confirm={this.handleSaveClient}/>
        }else if(updated) content= <><ListClients filter={filter} funcEditDoctor={this.handleEditClient} /></>
        else if(inserted) content= <div><ListClients filter={filter} funcEditDoctor={this.handleEditClient} /></div>

        return (
            <>
                 <div id="cortina" style={{display: wait ? undefined : "none"}}>
                    <img
                     value      =   {filter}
                     src        =   "./img/loading.gif"
                     className  =   "wait" alt="Aguarde"
                    />
                </div>
                {dlg}
                <div>
                    <h2 className="title col-12" >Pacientes</h2>
                    <div className="filter  col-12 text-right header-main">
                        <button 
                            title       ="Novo Paciente"
                            className   ="btNew chip"
                            onClick     ={this.handleNovoClick}
                         >
                            <small>
                                Novo Paciente &nbsp; <FontAwesomeIcon icon={faPlus} />
                            </small>
                        </button>
                        <span className="search">
                            <input 
                                value           ={filter}
                                placeholder     ="Buscar por Nome"
                                className       ="box col-lg-3 text-left"
                                onChange        ={this.handleFilterChange}
                            />
                            <b 
                                className       ="clear"
                                style           ={{display: filter.length ? undefined : 'none'}}
                                onClick         ={this.handleClearSearch}
                             >
                                <FontAwesomeIcon icon={faTimes} />
                            </b>
                        </span>
                    </div>
                    {content}
                </div>
            </>
        )
    }
}

export default Clients

import React, { Component } from 'react'
import {formatDate} from '../functions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-regular-svg-icons'

export class waitCard extends Component {
    handleViewClick = () => {
        const {event} = this.props
        this.props.funcViewClick({item:event, title: event.status, start: new Date(event.dtIni), end: new Date(event.dtFim)})
    }
    render() {
        const e   =   this.props.event
        
        return (
            <div className={"card-wait"} >
                {e.paciente.nome}
                <br/>
                <small>{e.paciente.phone}</small>
                <br/>
                <small>{formatDate(new Date(e.dtIni))}</small>
                <span id={e._id}
                      className="edit"
                      onClick={this.handleViewClick} 
                >
                    <small>Detalhes</small>
                </span>
            </div>
        )
    }
}

export default waitCard

/*

                <br/>
                <small>
                    <b>{item.especialidade.toUpperCase()}</b>
                </small>
*/
import React, { Component } from 'react'
import Axios from 'axios'
import {Redirect} from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserMd, faPlus } from '@fortawesome/free-solid-svg-icons'

import Select from 'react-select';

import Doctor from '../forms/doctor'
import Dialog from '../dialog'
import ListDoctors from './doctorsList'

import '../forms/form-dialog.scss'
import * as Constant from '../../_constants'

Axios.defaults.withCredentials = true

export class Doctors extends Component {
    state={
        filter      : "",
        doctor      : {},
        editDoctor  : false,
        updated     : false,
        showDialog  : false,
        dados       : {},
        wait        : true,
        err         : {},
        redirect    : "",
        lists       : {
            especialidades  : [],
            planos          : []
        }
    }
    componentDidMount(){
        let especialidades  = [],
            planos          = [];
        const espec = async () => {
            let resp = await Axios.get(`${Constant.myServer}/agenda/expertise/list`)
            resp.data.item.map(l => especialidades.push({label: l.nome}))

            resp = await Axios.get(`${Constant.myServer}/agenda/planos/list`)
            resp.data.item.map(l => planos.push({label: l.nome}))

            this.setState({lists:{especialidades, planos}, wait:false})
        }
        espec()
    }
    handleFilterChange  = value =>{
        const filter = value ? value : ""
        this.setState({filter})
    }

    handleEditDoctor    = id => {
        const espec = async () => {
            const {data} = await Axios.get(`${Constant.myServer}/agenda/prestador/${id}/list`)
            //console.log(data)
            if(data.prestadores==="Ok"){
                this.setState({doctor:data.item[0], editDoctor:true, updated:false, dados:{}})
            }
        }
        espec()
    }

    hanleUpdDoctor      = doctor => {
        this.setState({doctor, dados:doctor})
    }
    hanleUpdState       = dados => this.setState({dados})
    handleNovoClick     = () => this.setState({showDialog:true})
    dialogExit          = () => this.setState({showDialog:false, editDoctor:false})
    

    // VALIDAÇÃO DO ENVIOD DE DADOS
    handleSaveDoctor    = () => {
        let {dados} = this.state
        //console.log(dados); return
        this.setState({wait:true})
        if(!this.hasInvalidData()){
            let id              = dados._id, 
                plano           = [],
                especialidade   = [],
                e;

            for(e in dados.plano){ plano.push(dados.plano[e].label) }
            for(e in dados.especialidade){ especialidade.push(dados.especialidade[e].label) }

            dados={...dados, especialidade, plano}
            delete dados.masks
            delete dados._id
            delete dados.conselhos
            delete dados.abaHorario
            
            const update = async () => {
                const {data} = await Axios.post(`${Constant.myServer}/agenda/prestador/${id}/update`,dados)
                if(data.prestador==="Ok"){
                    this.setState({wait:false, editDoctor:false, updated:true})
                }else this.setState({wait:false})
            }
            update()
        }else{
            if(!dados._id) this.setState({wait:false, editDoctor:false, err:{}})
            else this.setState({wait:false})
        }
    }
       
    dialogConfirm       = () => {
        //console.log(this.state.dados, this.hasInvalidData());return
        this.setState({wait:true})
        if(!this.hasInvalidData()){
            let {dados} = this.state, especialidade = [], plano = [], e;

            for(e in dados.especialidade){ especialidade.push(dados.especialidade[e].label) }
            for(e in dados.plano){ plano.push(dados.plano[e].label) }
            dados={...dados, especialidade, plano}
            delete dados.masks
            const insert = async () => {
                const {data} = await Axios.post(`${Constant.myServer}/agenda/prestador/insert`, dados)
                if(data.prestador==="Ok"){
                    this.setState({wait:false, showDialog:false})
                }else this.setState({wait:false})
            }
            insert()
        }else this.setState({wait:false})
    }

    isValid = (val, numeric=false, length=true, trim=true) => {
        
        if(val===undefined) return false

        let v = trim && !numeric ? val.trim() : val,
            l = length ? (v.toString().length ? true: false): true,
            n = numeric ? (!isNaN(v)? true : false): true,
            r = l && n;
        return r
    }

    hasInvalidData = () => {
        let err     ={}, 
            hasErr  =false, 
            obj,  
            key,
            {
                cpf,
                especialidade,
                nome,
                celular        } = this.state.dados;
        
        
        //AJUSTE: Substituição do texto do LABEL
        /*obj = {
            especialidade
        } 
        for (key in obj) 
            eval(`${key}=${key}.label.replace('Selecione...','')`) */
        

        //VALIDAÇÃO CAMPOS: TEXT
        obj = {
            cpf,
            nome,
            celular
        }
        for (key in obj)
            if(!this.isValid(obj[key]))
                err[key]=true
        
        
        //VALIDAÇÃO CAMPOS: NUMERIC
        /*obj = {
            valor
        }
        
        for (key in obj)
            if(!this.isValid(obj[key], true))
                err[key]=true*/

        //VALIDAÇÃO: Específicas
        if(!especialidade || especialidade.length===0)
            err["especialidade"]=true
        
        //Verificação da existência de erro
        for (key in err)
            if (key) { hasErr=true; break }
            
        hasErr && this.setState({err})
        //console.log(err, this.state.dados)
        return hasErr
    }

    handleHorariosOn = (obj) => {
        console.log("ON", obj)
    }
    
    handleHorariosOff = (obj) => {
        console.log("OFF", obj)
    }

    //handleClickTeste    = () => console.log("clicou")
    render() {
        const   stt             = this.state,
                { lists,
                  filter,
                  doctor,
                  err,
                  redirect,
                  editDoctor,
                  updated,
                  wait }        = stt;

        const myHr = [  {day: 1, ini: "08:00", fim: "12:00"},
                        {day: 1, ini: "13:00", fim: "17:00"},
                        {day: 1, ini: "18:00", fim: "21:00"},
                        {day: 2, ini: "08:00", fim: "12:00"},
                        {day: 2, ini: "13:00", fim: "17:00"},
                        {day: 2, ini: "18:00", fim: "21:00"},
                        {day: 3, ini: "08:00", fim: "12:00"},
                        {day: 3, ini: "18:00", fim: "21:00"},
                        {day: 4, ini: "08:00", fim: "12:00"},
                        {day: 4, ini: "13:00", fim: "17:00"},
                        {day: 4, ini: "18:00", fim: "21:00"},
                        {day: 5, ini: "08:00", fim: "12:00"},
                        {day: 5, ini: "13:00", fim: "17:00"},
                        {day: 5, ini: "18:00", fim: "21:00"}    ]

        if(redirect)
            return <Redirect to={redirect} />

        let     config      ={
                                showCancel:     true,
                                showConfirm:    true,
                                showClose:      true,
                                cancelText:     "Cancelar",
                                confirmText:    "Cadastrar",
                                titleText:      <> &nbsp; <FontAwesomeIcon icon={faUserMd} /> &nbsp; Novo Prestador </>,
                                classAdd:       "col-xs-12 col-sm-11 col-md-10 col-lg-9"
                            },
                cntt        =stt.showDialog ? <Doctor lists={lists} err={err} funcUpd={this.hanleUpdState} /> : <></>,
                dlg         =stt.showDialog ? <Dialog config={config} content={cntt} exit={this.dialogExit} confirm={this.dialogConfirm}/> : null,
                content     = lists && lists.especialidades && lists.especialidades.length
                                ? <ListDoctors filter={filter} funcEditDoctor={this.handleEditDoctor} />
                                : <></>;

        if(editDoctor){
            config = {
                showCancel:     true,
                showConfirm:    true,
                showClose:      true,
                cancelText:     "Cancelar",
                confirmText:    "Atualizar",
                titleText:      <> &nbsp; Edição de Prestador: <span className="cor5">{doctor.nome}</span> </>,
                classAdd:       "col-xs-12 col-sm-11 col-md-10 col-lg-9"
            }
            cntt        = <Doctor lists={lists} err={err} data={doctor} funcUpd={this.hanleUpdDoctor} />
            dlg         = <Dialog config={config} content={cntt}  exit={this.dialogExit} confirm={this.handleSaveDoctor}/>
        }else if(updated) content= <><ListDoctors filter={filter} funcEditDoctor={this.handleEditDoctor} /></>

        return (
            <>
                 <div id="cortina" style={{display: wait ? undefined : "none"}}>
                    <img
                     src         =   "./img/loading.gif"
                     className   =   "wait" alt="Aguarde"
                    />
                </div>
                {dlg}
                <div>
                    <h2 className="title col-12" >Especialistas</h2>
                    <div className="filter col-12 text-right header-main">
                        <button 
                            title       ="Novo Prestador"
                            className   ="btNew chip"
                            onClick     ={this.handleNovoClick}
                         >
                            <small>
                                Novo Especialista &nbsp; <FontAwesomeIcon icon={faPlus} />
                            </small>
                        </button>
                        <Select
                            cashOptions
                            className           ="mySelect col-lg-3 text-left"
                            classNamePrefix     ="_"
                            placeholder         ="Selecione uma opção"
                            isSearchable        ={true}
                            isClearable         ={true}
                            onChange            ={this.handleFilterChange}
                            noOptionsMessage    ={() => "Opções não disponíveis"}
                            options             ={lists.especialidades} />
                    </div>
                    {content}
                </div>
            </>
        )
    }
}

export default Doctors

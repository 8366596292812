import React, { Component } from 'react'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons'

import Clients from './lists/clients'
import Doctors from './lists/doctors'
import Agenda from './lists/agenda'

import 'bootstrap-sass/assets/stylesheets/_bootstrap.scss'
import { faBell } from '@fortawesome/free-regular-svg-icons'

//import 'react-big-calendar/lib/css/react-big-calendar.css'
//import {Calendar, Views, momentLocalizer} from 'react-big-calendar';
//import moment from 'moment';

//require('globalize/lib/cultures/globalize.culture.pt-BR')
//require('./globalize.culture.pt-BR')
//const localizer = momentLocalizer(moment)
Axios.defaults.withCredentials = true
export class Main extends Component {
    state={
        
    }
    componentDidMount(){
       
    }
    
    render() {
        const   { select } = this.props.obj.data,
                { logOut } = this.props.obj.funcs;
        let content
        
        switch(select){
            case 1: break
            case 2:  
                content =<Agenda />; break    
            case 3: 
                content =<Doctors />; break
            case 4:
                content =<Clients />; break //this.refs.filter.loadOptions('');
            default:           
        }
        //console.log(0, lists)
        return (
            <div className="main">
                <div className="header d-none">
                    <div className="logo inlineBlock">
                        <span className="img"><FontAwesomeIcon icon={faBars} /></span>
                    </div>
                    <div className="rightMenu">
                        <div className="inlineBlock">
                            <span className="img"><FontAwesomeIcon icon={faBell} /></span>
                        </div>
                        <div className="logout" onClick={logOut}>
                            <span className="img"><FontAwesomeIcon icon={faSignOutAlt} /></span>
                            <span className="text">Sair</span>
                        </div>
                    </div>
                        
                </div>
                <div className="body">
                   {content}
                </div>
            </div>
        )
    }
}
export default Main

/*
 <AsyncSelect 
                        className="mySelect col-lg-3"
                        classNamePrefix="_"
                        cacheOptions 
                        defaultOptions
                        isLoading={true}
                        isSearchable={false}
                        loadOptions={this.getSelectOptions}
                    />
                    */

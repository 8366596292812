import React, { Component } from 'react'

export class Wait extends Component {
    render() {
        return (
            <div id= "cortina">
                <img
                    src         = "./img/loading.gif"
                    className   = "wait" alt="Aguarde"
                />
            </div>
        )
    }
}

export default Wait

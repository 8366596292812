import React from 'react';
import styled, { css } from 'styled-components';

import {simulate} from '../../functions'

import swal from 'sweetalert2'
import '../../sweetAlert.scss'


export default class Draggable extends React.Component {
  state = {
    isDragging: false,
    isVisible:  true,

    originalX: 0,
    originalY: 0,

    translateX: 0,
    translateY: 0,

    lastTranslateX: 0,
    lastTranslateY: 0,

    coords: {}
  };
  componentDidMount(){
      //const coords = document.getElementById(this.props.id).getBoundingClientRect()
      //this.setState({coords})
  }
  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseDown = ({ clientX, clientY }) => {
    const coords = document.getElementById(this.props.id).getBoundingClientRect()

    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);

    if (this.props.onDragStart) {
      this.props.onDragStart();
    }
    let c = coords
    //console.log(clientX, clientY, window.pageXOffset, c.left, c.left-window.pageXOffset, clientX-(c.left-window.pageXOffset))
    this.setState({
      originalX: clientX,
      originalY: clientY,
      lastTranslateX: clientX-(c.left)+13,
      lastTranslateY: clientY-(c.top)+13,
      isDragging: true,
      coords
    });
  };

  handleMouseMove = ({ clientX, clientY }) => {
    const { isDragging } = this.state;
    const { onDrag } = this.props;
    const element = document.getElementsByClassName("rbc-calendar")[0];

    if (!isDragging) {
      return;
    }
    try{element.classList.add("over");}catch(e){}
    this.setState(prevState => ({
      translateX: clientX - prevState.originalX + prevState.lastTranslateX,
      translateY: clientY - prevState.originalY + prevState.lastTranslateY
    }), () => {
      if (onDrag) {
        onDrag({
          translateX: this.state.translateX,
          translateY: this.state.translateY
        });
      }
    });
  };

  handleMouseUp = ({clientX, clientY}) => {
    const cb = document.elementFromPoint(clientX, clientY)

    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
    
    this.setState(
      {
        originalX: 0,
        originalY: 0,
        lastTranslateX: this.state.translateX,
        lastTranslateY: this.state.translateY,

        isDragging: false
      },
      () => {
        if (this.props.onDragEnd) {
          this.props.onDragEnd();
        }
      }
    );
    
    const element = document.getElementsByClassName("rbc-calendar")[0];
    try{element.classList.remove("over");}catch(e){}
    
    
    if(cb.className==="rbc-time-slot"){
      const pps = this.props,
            dt  = new Date()
      let key   = Object.keys(cb).find(key=>key.startsWith("__reactInternalInstance$")),
          val   = cb[key].return.memoizedProps.value,
          range = pps.funcGetRange();
      if(range.length>1){
        let day = val.getDay()
        val = new Date(range[day].toString().substr(0,16)+val.toString().substr(16,115))
        //console.log(nDt, val, range)
      }
      //console.log(dt, val, )
      if(dt.getTime()<val.getTime()){
        if(pps.type!=="doctor") this.setState({ isVisible: false })

        pps.funcOnDrop(pps.type, pps.id, val)
        
      }else{
        //console.log(key, cb[key].stateNode._debugOwner)
        swal.fire({
          type:             'error',
          title:            'Horário inválido!',
          showConfirmButton: false,
          timer:            1500  })
      }
      //key = Object.keys(cb).find(key=>key.startsWith("__reactEventHandlers$"))
    }
    this.resetMove()
    
  };
  resetMove = () => {
    this.setState({
      originalX: 0,
      originalY: 0,

      translateX: 0,
      translateY: 0,

      lastTranslateX: 0,
      lastTranslateY: 0,
      //isVisible: false
    })
  }
  render() {
    const { children, id, className, move, checked } = this.props;
    const { translateX, translateY, isDragging, isVisible } = this.state;
    
    return (
      <Container
        className   = {className}
        onMouseDown ={move ? this.handleMouseDown : ()=>{} }
        x           ={translateX}
        y           ={translateY}
        id          ={id}
        style       ={{order: checked ? "1" : "2"}}
        isVisible   ={isVisible}
        isDragging  ={isDragging}
      >
        {children}
      </Container>
    );
  }
}

const Container = styled.div.attrs({
  style: ({ x, y}) => ({
    transform: `translate(${x}px, ${y}px)`
  }),
})`
${({ move }) =>  move && css`cursor: grab;`};
${({ isVisible }) => 
  !isVisible && css`
    display: none;
  `};
${({ isDragging }) =>
  isDragging && css`
    opacity: 0.8;
    cursor: grabbing;
    position: relative!important;
    z-index:9!important
  `};
`;
import React, { Component } from 'react'
import Axios from 'axios'

import JsCalendar from '../myCalendar/jsc' 
//import {Redirect} from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faHandPointLeft, faCommentAlt, faCalendarAlt } from '@fortawesome/free-regular-svg-icons'

import Eventos from '../forms/fastEvent'
import EventosUpd from '../forms/consulta'
import Avisos from '../forms/aviso'

import Dialog from '../dialog'

//import { withSwalInstance } from 'sweetalert2-react';
import swal from 'sweetalert2'
import '../sweetAlert.scss'

import '../forms/form-dialog.scss'
import {myServer, acentoPorPonto} from '../../_constants'
import {dateInterval} from '../functions'

import { Calendar, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import moment from 'moment'
import './react-big-calendar.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'

import Wait from '../wait'
import Select from 'react-select';


require("moment/min/locales.min");
moment.locale('pt-br')
//console.log(moment())
const localizer = momentLocalizer(moment)
const DraggableCalendar = withDragAndDrop(Calendar)

//const allViews = Object.keys(Calendar.Views).map(k => Calendar.Views[k]);
/*const Events =[ {
    title: "Consulta",
    start: new Date("2019-10-07 19:00"),
    end: new Date("2019-10-07 20:00"),
    desc: "Francisco de Assis - Unimed"
    //allDay: false
    //resource?: any,
  }]*/

Axios.defaults.withCredentials = true
let searchTime = null
function Event({ event }) { //console.log(event)
    return (
        <span>
            <strong>
                {event.title}
            </strong>
            { event.desc && (':  ' + event.desc)}
        </span>
    )
}

function EventAgenda({ event }) { console.log("EventAgenda")
    return (
        <span>
            <em >{event.title}</em>   <p>{ event.desc }</p>
        </span>
    )
}

const Tollbar = (event) => {
    return <p>teste</p>
}

const convertDataToSelectReact = obj => {
    let labels = []
    obj.map(i => {labels.push({value: i.codigo || i._id, label: i.nome || i.desc}); return true })
    return labels
}


export class Agenda extends Component {
    state={
        wait:       true,
        show:       true,
        timeSel:    {ini:0, fim:0},
        doctors:    [],
        doctorSel:  "",
        events:     [],
        evntPcnt:   [],
        idUpd:      "",
        dados:      {},
        novo:       false,
        update:     false,
        novoAviso:  false,
        especFiltro: [],
        especialidade: "",
        especialidades: [],
        comboSel:       [],
        comboSelVal:    null,
        horarios:       [],
        range:          []
    }

    componentDidMount(){
        let doctors = [],
            events  = [],
            especs  = [],
            comboSel= [{label:"Especialidades", options:[]}] //,{label:"Prestadores", options:[]}
        const espec = async () => {
            let res = await Axios.get(`${myServer}/agenda/prestadores/list/all/?t=${Math.random()}`)
            doctors = res.data.item
            //doctors.map(d =>{ comboSel[1].options.push({value:d._id, label:d.nome}) })
            res = await Axios.get(`${myServer}/agenda/expertise/list`)
            especs  = res.data.item || []
            especs.map(d =>{ comboSel[0].options.push({value:d._id, label:d.nome}) })
            this.setState({doctors, events, especialidades: especs, especFiltro: especs, comboSel, wait:false})
        }
        espec()
    }

    handleViewEvent = event => this.onEventClick(event)

    onEventClick(event) { 
        const app = this,
            {
            title,
            end,
            start,
            item
                }   = event;
        //Shows the event details provided while booking
        let dt = dateInterval(start, end), 
            btn = <buttton onClick={() => console.log("CLICK")}>EDITAR</buttton>
        
        swal.fire({
            title: `<strong>${title}</strong>`,
            type: 'info',
            html:   `<h5 style='padding:8px;text-align:left'><p'><b>Paciente</b>: ${item.paciente.nome}</p>`+
                    `<p><b>Prestador</b>: ${item.prestador.nome}</p>`+
                    `<p><b>Especialidade</b>: ${item.especialidade}</p>`+
                    `<p><b>Consulta</b>: ${item.consulta}</p>`+
                    `<p'><b>Horário</b>: ${dt}</p>`+
                    `<p><b>Observação</b>: ${item.observacao}</p></h5>`,
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: '<big><i class="fa fa-edit"></i> Editar</big>',
            cancelButtonText: '<big> &nbsp; <i class="fa fa-thumbs-up"></i> OK &nbsp; </big>',
            confirmButtonAriaLabel: ''
          }).then((result) => {
            if (result.value) {
                app.setState({update:true, idUpd:item._id})
            }
          })
    }

    onEventDrop = data => { //console.log("drop", data)
        const {events}  = this.state,
            {start,
             event}     = data,
             hrAtual    = new Date();
        let evnts       = [], 
            evnt        = {...event},
            delta       = evnt.end.getTime() - evnt.start.getTime()

        
        if(evnt.start.getTime() !== start.getTime()){
            if(hrAtual.getTime() < start.getTime()){
                evnt.start  = start
                evnt.end    = new Date(start.getTime()+delta)
                events.map(e => {
                    if(e.item._id === evnt.item._id){
                        evnts.push(evnt)
                    }else{
                        evnts.push(e)
                    }
                })
                const update = async () => {
                    let res = await Axios.post(`${myServer}/agenda/compromisso/update/${evnt.item._id}`,{dtIni: evnt.start, dtFim:evnt.end})
                    if(res.data.agenda){
                        this.setState({events:evnts})
                    }
                }
                update()
            }else{
                swal.fire({
                    type: 'warning',
                    title: 'Horário Inválido',
                    showConfirmButton: false,
                    timer: 2000})
            }
        }
    }

    onSelecting = (data) =>{
        //console.log("onSelecting",data)
    }

    draggableAccessor= (data) => console.log("draggableAccessor",data) 

    handleNovoClick = () => { this.setState({novoAviso:true}); }

    handleInsAviso  = () => { document.getElementById('formAvisoSubmit').click()  }

    handleUpdAviso  = aviso => { this.dialogExit() }

    setWait         = wait => this.setState({wait})

    dialogExit      = () => {
        this.setState({novo:false, novoAviso: false, update:false, idUpd:"", dados:{}, wait:false})
        if(this.state.idUpd.length)
            try{
                document.getElementById(this.state.idUpd).style.display = "inherit"
            }catch(e){}
    }

    handleInsAgenda = () => document.getElementById('newAgenda').click()

    handleUpdAgenda = obj => {
        this.dialogExit()
        let {events}    = this.state,
            novo        = true

        events.map(e => {
           if(e.item._id===obj._id){
               novo     = false
               let a    = obj
                e.title = a.status
                e.start = new Date(a.dtIni)
                e.end   = new Date(a.dtFim)
                e.desc  = `${a.paciente.nome} (${a.consulta}) - ${a.prestador.nome} (${a.especialidade})`
                e.item  = a
           }
        })
        if(novo){ //console.log(obj)
            const e ={
                title:  obj.status,
                start:  new Date(obj.dtIni),
                end:    new Date(obj.dtFim),
                desc:   `${obj.paciente.nome} (${obj.consulta}) - ${obj.prestador.nome} (${obj.especialidade})`,
                item:   obj
            }
            events.push(e)
        }
        this.setState({events})
        //console.log(obj, this.state.events)
    }

    onSlotClick(slotInfo) {  
        const   {start, end}    = slotInfo,
                dt              = new Date();
        
        if(start.getMinutes()+start.getHours() !== 0 && end.getMinutes()+end.getHours()){
            if(this.state.doctorSel && dt.getTime()<start.getTime())
                this.handleDropOnCalendar('doctor', this.state.doctorSel, start)
        }
    }

    // >>> Disparado quando um CARD é solto sobre o calendário
    handleDropOnCalendar = (type, id, date) => { 
        // type=["doctor","event"]
        //console.log(type, id, date)
        let dados = {
            //consulta:       {label: 'Particular'},
            //status:         {label: 'Agendamento'},
            //especialidade:  {label: ''},
            //paciente:       {value: a.paciente.id, label: a.paciente.nome + ' - CPF '+a.paciente.cpf},
            //prestador:      {value: a.prestador.id, label: a.prestador.nome + ' - CRM '+a.prestador.crm},
            //observacao:     a.observacao,
            //tempo:          {value: 30, label:"30 min"},
            dtIni:          new Date(date),
            hrIni:          new Date(date)
        },
            idUpd              = "";
        if(type==="event") idUpd = id
        switch(type){
            case "event":
                idUpd = id; 
                this.setState({update:true, dados, idUpd})
                break
            case "doctor":
                let especialidades  = [],
                    especialidade   = "",
                    planos          = [{label:"Particular"}],
                    dr              = this.state.doctors.filter(d => d._id === id);

                if(dr.length && dr[0].especialidade){
                    dr[0].especialidade.map(e => { especialidades.push({label:e}) })
                    especialidade = especialidades[0]

                    dr[0].plano.map(e => { planos.push({label:e}) })

                    dados["prestador"]      = {value: dr[0]._id, label: dr[0].nome + ' - CRM ' + dr[0].crm}
                    dados["especialidades"] = especialidades
                    dados["especialidade"]  = especialidade
                    dados["planos"]         = planos
                    //console.log(dados)
                }
                this.setState({novo:true, dados, idUpd})
                break
            default: break
        }
    }

    handleGSelChange = sel => {
        try{
            const {value, label} = sel,
                espec =  this.state.especialidades.filter( e => e._id===value)
            if(espec.length)    this.setState({especialidade:label})
            else{
                document.getElementById(value).click()
                this.handleSelDoctor(value)
                this.setState({especialidade:"", doctorSel: value})
            }
        }catch(e){this.setState({especialidade:"",doctorSel:"", events:[]})}
    }

    updDoctorSel = id => this.setState({doctorSel: id})

    handleEPSelIChange = paciente => {
        const app = this
        paciente = acentoPorPonto(paciente)
        
        const select = async () => {
            let {data}      = await Axios.post(`${myServer}/agenda/pacientes/search`,{paciente}),
                pcnt        = data.pacientes || [],
                evntPcnt    = [];
            
            pcnt.map(d =>{ evntPcnt.push({value:d._id, label:d.nome}) })
            this.setState({evntPcnt, wait:false})
            searchTime=null
        }
        if(paciente.length>1){
            clearTimeout(searchTime)
            searchTime = setTimeout(()=>{app.setWait(true); select()}, 1000)
        }else if(paciente.trim().length===0){
            clearTimeout(searchTime)
            searchTime = setTimeout(()=>{app.setState({evntPcnt:[]})}, 1000)
        }
        
    }
    
    handleEPSelChange = val => {
        try{
            const id = val.value
            const select = async () => {
                let {data}      = await Axios.post(`${myServer}/agenda/compromissos/pacientes/search`,{id}),
                    {agenda}    = data;
                
                if(agenda && agenda.length>1){
                    let html = ""
                    agenda.map(a => {
                        const start = new Date(a.dtIni),
                                end = new Date(a.dtFim), 
                                dt  = dateInterval(a.dtIni, a.dtFim); 
                        html    += `<h5 style='padding:8px;text-align:center;justify-content:space-evenly;display:flex'><b style='display:flex'><input type='radio' onclick="document.getElementsByClassName('swal2-input')[0].value='${a._id}'" name='evnt' style='outline:none;width:1.2em;height:1.2em;margin:0'/>&nbsp;${a.status}</b> ${a.prestador.nome}<br/><b>${dt}</b></h5>`
                    })
                    html    += "<div id='swalErr' style='display:none'><big style='color:red'>Selecione um compromisso</big></div>"
                    swal.fire({
                        title: `<strong>${agenda[0].paciente.nome}</strong>`,
                        type: 'info',
                        html,
                        input: 'text',
                        width: 600,
                        customClass: {input:'hide'},
                        preConfirm: (txt) => {if(txt.trim().length){return new Promise((res) => { res({id:txt}) } )} document.getElementById("swalErr").style.display="inherit"; return false },
                        showCloseButton: false,
                        showCancelButton: true,
                        showConfirmButton: true,
                        focusConfirm: false,
                        confirmButtonText: '<big><i class="fa fa-edit"></i> Editar</big>',
                        cancelButtonText: '<big> &nbsp; <i class="fa fa-thumbs-up"></i> OK &nbsp; </big>',
                        confirmButtonAriaLabel: ''
                    }).then((result) => {
                        if (result.value && result.value.id) {
                            const idUpd     = result.value.id,
                                item        = agenda.filter(a => a._id===idUpd),
                                doctorSel   = item[0].prestador.id;

                            this.setState({update: true, doctorSel, idUpd})
                            this.handleSelDoctor(doctorSel)
                        }
                    })
                }else if(agenda && agenda.length===1){
                    let item    = agenda[0],
                        dt      = dateInterval(item.dtIni, item.dtFim); 
                    swal.fire({
                        title: `<strong></strong>`,
                        type: 'info',
                        html:   `<h5 style='padding:8px;text-align:left'><p'><b>Paciente</b>: ${item.paciente.nome}</p>`+
                                `<p><b>Prestador</b>: ${item.prestador.nome}</p>`+
                                `<p><b>Especialidade</b>: ${item.especialidade}</p>`+
                                `<p><b>Consulta</b>: ${item.consulta}</p>`+
                                `<p'><b>Horário</b>: ${dt}</p>`+
                                `<p><b>Observação</b>: ${item.observacao}</p></h5>`,
                        showCloseButton: false,
                        showCancelButton: true,
                        focusConfirm: false,
                        confirmButtonText: '<big><i class="fa fa-edit"></i> Editar</big>',
                        cancelButtonText: '<big> &nbsp; <i class="fa fa-thumbs-up"></i> OK &nbsp; </big>',
                        confirmButtonAriaLabel: ''
                    }).then((result) => {
                        if (result.value) {
                            const doctorSel = item.prestador.id
                            
                            this.setState({update: true, doctorSel, idUpd: item._id})
                            this.handleSelDoctor(doctorSel)
                            
                        }
                    })
                }
                
                this.setWait(false)
            }
            if(id.trim().length===24){
                this.setWait(true)
                select()
            }
        }catch(e){}
    }

    handleSelDoctor = doctorSel =>{ //console.log(doctorSel)
        const espec = async () => {
            let events      = [],
                doctor      = this.state.doctors.filter(d => d._id===doctorSel)[0]
            //console.log(this.state.doctors)
            if(doctorSel.length){
                const   {data}      = await Axios.post(`${myServer}/agenda/compromissos/list/${doctorSel}`,{})

                data.agenda.map(a => {
                    events.push({
                        title:      a.status,
                        start:      new Date(a.dtIni),
                        end:        new Date(a.dtFim),
                        desc:       `${a.paciente.nome} (${a.consulta}) - ${a.prestador.nome} (${a.especialidade})`,
                        item:       a,
                        allDay:     false
                    })
                })
            }

            this.setState({doctorSel, events, horarios: doctor && doctor.horarios ? doctor.horarios : []})
        }
        espec()
    }

    handleStatusChange = (id,status) => {
        let {doctors} = this.state
        doctors.map(d => {
            if(d._id===id){
                d['status']=status
            }
        })
        this.setState({doctors})
    }

    handleNewItem = (dtIni) =>{
        //console.log(this.state.doctorSel)
        this.setState({novo:true, dados:{...this.state.dados, dtIni}})
    }

    handleRangeChange = range => { this.setState({range});  } //console.log(range)

    handleGetRange = () => {  return this.state.range }

    getNewEvent = () => {
        const {doctors, doctorSel, dados} = this.state,
            doctor  = doctorSel.length ? doctors.filter(d => d._id===doctorSel) : []

        const config = {
            showCancel:     true,
            showConfirm:    true,
            showClose:      true,
            cancelText:     "Cancelar",
            confirmText:    "Enviar",
            titleText:      <> &nbsp; <FontAwesomeIcon icon={faCalendar} /> &nbsp; Inserção de Compromisso para <span className="cor5">{doctor[0].nome}</span></>,
            classAdd:       "col-xs-12 col-sm-11 col-md-10 col-lg-9"
        },
        cntt        = <Eventos id="" prestadores={doctor} dados={{planos:doctor[0].plano, status:{label:"Lista de Espera"}, dtIni:new Date(dados.dtIni)}} funcSetWait={this.setWait} funcSuccess={this.handleUpdAgenda} submit={false}/>;

        return <Dialog config={config} content={cntt}  exit={this.dialogExit} confirm={this.handleInsAgenda}/>
    }

    getNewAlert = () => {
        const {idUpd} = this.state

        const config = {
            showCancel:     true,
            showConfirm:    true,
            showClose:      true,
            cancelText:     "Cancelar",
            confirmText:    "Enviar",
            titleText:      <> &nbsp; <FontAwesomeIcon icon={faCommentAlt} /> &nbsp; Novo Aviso</>,
            classAdd:       "col-xs-12 col-sm-11 col-md-10 col-lg-9"
        },
        cntt        = <Avisos
                        id          ={idUpd}
                        funcSetWait ={this.setWait}
                        funcSuccess ={this.handleUpdAviso}
                     />;

        return <Dialog
                    config  ={config}
                    content ={cntt}
                    exit    ={this.dialogExit}
                    confirm ={this.handleInsAviso}
                />
    }

    getEventUpdate = () => {
        const {idUpd, doctors, doctorSel, dados} = this.state
        
        const dr = doctors.filter(d => d._id === doctorSel)
        const config = {
            showCancel:     true,
            showConfirm:    true,
            showClose:      true,
            cancelText:     "Cancelar",
            confirmText:    "Atualizar",
            titleText:      <> &nbsp; <FontAwesomeIcon icon={faCalendar} /> &nbsp; Alteração de Compromisso para <span className="cor5">{dr[0].nome}</span></>,
            classAdd:       "col-xs-12 col-sm-11 col-md-10 col-lg-9"
        },
        cntt        = <EventosUpd
                        id          ={idUpd} 
                        prestadores ={doctors} 
                        dataHora    ={dados.dtIni}
                        funcSetWait ={this.setWait}
                        funcSuccess ={this.handleUpdAgenda}
                        submit      ={false}
                     />;
        
        return <Dialog
                    config  ={config}
                    content ={cntt}
                    exit    ={this.dialogExit}
                    confirm ={this.handleInsAgenda}
                />
    }

    render() {
        const   {wait, doctors, doctorSel, 
                 novo, novoAviso, events, 
                 update, horarios, evntPcnt   } = this.state
        let evts    = [],
            doctor  = doctorSel.length ? doctors.filter(d => d._id===doctorSel) : [],
            tClass  = "",
            title   = doctor.length
                        ? 
                     <h4 className="titulo">{doctor[0].nome}</h4>
                        :
                     <h4 className="titulo"><FontAwesomeIcon icon={faHandPointLeft} /> Selecione uma Agenda</h4>

        events.map(e => { if(e.title.toLowerCase()!=='encaixe'){ evts.push(e) } })
        let min     =new Date("2000-01-01T07:00:00"),
            max     =new Date("2000-01-01T22:00:00"),
            step    =30,
            slots   =2;
        
        if(horarios && horarios.intervalos){
            const int = horarios.intervalos
            let mn = "22:00", mx = "07:00"
            if(int.fixo && int.fixo[0].range){
                int.fixo[0].range.map(r => {
                    if(mn>r.ini) mn=r.ini
                    if(mx<r.fim) mx=r.fim
                })
            }
            min     =new Date("2000-01-01T"+mn+":00")
            max     =new Date("2000-01-01T"+mx+":00")
            step    =horarios.tempoConsulta
            slots   =60/horarios.tempoConsulta
        }
        

        let evnt    = <></>,
            blck    = wait ? <Wait /> : <></>,
            newEvnt = <></>,
            lista   = <><p className="dark"><br/><big><strong>SELECIONE<br/>UMA AGENDA</strong></big><br/>&nbsp;</p><FontAwesomeIcon icon={faCalendarAlt} className="big dark"/></>

        
        if(novo)        evnt = this.getNewEvent()       //INSERÇÃO DE UM NOVO COMPROMISSO
        else
         if(novoAviso)  evnt = this.getNewAlert()
        else
         if(update)     evnt = this.getEventUpdate()    //EDITA COMPROMISSO SELECIONADO EM idUpd
        

        //LISTA COMPROMISSOS EM ESPERA - ENCAIXE
        /*
        if(events && events.length){
            lista = events.map(e => { 
                if(e.status.toLowerCase()==='Lista de Espera'){
                    const item = e.item
                    return <Card 
                                key         ={item._id} 
                                id          ={item._id}  
                                move        ={true}
                                className   ={"card"} 
                                type        ="event"
                                doctors     ={doctors}
                                children    ={<WaitCard event={e} funcViewClick={this.handleViewEvent}/>} 
                                funcOnDrop  ={this.handleDropOnCalendar}
                                funcGetRange={this.handleGetRange}
                            />
                }
            })
        }

        let doctorName = "Qual o Prestador?",
            doctorEspc = "Utilize o campo de seleção";
        if(doctor.length){
            tClass      = "t"+doctor[0].horarios.tempoConsulta
            newEvnt     = <button className="btNew wait" type="button" onClick={this.handleNewItem}><FontAwesomeIcon icon={faCalendarPlus} /> &nbsp;NOVO</button>
            doctorName  = doctor[0].nome
            doctorEspc  = doctor[0].especialidade
        }*/

        return (
            <>
                {blck}{evnt}
                
                <div>
                    <h2 className="title col-12" >Agenda</h2>
                        <div  className="sel-search w-100 d-none">
                            <Select
                                cashOptions
                                className           ="mySelect text-left width-50"
                                classNamePrefix     ="_"
                                placeholder         ="Encotrar paciente com marcação"
                                isSearchable        ={true}
                                isClearable         ={true}
                                onInputChange       ={this.handleEPSelIChange}
                                onChange            ={this.handleEPSelChange}
                                noOptionsMessage    ={() => "Opções não disponíveis"}
                                options             ={evntPcnt}
                            />
                        </div>
{/*>>>>>>>>>>>>>>>>>>>> CALENDÁRIO <<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
                    <JsCalendar 
                        doctor          ={doctor.length ? doctor[0] : {}}
                        doctors         ={doctors}
                        minHour         ={min}
                        maxHour         ={max}
                        stepMin         ={step}
                        moment          ={moment}
                        view            ="week" 
                        clientSearch    ={{options: evntPcnt, onChange: this.handleEPSelChange, onIChange: this.handleEPSelIChange}}
                        funcViewEvent   ={this.handleViewEvent}
                        funcUpdDoctorSel={this.updDoctorSel}
                        funcNewItem     ={this.handleNewItem}
                    /> 
{/*>>>>>>>>>>>>>>>>>>>>>>> F I M <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
                </div>
            </>
        )
    }
}

export default Agenda

/*
                    <div className="d-none filter top12 col-6 text-right">
                        <div  className="sel-search w-100">
                            <FontAwesomeIcon icon={faUsers} className="icon"/>
                            <Select
                                cashOptions
                                className           ="mySelect text-left width-50"
                                classNamePrefix     ="_"
                                placeholder         ="Encotrar paciente com marcação"
                                isSearchable        ={true}
                                isClearable         ={true}
                                onInputChange       ={this.handleEPSelIChange}
                                onChange            ={this.handleEPSelChange}
                                noOptionsMessage    ={() => "Opções não disponíveis"}
                                options             ={evntPcnt}
                            />
                        </div>
                        <button 
                            title       ="Novo Agendamento"
                            className   ="btNew"
                            onClick     ={this.handleNovoClick}
                            style       ={{display:"none"}}
                         >
                            
                            <FontAwesomeIcon icon={faCommentAlt} />
                            &nbsp; Novo Aviso
                        </button>
                    </div>
                    
                    <div className="d-none row">
                        <div className="col-sm-6">
                        <div style={{backgroundColor:"white", padding:"10px", marginBottom: "-30px"}}>
                            <Select
                                cashOptions
                                className           ="mySelect w100 text-left border-less"
                                classNamePrefix     ="_"
                                placeholder         ="SELECIONE UM PRESTADOR"
                                isSearchable        ={true}
                                isClearable         ={true}
                                onChange            ={this.handleGSelChange}
                                noOptionsMessage    ={() => "Opções não disponíveis"}
                                options             ={convertDataToSelectReact(doctors)}
                            />
                            <ImgCard 
                                img         ={<img src="./img/person.png" alt="" className="invert sem"/>}
                                info        ={<><p className="titulo">{doctorName}</p><p>{doctorEspc}</p></>}
                                doctor      ={doctor.length?doctor[0]:null}
                                funcStatus  ={this.handleStatusChange}
                            />
                        </div>
                        </div>
                    </div>



                    <div className="d-none agendaCols">
                        <div className="colDoctors">
                            <h4 style={{color:"#000"}}>PRESTADORES</h4>
                            <Select
                                cashOptions
                                className           ="mySelect col-lg-3 text-left"
                                classNamePrefix     ="_"
                                placeholder         ="SELECIONE UMA ESPECIALIDADE"
                                isSearchable        ={true}
                                isClearable         ={true}
                                onChange            ={this.handleGSelChange}
                                noOptionsMessage    ={() => "Opções não disponíveis"}
                                options             ={comboSel}
                            />
                            <div className="doctors" style={{flexDirection: "row",flexWrap: "wrap", justifyContent:"space-around", paddingBottom:"2em"}}>
                                {doctors.map(d => {
                                    if(especialidade==="" || JSON.stringify(d.especialidade).toLowerCase().indexOf(especialidade.toLowerCase())>0){
                                        return <Card 
                                                    key         ={d._id} 
                                                    id          ={d._id} 
                                                    move        ={false}
                                                    type        ="doctor"
                                                    checked     ={d._id===doctorSel}
                                                    children    ={<DoctorCard doctor={d} checked={d._id===doctorSel} funcDoctorAgenda={()=>{}} funcStatus={this.handleStatusChange}/>} 
                                                    funcOnDrop  ={this.handleDropOnCalendar}
                                                    funcGetRange={this.handleGetRange}
                                                />
                                    }
                                })} 
                            </div>
                        </div>
                    </div>
*/
import React from 'react'
import {getElementOffset} from '../functions'

import {Chip, ButtonGroup, Button, Grid, Select, MenuItem, FormControl, TextField, InputLabel, Fade} from '@material-ui/core'
import {Add, NavigateBefore, NavigateNext, KeyboardArrowDown} from '@material-ui/icons'

import Select2 from 'react-select';

export default class Tollbar extends React.Component {
    state = {
        over : <></>,
        doctor: false,
        seeAll: false
    }

    handleSeeAll = () => {
        const seeAll = !this.state.seeAll
        this.setState({seeAll})
        this.props.handleSeeAll(seeAll)
    }
    handleViewClick = e => this.props.handleViewClick(e)

    handleMoveClick = e => this.props.handleMoveClick(e)

    hideCutout = () => this.setState({over:<></>})

    handleNewEvent = () => {
        if(this.props.doctor && this.props.doctor._id){
           this.props.funcNewItem(this.props.refDay)
        }else{
            const {clientWidth, clientHeight} = document.body,
                element =document.getElementById("especSelection"),
                offsets = getElementOffset(element),
                rect = <rect fill="#000" rx="5" ry="5" x={offsets.left-20} y={offsets.top-40} width={element.clientWidth+40} height={element.clientHeight+55}></rect>; //<circle cx="50" cy="50" r="40" stroke="black" strokeWidth="3" fill="#000" />
            
            const over = (
                <Fade in={true}>
                    <div className="cutout" onClick={this.hideCutout}>
                        <svg viewBox={`0 0 ${clientWidth} ${clientHeight*1.1}`}>
                            <rect fill="#000" x="0" y="0" width="100%" height="100%" fillOpacity=".9" mask="url(#text)"/>
                            <mask id="text">
                            <rect fill="#aaa" x="0" y="0" width="100%" height="100%"></rect>
                            {rect}
                            </mask>
                        </svg>
                        <div className="cutout-alert" style={{top:`${offsets.top-30}px`,left:`${offsets.left}px`, width:`${element.clientWidth}px`}}>Selecione um Especialista</div>
                    </div>
                </Fade>
            )
            this.setState({over})
        }
        //setTimeout(this.hideCutout,3000)
    }

    updateDoctor = (e) =>  e.target && e.target.value && e.target.value._id &&  this.props.funcUpdDoctor(e.target.value._id) && this.setState({doctor:true})

    render() {
        const {view, doctors} = this.props,
            {over, seeAll} = this.state;
        return (
            <>
                {over}
                <div className="jsc-toolbar">
                    <Grid container justify="space-between" className="jsc-toolbar-head">
                        <Grid item>
                            <Chip
                                //avatar={<Avatar>+</Avatar>}
                                label={<big>NOVA CONSULTA</big>}
                                clickable
                                color="primary"
                                size="medium"
                                onDelete={()=>{}}
                                deleteIcon={<Add />}
                                id="newEventToolbar"
                                onClick={this.handleNewEvent}
                            />
                        </Grid>
                        <Grid item>
                            <ButtonGroup color="default" aria-label="outlined primary button group" className="jsc-toolbar-view">
                                <Button type="day" onClick={this.handleSeeAll} className={seeAll ? "active" : ""}><big>Grade Fixa</big></Button>
                                <Button type="day" onClick={this.handleViewClick} className={view==="day" ? "active" : ""}><big>Dia</big></Button>
                                <Button type="week" onClick={this.handleViewClick} className={view==="week" ? "active" : ""}><big>Semana</big></Button>
                                <Button type="month" onClick={this.handleViewClick} className={view==="month" ? "active" : ""}><big>Mês</big></Button>
                            </ButtonGroup>
                            <ButtonGroup color="default" aria-label="outlined primary button group" style={{marginLeft:"8px"}}>
                                <Button className="prev" onClick={this.handleMoveClick}><big><NavigateBefore/></big></Button>
                                <Button className="next" onClick={this.handleMoveClick}><big><NavigateNext /></big></Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item id="especSelection">
                            <FormControl size="small" variant="outlined" className={"classes.formControl"}>
                                <InputLabel id="ddd">Especialista</InputLabel>
                                <Select
                                    labelId="prestador"
                                    id="demo-simple-select-outlined"
                                    IconComponent={KeyboardArrowDown}
                                    //value={age}
                                    onChange={this.updateDoctor}
                                    //labelWidth={labelWidth}
                                    style={{minWidth:"220px"}}
                                >
                                    {doctors.map((d, i) => <MenuItem key={i} value={d}>{d.nome}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Select2
                                cashOptions
                                className           ="mySelect text-left width-50"
                                classNamePrefix     ="_"
                                placeholder         ="Pesquisar Paciente"
                                isSearchable        ={true}
                                isClearable         ={true}
                                onInputChange       ={this.props.clientSearch.onIChange}
                                onChange            ={this.props.clientSearch.onChange}
                                noOptionsMessage    ={() => "Opções não disponíveis"}
                                options             ={this.props.clientSearch.options}
                            />
                            <TextField
                                //label="Size"
                                id="Pesquisa"
                                placeholder="Pesquisar"
                                //defaultValue="Small"
                                variant="outlined"
                                size="small"
                                style={{minWidth:"220px"}}
                                className="box-search"
                                style={{display:"none"}}
                            />
                        </Grid>
                    </Grid>
                </div>
            </>
        )
    }
}
/*
            <div className="d-none">
                <div className="prev" onClick={this.handleMoveClick}><FontAwesomeIcon icon={faChevronLeft} /></div>
                <div className="next" onClick={this.handleMoveClick}><FontAwesomeIcon icon={faChevronRight} /></div>
                <div className="desc">{period}</div>
                <div>&nbsp;</div> 
                <div type="day" className={"options" + (view==="day"   ? " active" : "")} onClick={this.handleViewClick}><FontAwesomeIcon icon={faCalendarDay} /> DIA</div>
                <div type="week" className={"options"+ (view==="week"  ? " active" : "")} onClick={this.handleViewClick}><FontAwesomeIcon icon={faCalendarWeek}/> SEMANA</div>
                <div type="month" className={"options"+(view==="month" ? " active" : "")} onClick={this.handleViewClick}><FontAwesomeIcon icon={faCalendarAlt} /> MÊS</div>
            </div>
*/

//"homepage": "http://bomdapeste.com:8080/agenda/"  // removido do package.json

export const myServer="https://www.descontauto.com" 
//export const myServer="http://www.descontauto.com"
//export const myServer="http://192.168.0.30"
//export const myServer   = "http://192.168.0.49"

export const appVersion = "19.11.18c"

//"homepage": "http://bomdapeste.com:8080/agenda/",

export const rowHeights     = {60: 4, 45: 4, 30: 6, 20: 6, 15: 8, 10: 12, 5: 24}
export const mesesAbrev     = {0:'jan', 1:'fev',2:'mar', 3:'abr', 4:'mai', 5:'jun', 6:'jul', 7:'ago', 8:'set', 9:'out', 10:'nov', 11:'dez'}
export const mesesNomes     = {0:'janeiro', 1:'fevereiro',2:'março', 3:'abril', 4:'maio', 5:'junho', 6:'julho', 7:'agosto', 8:'setembro', 9:'outubro', 10:'novembro', 11:'dezembro'}
export const weekDay        = {0:'DOM', 1:'SEG',2:'TER', 3:'QUA', 4:'QUI', 5:'SEX', 6:'SAB'}
export const weekDayFull    = {0:'domingo', 1:'seguda',2:'terça', 3:'quarta', 4:'quinta', 5:'sexta', 6:'sábado'}
export const consultaTempos = [{value: 5, label:"05 min"},{value: 10, label:"10 min"},{value: 15, label:"15 min"},{value: 20, label:"20 min"},{value: 25, label:"25 min"},{value: 30, label:"30 min"},{value: 35, label:"35 min"},{value: 40, label:"40 min"},{value: 45, label:"45 min"},{value: 50, label:"50 min"},{value: 55, label:"55 min"},{value: 60, label:"60 min"}]
export const ufs            = [{label:"AC"},{label:"AL"},{label:"AP"},{label:"AM"},{label:"BA"},{label:"CE"},{label:"DF"},{label:"ES"},{label:"GO"},{label:"MA"},{label:"MT"},{label:"MS"},{label:"MG"},{label:"PA"},{label:"PB"},{label:"PR"},{label:"PE"},{label:"PI"},{label:"RJ"},{label:"RN"},{label:"RS"},{label:"RO"},{label:"RR"},{label:"SC"},{label:"SP"},{label:"SE"},{label:"TO"}]
export const estados        = [{"AC":"Acre"},{"AL":"Alagoas"},{"AP":"Amapá"},{"AM":"Amazonas"},{"BA":"Bahia"},{"CE":"Ceará"},{"DF":"Distrito Federal"},{"ES":"Espírito Santo"},{"GO":"Goiás"},{"MA":"Maranhão"},{"MT":"Mato Grosso"},{"MS":"Mato Grosso do Sul"},{"MG":"Minas Gerais"},{"PA":"Pará"},{"PB":"Paraíba"},{"PR":"Paraná"},{"PE":"Pernambuco"},{"PI":"Piauí"},{"RJ":"Rio de Janeiro"},{"RN":"Rio Grande do Norte"},{"RS":"Rio Grande do Sul"},{"RO":"Rondônia"},{"RR":"Roraima"},{"SC":"Santa Catarina"},{"SP":"São Paulo"},{"SE":"Sergipe"},{"TO":"Tocantins"}]
export const conselhos      = [{label:"CBOO"},{label:"COFEN"},{label:"COREN"},{label:"CRAS"},{label:"CRBM"},{label:"CREFITO"},{label:"CRF"},{label:"CRFA"},{label:"CRM"},{label:"CRN"},{label:"CRO"},{label:"CRP"},{label:"CRT"}]
export const diasHorarios   = [{value:9, label:"Todos os dias"},{value:1, label:"Segundas"},{value:2, label:"Terças"},{value:3, label:"Quartas"},{value:4, label:"Quintas"},{value:5, label:"Sextas"},{value:6, label:"Sábados"},{value:0, label:"Domingos"},{value:7, label:"De Segundas a Sexta"},{value:8, label:"Sábados e Domingos"},]
export const diasHorarios2  = [{value:1, label:"Segundas"},{value:2, label:"Terças"},{value:3, label:"Quartas"},{value:4, label:"Quintas"},{value:5, label:"Sextas"},{value:6, label:"Sábados"},{value:0, label:"Domingos"}]
export const eventStatus    = [{label:"Lista de Espera"},{label:"Encaixe"},{label:"Agendamento"},{label:"Confirmado"},{label:"Atrasado"},{label:"Recepção"},{label:"Em Atendimento"},{label:"Realizado"},{label:"Ausência"}]
export const timeSteps      = [{ value: 5, label: '5 min'},{ value: 10, label: '10 min'},{ value: 15, label: '15 min'},{ value: 20, label: '20 min'},{ value: 25, label: '25 min'},{ value: 30, label: '30 min'},{ value: 35, label: '35 min'},{ value: 40, label: '40 min'},{ value: 45, label: '45 min'},{ value: 50, label: '50 min'},{ value: 55, label: '55 min'},{ value: 60, label: '1 hora'}]

export function semAcentos(s){var sa="áàãâäéèêëíìîïóòõôöúùûüçÁÀÃÂÄÉÈÊËÍÌÎÏÓÒÕÖÔÚÙÛÜÇ",ss="aaaaaeeeeiiiiooooouuuucAAAAAEEEEIIIIOOOOOUUUUC",n="";for (var i = 0; i < s.length; i++){if(sa.indexOf(s.charAt(i))!=-1){n+=ss.substr(sa.search(s.substr(i,1)),1)}else{n+=s.substr(i, 1)}}return n}
export function acentoPorPonto(s){var sa="aeiouAEIOUáàãâäéèêëíìîïóòõôöúùûüçÁÀÃÂÄÉÈÊËÍÌÎÏÓÒÕÖÔÚÙÛÜÇ",ss=".",n="";for (var i = 0; i < s.length; i++){if(sa.indexOf(s.charAt(i))!=-1){n+=ss}else{n+=s.substr(i, 1)}}return n}

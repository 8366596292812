import React, { Component } from 'react'
import { Redirect } from "react-router-dom" 
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faAt } from '@fortawesome/free-solid-svg-icons'
import './styles.scss'
import * as Constant from '../../_constants'
import { log } from 'util'
import { isLogged } from '../functions'

Axios.defaults.withCredentials = true

export class Login extends Component {
    state = {
        mail:       "", 
        pass:       "", 
        erro:       "", 
        redirect:   "",
        wait:       false, 
        showpass:   false,
    }

    componentDidMount(){
        const bodyHeight = document.body.clientHeight,
            { y }= this.refs.falseBg.getBoundingClientRect(),
            spanCenter   = y;
            this.refs.falseBg.setAttribute("style", "background-size: auto "+bodyHeight+"px;background-position-y: -"+(spanCenter)+"px;") 
    }

    handleChange = event =>{
        const {name, value} = event.target
        this.setState({ [name]: value })
    }

    handleSubmit = event =>{
        event.preventDefault()
        const {mail,pass} = this.state
        const user= {mail, pass}
        
        let myPost = async () =>{
            this.setState({ wait: true })

            const resp = await Axios.post(`${Constant.myServer}/agenda/user/login`,{ user })
            this.setState({ wait: false })
            if(resp.data && resp.data.login==="Ok"){
                //isLogged()
                this.setState({ redirect: '/adm' });
            }else{
                this.setState({ erro: "Confira suas credenciais" })
            }
        }
        mail.trim().length && pass.length ? myPost() : this.setState({ erro: "Informe as credenciais" })
    }

    handleShowPass = () => this.setState({ showpass: !this.state.showpass })

    render() {
        const {
            mail,
            pass,
            erro,
            wait,
            showpass,
            redirect } = this.state
        let msgErro={ display: (erro.length?"block":"none") }

        if(redirect.length){
            return <Redirect to={redirect} />
        }else
            return (
                <div id="login">
                    <form ref='login' onSubmit={this.handleSubmit}>
                        <h2>
                            <img 
                                src="./img/logo-modificada.png"
                                height="80" 
                                alt="Núcleo do Ser"
                             />
                             <span className='falseBg' ref='falseBg'/>
                        </h2>
                        <div className="data">
                            <label>
                                <input
                                    type        ="text"
                                    name        ="mail"
                                    value       ={mail}
                                    onChange    ={this.handleChange}
                                    placeholder ="DIGITE SEU E-MAIL"
                                 />
                                <span className   ="showHide">
                                    { <FontAwesomeIcon icon={faAt} /> }
                                </span>
                            </label>
                            <label>
                                <input
                                    autoComplete="off"
                                    type        ={showpass?"text":"password"}
                                    name        ="pass"
                                    value       ={pass}
                                    onChange    ={this.handleChange}
                                    placeholder ="DIGITE SUA SENHA"
                                 />
                                <span
                                    className   ="showHide"
                                    onClick     ={this.handleShowPass}
                                 >
                                    {
                                        showpass
                                        ?
                                        <FontAwesomeIcon title="Ocultar" icon={faEyeSlash} />
                                        :
                                        <FontAwesomeIcon title="Exibir" icon={faEye} />
                                    }
                                </span>
                            </label>
                            <label 
                                className   ="err" 
                                style       ={msgErro}
                             >
                                {erro}
                            </label>
                            <label>
                                <button
                                    type        ="submit"
                                    disabled    ={wait}
                                 >
                                    <img 
                                        src     ="./img/loading.gif"
                                        alt     ="" 
                                        style   ={ !wait ? {display:'none'} : {}} 
                                        height  ="20"
                                     />
                                    Acessar
                                </button>
                            </label>
                        </div>
                            
                    </form>
                </div>
            )
    }
}
export default Login

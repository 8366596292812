import React, { Component } from 'react'
import Axios from 'axios'

import JscCard from './card'
import Card from '../lists/dnd/card'
import './jsc.scss'

import {formatDateDMY} from '../functions'
import {myServer, mesesNomes, rowHeights} from '../../_constants'
import Toolbar from './toolbar'
import { Grid, Typography } from '@material-ui/core'
import WaitCard from '../lists/waitCard'
import { HighlightOff, Close } from '@material-ui/icons'

const msDay = 86400000
Axios.defaults.withCredentials = true

export class Calendar extends Component {
    state = {
        view        : "",
        doctor      : {},
        events      : [],
        eventsSel   : [],
        minHour     : null,
        maxHour     : null,
        stepMin     : null,
        today       : new Date(),
        refDay      : new Date(),
        seeAll      : false
        //horaIni     : "08:00:00",
        //horaFim     : "18:00:00"
    }
    
    componentDidUpdate(prevProps){
        if ( prevProps.doctor._id !== this.props.doctor._id ) {
          this.updateEvents(this.props.doctor); //example calling redux action
        }
    }

    setSeeAll = seeAll => this.setState({seeAll})
    setSelEvent = (event) => this.setState({eventsSel:[event]})
    clearSelEvent = (event) => this.setState({eventsSel:[]})

    updateSelDoctor = id => {
        //console.log(id)
        this.props.funcUpdDoctorSel(id)}

    updateEvents = (doctor) => {
        //console.log("UPDATE", doctor)
        const getEvents = async () => {
            let events      = []
            if(doctor._id){
                const   {data}  = await Axios.post(`${myServer}/agenda/compromissos/list/${doctor._id}`,{})
                events = data.agenda
            }
            this.setState({events, eventsSel:[]})
        }
        getEvents()
    }

    static getDerivedStateFromProps(nextProps, prevState){
        const { view, doctor, doctors, minHour, maxHour, stepMin }  = nextProps
        //console.log("doctor", doctor)
        
        return (
            prevState && prevState.view==="" && view!=="" 
                ?
            {view, doctor, doctors, minHour, maxHour, stepMin}
                :
            (
                doctor && prevState.doctor && prevState.doctor.nome!==doctor.nome
                    ?
                {doctor, doctors, minHour, maxHour, stepMin}
                    :
                {doctors, minHour, maxHour, stepMin}
            )
        )
    }

    isToday = date => {
        const dt = new Date(date),
              hj = this.state.today;
              
        return dt.getFullYear()===hj.getFullYear() && dt.getMonth()===hj.getMonth() && dt.getDate()===hj.getDate()
    }

    dayClick = event => {
        const time = event.currentTarget.getAttribute("time")
        this.setState({refDay:new Date(time*1),view:"week"})
    }

    changeRefDay = refDay => this.setState({refDay})

    getWaitEvent = () => {
        const {events, doctors} = this.state
        let lista = []
        
        if(events && events.length){
            lista = events.filter(e => e.status.toLowerCase()==='lista de espera' )
        }
        
        return lista.map(e => (<WaitCard key={e._id} event={e} funcViewClick={this.props.funcViewEvent} />))
    }

    handleMoveClick = event => {
        const app       = this,
            stt         = app.state,
            {view,
             refDay }   = stt,
            {className} = event.currentTarget;
        let day         = refDay.getDate(),
            signal      = className.indexOf("prev")>-1 ? -1 : 1,
            days        = 1;
        
        //console.log("className", className)
        switch(view){
            case "month":
                days = (signal < 0 ? day : 31-day) + 2
                this.changeRefDay(new Date(refDay.getTime()+signal*days*msDay))
                break
            case "week":
                this.changeRefDay(new Date(refDay.getTime()+signal*msDay*7))
                break
            case "day":
            default:
                const dayWeek = refDay.getDay()
                days = signal>0 && dayWeek===6 || signal<0 && dayWeek===1 ? 2 : 1
                this.changeRefDay(new Date(refDay.getTime()+signal*msDay*days))
                break
        }
    }

    handleViewClick = event =>{
        const type = event.currentTarget.getAttribute("type"),
            {view} = this.state;
        if(view!==type) this.setState({view:type})
    }

    getColCotent_OLD = day => {
        const app       = this,
            sDay        = day.toString(),
            { doctor,
             view,
             events,
             maxHour,
             minHour,
             stepMin }  = app.state,
            agora       = new Date;

        let horaIni     = minHour.toString().substr(16,8),
            horaFim     = maxHour.toString().substr(16,8),
            dIni        = new Date(sDay.substr(0,16) + horaIni + sDay.substr(24,9)),
            dFim        = new Date(sDay.substr(0,16) + horaFim + sDay.substr(24,9)),
            tIni        = (new Date(sDay.substr(0,16) + "00:00:00" + sDay.substr(24,9))).getTime(),
            tFim        = (new Date(sDay.substr(0,16) + "23:59:59" + sDay.substr(24,9))).getTime(),
            rows        = [],
            dayEvents   = [],
            delta       = stepMin*60000;

        
        events.map(e => {
            const ini = (new Date(e.dtIni)).getTime()
            if(ini>tIni && ini<tFim)
                dayEvents.push(e)
        })
        //console.log("step",dayEvents)
        if(dayEvents.length){
            dayEvents.sort((a,b) => {
                const aIni = (new Date(a.dtIni)).getTime(),
                      bIni = (new Date(b.dtIni)).getTime();
                if(aIni<bIni)       return -1
                else if(aIni>bIni)  return  1
                else                return  0
            })
        }
        while(dIni.getTime()<dFim.getTime()){
            let type = "free",
                fixo = doctor.horarios && doctor.horarios.intervalos && doctor.horarios.intervalos.fixo ? doctor.horarios.intervalos.fixo[0].range : [],
                temp = doctor.horarios && doctor.horarios.intervalos && doctor.horarios.intervalos.temp ? doctor.horarios.intervalos.temp : [];

            if(dIni.getDay()===0)                               type = "off"
            else if(dIni.getDay()===6 && dIni.getHours()>13)    type = "off"
            else if(agora.getTime()>dIni.getTime())             type = "over"
            if(doctor && !doctor.nome)                          type = "bloq"
            if(type=="free"){
                let busy = true,
                    hDay = fixo.filter(r => dIni.getDay()===r.day)
                //console.log(fixo, dIni.getDay())
                //>> VERIFICA SE O HORÁRIO ESTÁ DENTRO DOS HORÁRIOS FIXOS
                hDay.every(r => {
                    const di = dIni.toString(),
                        ini = new Date(di.substr(0,16) + r.ini + ":00" + di.substr(24,9)),
                        fim = new Date(di.substr(0,16) + r.fim + ":00" + di.substr(24,9))
                    //console.log(ini, dIni, fim)
                    if(ini.getTime()<=dIni.getTime() && fim.getTime()>dIni.getTime())
                        busy=false
                        
                    return busy
                })

                temp.every(r => { 
                    let ini = (new Date(r.ini)).toString(),
                        fim = (new Date(r.fim)).toString();
                        ini = new Date(ini.substr(0,16) + "00:00:00" + ini.substr(24,9))
                        fim = new Date(fim.substr(0,16) + "23:59:59" + fim.substr(24,9))
                    
                    if(ini.getTime()<=dIni.getTime() && fim.getTime()>dIni.getTime()){
                        const value = r.tipo.value===0 ? true : false
                        if(!r.range) busy = value
                        else if(r.range.length===0) busy = value
                        else{
                            hDay = r.range.filter(r => dIni.getDay()===r.day)
                            hDay.every(r => {
                                const di = dIni.toString(),
                                    ini  = new Date(di.substr(0,16) + r.ini + ":00" + di.substr(24,9)),
                                    fim  = new Date(di.substr(0,16) + r.fim + ":00" + di.substr(24,9));

                                if(ini.getTime()<=dIni.getTime() && fim.getTime()>dIni.getTime())
                                    busy=value
                            })
                        }
                   }
                   return busy
                })
                if(busy) type ="off"
            }

            if(dayEvents.length){
                let dE      = [],
                    tIni    = dIni.getTime(),
                    dE_fim  = tIni;

                dayEvents.map(e => dE.push(e))
                dE.map(e => {
                    const   eIniD = (new Date(e.dtIni)),
                            eIni  = eIniD.getTime()
                    //console.log((new Date(e.dtIni)), dIni, tIni===eIni, eIni<tIni )
                    if(tIni===eIni){
                        rows.push({date:tIni, time:("0"+dIni.getHours()).substr(-2)+":"+("0"+dIni.getMinutes()).substr(-2), type:"busy", data: e})
                        dayEvents.map( (d,i) => {
                            if(d._id===e._id)
                                dayEvents.splice(i, 1)
                        })
                        dE_fim = (new Date(e.dtFim)).getTime()
                    }else if(eIni<tIni){
                        rows.push({date:eIni, time:("0"+eIniD.getHours()).substr(-2)+":"+("0"+eIniD.getMinutes()).substr(-2), type:"busy", data: e})
                        dayEvents.map( (d,i) => {
                            if(d._id===e._id)
                                dayEvents.splice(i, 1)
                        })
                        dE_fim = (new Date(e.dtFim)).getTime()
                    }
                })
                if(dE_fim<=tIni)
                    rows.push({date:tIni, time:("0"+dIni.getHours()).substr(-2)+":"+("0"+dIni.getMinutes()).substr(-2), type, data:{}})
            }else   rows.push({date:dIni.getTime(), time:("0"+dIni.getHours()).substr(-2)+":"+("0"+dIni.getMinutes()).substr(-2), type, data:{}})
            
            dIni = new Date(dIni.getTime() + delta)
        }

        // insere registro além do horário de término do atendimento
        dayEvents.map(e => {
            const   eIniD = (new Date(e.dtIni)),
                    eIni  = eIniD.getTime()

            rows.push({date:eIni, time:("0"+eIniD.getHours()).substr(-2)+":"+("0"+eIniD.getMinutes()).substr(-2), type:"busy", data: e})
        })
        //console.log("dataINI", dIni, dFim, rows)
        return <>{ rows.map((r,i)=> <JscCard id={r.date} key={i} dados={{...r, doctor:app.props.doctor}} view={view} funcEventClick={this.props.funcEventClick} funcEventsReload={this.updateEvents}/>) } </> 
    }

    getColTime = () => {
        const app       = this,
            agora       = new Date,
            sDay        = agora.toString(),
            { 
             view, doctor,
             maxHour, seeAll,
             minHour }  = app.state;

        let horaIni     = minHour.toString().substr(16,8),
            horaFim     = maxHour.toString().substr(16,8),
            dIni        = new Date(sDay.substr(0,16) + horaIni + sDay.substr(24,9)),
            dFim        = new Date(sDay.substr(0,16) + horaFim + sDay.substr(24,9)),
            delta       = 60*60000,
            height      = seeAll && doctor.horarios && rowHeights[doctor.horarios.tempoConsulta] ? rowHeights[doctor.horarios.tempoConsulta]  : 4,
            rows        = [];
        //if(doctor.horarios)
        //    console.log(doctor.horarios, height, rowHeights[doctor.horarios.tempoConsulta])
        while(dIni.getTime()<dFim.getTime()){
            rows.push({height, date:agora.getTime(), time:("0"+dIni.getHours()).substr(-2)+":"+("0"+dIni.getMinutes()).substr(-2), type:"time", data: null})
            dIni = new Date(dIni.getTime() + delta)
        }

        return <>{ rows.map((r,i)=> <JscCard id={r.date} key={i} dados={{...r}} view={view} />) } </> 
    }

    getTotalEventsDay = day => {
        const app       = this,
            sDay        = day.toString(),
            { events }  = app.state;

        if(sDay.toString()==="Invalid Date")
            return ""
        
        let tIni        = (new Date(sDay.substr(0,16) + "00:00:00" + sDay.substr(24,9))).getTime(),
            tFim        = (new Date(sDay.substr(0,16) + "23:59:59" + sDay.substr(24,9))).getTime(),
            dayEvents   = [],
            result      = "";

        
        events.map(e => {
            const ini = (new Date(e.dtIni)).getTime()
            if(ini>tIni && ini<tFim && e.status !== "Lista de Espera")
                dayEvents.push(e)
        })
        switch(dayEvents.length){
            case 0: result  = ""; break;
            case 1: result  = "1 Marcação"; break;
            default: result = dayEvents.length + " Marcações"
        }

        return result
    }

    getColCotentDay = day => {
        const app       = this,
            sDay        = day.toString(),
            {
             view,
             events }  = app.state;

        let tIni        = (new Date(sDay.substr(0,16) + "00:00:00" + sDay.substr(24,9))).getTime(),
            tFim        = (new Date(sDay.substr(0,16) + "23:59:59" + sDay.substr(24,9))).getTime(),
            rows        = [],
            dayEvents   = [];

        
        events.map(e => {
            const ini = (new Date(e.dtIni)).getTime()
            if(ini>tIni && ini<tFim)
                dayEvents.push(e)
        })
        //console.log("step",dayEvents)

        if(dayEvents.length){
            dayEvents.sort((a,b) => {
                const aIni = (new Date(a.dtIni)).getTime(),
                      bIni = (new Date(b.dtIni)).getTime();
                if(aIni<bIni)       return -1
                else if(aIni>bIni)  return  1
                else                return  0
            })
        }

        // insere registro além do horário de término do atendimento
        dayEvents
         .filter(e => e.status !== "Lista de Espera")
         .map(e => {
            const   eIniD = (new Date(e.dtIni)),
                    eIni  = eIniD.getTime()

            rows.push({date:eIni, time:("0"+eIniD.getHours()).substr(-2)+":"+("0"+eIniD.getMinutes()).substr(-2), type:"busy", data: e})
        })
        
        return <>{ 
            rows.map((r,i)=> (
                <JscCard 
                    key={i} 
                    view={view} 
                    id={r.date} 
                    dados={{...r, doctor:app.props.doctor}}
                    funcSelEvent={this.setSelEvent}
                    funcEventClick={this.props.funcEventClick} 
                    funcEventsReload={this.updateEvents}
                />
            )) 
        } </> 
    }

    getColCotent = day => {
        const app       = this,
            sDay        = day.toString(),
            { doctor,
             view,
             events,
             seeAll,
             maxHour,
             eventsSel,
             minHour }  = app.state,
            agora       = new Date;

        let horaIni     = minHour.toString().substr(16,8),
            horaFim     = maxHour.toString().substr(16,8),
            dIni        = new Date(sDay.substr(0,16) + horaIni + sDay.substr(24,9)),
            dFim        = new Date(sDay.substr(0,16) + horaFim + sDay.substr(24,9)),
            tIni        = (new Date(sDay.substr(0,16) + "00:00:00" + sDay.substr(24,9))).getTime(),
            tFim        = (new Date(sDay.substr(0,16) + "23:59:59" + sDay.substr(24,9))).getTime(),
            rows        = [],
            dayEvents   = [],
            sessionTime = seeAll && doctor.horarios ? doctor.horarios.tempoConsulta : 60,
            height      = seeAll && doctor.horarios && rowHeights[doctor.horarios.tempoConsulta] ? rowHeights[doctor.horarios.tempoConsulta]  : 4,
            deltaIni    = sessionTime * 60000,
            cardHeight  = sessionTime / 60 * height;
        
        events.map(e => {
            const ini = (new Date(e.dtIni)).getTime()
            if(ini>tIni && ini<tFim)
                dayEvents.push(e)
        })
        //console.log("sDay",doctor)

        if(dayEvents.length){
            dayEvents.sort((a,b) => {
                const aIni = (new Date(a.dtIni)).getTime(),
                      bIni = (new Date(b.dtIni)).getTime();
                if(aIni<bIni)       return -1
                else if(aIni>bIni)  return  1
                else                return  0
            })
        }
        while(dIni.getTime()<dFim.getTime()){
            let delta = deltaIni, cHeight = cardHeight;
            try{
                let type = "free", 
                    fixo = doctor.horarios && doctor.horarios.intervalos && doctor.horarios.intervalos.fixo ? doctor.horarios.intervalos.fixo[0].range : [],
                    temp = doctor.horarios && doctor.horarios.intervalos && doctor.horarios.intervalos.temp ? doctor.horarios.intervalos.temp : [];

                if(dIni.getDay()===0)                               type = "off"
                else if(dIni.getDay()===6 && dIni.getHours()>13)    type = "off"
                else if(agora.getTime()>dIni.getTime())             type = "over"
                if(doctor && !doctor.nome)                          type = "bloq"
                if(type==="free" || type==="over"){
                    let busy = true,
                        hDay = fixo.filter(r => dIni.getDay()===r.day).sort((a,b) => {if(a.ini<b.ini){return -1}else if(a.ini>b.ini){return 1}else{return 0}})
                    //console.log(fixo, dIni.getDay())
                    //>> VERIFICA SE O HORÁRIO ESTÁ DENTRO DOS HORÁRIOS FIXOS
                    hDay.every(r => {
                        const di = dIni.toString(),
                            ini = new Date(di.substr(0,16) + r.ini + ":00" + di.substr(24,9)),
                            fim = new Date(di.substr(0,16) + r.fim + ":00" + di.substr(24,9))
                        //console.log(ini, dIni, fim)
                        if(ini.getTime()<=dIni.getTime() && fim.getTime()>dIni.getTime())
                            busy=false
                            
                        return busy
                    })

                    //>> VERIFICA SE O HORÁRIO ESTÁ DENTRO DOS HORÁRIOS TEMPORÁRIOS
                    temp.every(r => { 
                        let ini = (new Date(r.ini)).toString(),
                            fim = (new Date(r.fim)).toString();
                            ini = new Date(ini.substr(0,16) + "00:00:00" + ini.substr(24,9))
                            fim = new Date(fim.substr(0,16) + "23:59:59" + fim.substr(24,9))
                        
                        if(ini.getTime()<=dIni.getTime() && fim.getTime()>dIni.getTime()){
                            const value = r.tipo.value===0 ? true : false
                            if(!r.range) busy = value
                            else if(r.range.length===0) busy = value
                            else{
                                hDay = r.range.filter(r => dIni.getDay()===r.day)
                                hDay.every(r => {
                                    const di = dIni.toString(),
                                        ini  = new Date(di.substr(0,16) + r.ini + ":00" + di.substr(24,9)),
                                        fim  = new Date(di.substr(0,16) + r.fim + ":00" + di.substr(24,9));

                                    if(ini.getTime()<=dIni.getTime() && fim.getTime()>dIni.getTime())
                                        busy=value
                                })
                            }
                        }
                        return busy
                    })
                    if(busy){
                        if(hDay.length){
                            hDay.every((r,i) => {
                                const di = dIni.toString(),
                                    ini = new Date(di.substr(0,16) + r.ini + ":00" + di.substr(24,9)),
                                    fim = new Date(di.substr(0,16) + r.fim + ":00" + di.substr(24,9))
                                //console.log(ini, dIni, fim)
                                if(ini.getTime()>dIni.getTime()){
                                    delta = ini.getTime()-dIni.getTime()
                                    cHeight = delta*height/3600000
                                    //console.log(delta/60000, cHeight)
                                    return false
                                }else if((i+1)===hDay.length && fim.getTime()<=dIni.getTime()){
                                    delta = dFim.getTime()-dIni.getTime()
                                    cHeight = delta*height/3600000
                                    //console.log(delta/60000, cHeight)
                                    return false
                                }else return true
                                
                            })
                        }else{
                            delta = dFim.getTime()-dIni.getTime()
                            cHeight = delta*height/3600000
                        }
                        type ="off"
                    }
                }

                if(dayEvents.length){
                    let dE      = [],
                        tIni    = dIni.getTime(),
                        dE_fim  = tIni +delta-1,
                        dEvents = [];

                    dayEvents.map(e => dE.push(e))
                    dE.map(e => {
                        const   eIniD = (new Date(e.dtIni)),
                                eIni  = eIniD.getTime()

                        //console.log(e, dIni.getHours(), (new Date(dE_fim)).getHours(), (new Date(eIni)).getHours(), tIni<=eIni, dE_fim<eIni)

                        if(tIni<=eIni && dE_fim>eIni && e.status!=="Lista de Espera")
                            dEvents.push(e)
                    })
                    if(dEvents.length){
                        rows.push({height:cHeight, date:tIni, time:("0"+dIni.getHours()).substr(-2)+":"+("0"+dIni.getMinutes()).substr(-2), type, data:{events:dEvents}})
                    }else{
                        rows.push({height:cHeight, date:tIni, time:("0"+dIni.getHours()).substr(-2)+":"+("0"+dIni.getMinutes()).substr(-2), type, data:{}})
                    }
                }else   rows.push({height:cHeight, date:dIni.getTime(), time:("0"+dIni.getHours()).substr(-2)+":"+("0"+dIni.getMinutes()).substr(-2), type, data:{}})
            }catch(e){}
            dIni = new Date(dIni.getTime() + delta)
        }
        
        return  <>{ rows.map((r,i) => (
                    <JscCard 
                        key={i} 
                        view={view} 
                        id={r.date}
                        eventSel={eventsSel}
                        funcEventsReload={this.updateEvents}
                        funcSelDay={this.handleCardWeekClick}
                        dados={{...r, doctor:app.props.doctor}} 
                        funcEventClick={this.props.funcEventClick} 
                    />
                    )
                 )}
                </> 
    }
    
    handleHeaderWeekClick = event => {
        const refDay = new Date(event.currentTarget.getAttribute("time")*1)
        //console.log(time)
        this.setState({refDay, view:"day"})
    }

    handleCardWeekClick = day => {
        const refDay = new Date(day)
        this.setState({refDay, view:"day"})
    }

    buildWeek_OLD = moment => {
        const app           = this,
        {   refDay,
            today,
            doctors,
            view    }       = app.state;
       const {_locale }     = moment(),
           {_months,
            _monthsShort}   = _locale;

       //console.log("events",app.state.events)

       let firstWeekDay = refDay, //new Date(refDay.getTime() - (refDay.getDay())*msDay),
           lastWeekDay  = new Date(firstWeekDay.getTime() + 6*msDay),
           period       = _monthsShort[lastWeekDay.getMonth()] + "/"+lastWeekDay.getFullYear(),
           //slot         = 2,
           days         = [],
           cols         = [];
        
        _locale._weekdaysShort.map((d,i) => {
            const day = new Date(firstWeekDay.getTime() + i*msDay)
            days.push(day) 
            cols.push(this.getColCotent_OLD(day))
        })
        //console.log(days,cols)
        if(lastWeekDay.getMonth()!==firstWeekDay.getMonth())
            period = _monthsShort[firstWeekDay.getMonth()] + (lastWeekDay.getFullYear()!==firstWeekDay.getFullYear() ? "/"+firstWeekDay.getFullYear() : "") +" - " +period
        
            return (
            <div className="jsc-week-view">
                <Toolbar
                    handleSeeAll={this.setSeeAll}
                    handleViewClick={this.handleViewClick} 
                    handleMoveClick={this.handleMoveClick} 
                    funcUpdDoctor={this.updateSelDoctor}
                    view={view} doctors={doctors} period={period} />
                <div className="jsc-body">
                    <div className="jsc-header">
                        { days.map((day,i) => (
                            <div
                             key        ={i}
                             time       ={day.getTime()}
                             onClick    ={this.handleHeaderWeekClick}
                             className  ={"jsc-header-cell"+(day.toString().substr(4,12)===today.toString().substr(4,12)?" active":"")}
                            >
                                <small>
                                    {_locale._weekdaysShort[day.getDay()].toUpperCase()} - {day.getDate()+"/"+_monthsShort[day.getMonth()]}
                                </small>
                            </div>
                        )
                        ) }
                    </div>
                    <div className="jsc-week-content">
                        { cols.map((d,i)=>{
                            return(<div className="jsc-week-col" alt={refDay} key={i}>{d}</div>  )
                        }) }
                    </div>
                </div>
            </div>
        )
    }

    showSelectedEvents = () => {
        const {eventsSel} = this.state
        let result = <></>
        
        if(eventsSel.length){
            result = <div className="jsc-events-sel">{eventsSel.map(e => <WaitCard key={e._id} event={e} funcViewClick={this.props.funcViewEvent} />)}<span onClick={this.clearSelEvent} className="jsc-events-close" title="Fechar"><Close /></span></div>
        }

        return result
    }

    buildWeek_OLD1 = moment => {
        const app           = this,
        {   refDay,
            today,
            doctors,
            view    }       = app.state;
       const {_locale }     = moment(),
           {_months,
            _monthsShort}   = _locale;

       //console.log("events",app.state.events)

       let firstWeekDay = new Date(refDay.getTime() - (refDay.getDay())*msDay), //refDay, 
           lastWeekDay  = new Date(firstWeekDay.getTime() + 6*msDay),
           period       = _monthsShort[lastWeekDay.getMonth()] + "/"+lastWeekDay.getFullYear(),
           //slot         = 2,
           days         = [],
           cols         = [],
           refWeek      = mesesNomes[firstWeekDay.getMonth()] + '-' + ( firstWeekDay.getFullYear()!== lastWeekDay.getFullYear() ? firstWeekDay.getFullYear() + ' ': '') + (firstWeekDay.getMonth()!==lastWeekDay.getMonth() ? mesesNomes[lastWeekDay.getMonth()] + '-' : "" ) + lastWeekDay.getFullYear();
        
           
        cols.push(this.getColTime())
        _locale._weekdaysShort.map((d,i) => {
            const day = new Date(firstWeekDay.getTime() + i*msDay)
            i && days.push(day) && cols.push(this.getColCotent(day))
        })
        //console.log(days,cols)
        if(lastWeekDay.getMonth()!==firstWeekDay.getMonth())
            period = _monthsShort[firstWeekDay.getMonth()] + (lastWeekDay.getFullYear()!==firstWeekDay.getFullYear() ? "/"+firstWeekDay.getFullYear() : "") +" - " +period
        
            return (
            <div className="jsc-week-view">
                <Toolbar
                    view={view} 
                    refDay={refDay}  
                    period={period} 
                    doctors={doctors} 
                    doctor={this.state.doctor} 
                    handleSeeAll={this.setSeeAll}
                    funcNewItem={this.props.funcNewItem} 
                    funcUpdDoctor={this.updateSelDoctor}
                    handleViewClick={this.handleViewClick} 
                    handleMoveClick={this.handleMoveClick} 
                />
                <div className="jsc-body">
                    <div style={{width:"75%"}}>
                        <div>{this.showSelectedEvents()}</div>
                        <div className="jsc-month-ref">{refWeek}</div>
                        <div className="jsc-header">
                            <div className="jsc-header-cell time"><small>Hora</small></div>
                            { days.map((day,i) => (
                                <div
                                key        ={i}
                                time       ={day.getTime()}
                                onClick    ={this.handleHeaderWeekClick}
                                className  ={"jsc-header-cell"+(day.toString().substr(4,12)===today.toString().substr(4,12)?" active":"")}>
                                    <small>
                                        {_locale._weekdaysShort[day.getDay()].toUpperCase()} - {('0'+day.getDate()).substr(-2)}
                                    </small>
                                </div>
                            )
                            ) }
                        </div>
                        <div className="jsc-week-content">
                            { cols.map((d,i)=>{
                                return(<div className={"jsc-week-col"+(i ? "" : " time")} alt={refDay} key={i}>{d}</div>  )
                            }) }
                        </div>
                    </div>
                    <div style={{width:"25%"}}>
                        <Typography variant="h6" className="jsc-col-title">LISTA DE ESPERA</Typography>
                        {this.getWaitEvent()}
                    </div>
                </div>
            </div>
        )
    }

    buildWeek = moment => {
        const app           = this,
        {   refDay,
            today,
            doctors,
            view    }       = app.state;
       const {_locale }     = moment(),
           {_months,
            _monthsShort}   = _locale;

       //console.log("events",app.state.events)

       let firstWeekDay = new Date(refDay.getTime() - (refDay.getDay())*msDay), //refDay, 
           lastWeekDay  = new Date(firstWeekDay.getTime() + 6*msDay),
           period       = _monthsShort[lastWeekDay.getMonth()] + "/"+lastWeekDay.getFullYear(),
           //slot         = 2,
           days         = [],
           cols         = [],
           refWeek      = mesesNomes[firstWeekDay.getMonth()] + '-' + ( firstWeekDay.getFullYear()!== lastWeekDay.getFullYear() ? firstWeekDay.getFullYear() + ' ': '') + (firstWeekDay.getMonth()!==lastWeekDay.getMonth() ? mesesNomes[lastWeekDay.getMonth()] + '-' : "" ) + lastWeekDay.getFullYear();
        
           
        cols.push(this.getColTime())
        _locale._weekdaysShort.map((d,i) => {
            const day = new Date(firstWeekDay.getTime() + i*msDay)
            i && days.push(day) && cols.push(this.getColCotent(day))
        })
        //console.log(days,cols)
        if(lastWeekDay.getMonth()!==firstWeekDay.getMonth())
            period = _monthsShort[firstWeekDay.getMonth()] + (lastWeekDay.getFullYear()!==firstWeekDay.getFullYear() ? "/"+firstWeekDay.getFullYear() : "") +" - " +period
        
            return (
            <div className="jsc-week-view">
                <Toolbar
                    view={view} 
                    refDay={refDay}  
                    period={period} 
                    doctors={doctors} 
                    doctor={this.state.doctor} 
                    handleSeeAll={this.setSeeAll}
                    funcNewItem={this.props.funcNewItem} 
                    funcUpdDoctor={this.updateSelDoctor}
                    handleViewClick={this.handleViewClick} 
                    handleMoveClick={this.handleMoveClick} 
                    clientSearch={this.props.clientSearch}
                />
                <div className="jsc-body">
                    <div style={{width:"75%"}}>
                        <div>{this.showSelectedEvents()}</div>
                        <div className="jsc-month-ref">{refWeek}</div>
                        <div className="jsc-header">
                            <div className="jsc-header-cell time"><small>Hora</small></div>
                            { days.map((day,i) => (
                                <div
                                key        ={i}
                                time       ={day.getTime()}
                                onClick    ={this.handleHeaderWeekClick}
                                className  ={"jsc-header-cell"+(day.toString().substr(4,12)===today.toString().substr(4,12)?" active":"")}>
                                    <small>
                                        {_locale._weekdaysShort[day.getDay()].toUpperCase()} - {('0'+day.getDate()).substr(-2)}
                                    </small>
                                </div>
                            )
                            ) }
                        </div>
                        <div className="jsc-week-content">
                            { cols.map((d,i)=>{
                                return(<div className={"jsc-week-col"+(i ? "" : " time")} alt={refDay} key={i}>{d}</div>  )
                            }) }
                        </div>
                    </div>
                    <div style={{width:"25%"}}>
                        <Typography variant="h6" className="jsc-col-title">LISTA DE ESPERA</Typography>
                        {this.getWaitEvent()}
                    </div>
                </div>
            </div>
        )
    }

    buildDay = moment => {
        const app           = this,
        {   refDay,
            today,
            doctors,
            view    }       = app.state;
       const {_locale }     = moment(),
           {_months,
            _monthsShort}   = _locale;

       //console.log(moment.year())

       let firstWeekDay = refDay, //new Date(refDay.getTime() - (refDay.getDay())*msDay),
           lastWeekDay  = new Date(firstWeekDay.getTime() + 6*msDay),
           period       = ("0"+refDay.getDate()).substr(-2)+"/"+_monthsShort[lastWeekDay.getMonth()] + "/"+firstWeekDay.getFullYear(),
           slot         = 2,
           days         = [],
           cols         = [];
        
        days.push(firstWeekDay) 
        cols.push(this.getColCotentDay(firstWeekDay))
        //console.log(cols)
        
        return (
            <div className="jsc-day-view">
                <Toolbar
                    view={view}
                    refDay={refDay}
                    period={period} 
                    doctors={doctors} 
                    doctor={this.state.doctor}
                    handleSeeAll={this.setSeeAll}
                    handleViewClick={this.handleViewClick} 
                    handleMoveClick={this.handleMoveClick} 
                    funcNewItem={this.props.funcNewItem} 
                    funcUpdDoctor={this.updateSelDoctor}
                    clientSearch={this.props.clientSearch}
                />
                <div className="jsc-body">
                    <div style={{width:"75%"}}>
                        <div>{this.showSelectedEvents()}</div>
                        <div className="jsc-header">
                            { days.map((day,i) => <div className={"jsc-header-cell size7"+(day.toString().substr(4,12)===today.toString().substr(4,12)?" active":"")} key={i}><small>{_locale._weekdays[day.getDay()].toUpperCase()} <b>{formatDateDMY(day)}</b></small></div>) }
                            <div className="jsc-header-cell d-none">ESPERA</div>
                        </div>
                        <div className="jsc-day-content">
                            { cols.map((d,i)=>{
                                return(<div className="jsc-day-col size7" alt={refDay} key={i}>{d}</div>  )
                            }) }
                        </div>
                    </div>
                    
                    <div style={{width:"25%"}}>
                        <Typography variant="h6" className="jsc-col-title">LISTA DE ESPERA</Typography>
                        {this.getWaitEvent()}
                    </div>
                </div>
            </div>
        )
    }

    buildMonth = moment => {
        const app           = this,
         {refDay,
          doctors,
          view }   = app.state;
        const {_locale }   = moment(),
            {_months,
             _monthsShort}  = _locale;

        //console.log(this.props)

        let firstMonthDay   = new Date(refDay.getTime() - (refDay.getDate()-1)*msDay),
            dayOfWeek       = firstMonthDay.getDay(),
            month           = firstMonthDay.getMonth(),
            period          = _monthsShort[firstMonthDay.getMonth()] + "/"+firstMonthDay.getFullYear(),
            actualDay       = firstMonthDay,
            days            = [];

        for(let i=1; i<dayOfWeek; i++) days.push({day:"", date:""})
        while(month===actualDay.getMonth()){
            days.push({day:actualDay.getDate(), date:actualDay.getTime()})
            actualDay = new Date(actualDay.getTime()+msDay)
        }
        //console.log(actualDay.getDay())
        if(actualDay.getDay()!==0)
            for(let i=actualDay.getDay(); i<7; i++) days.push({day:"", date:""})
        return (
            <div className="jsc-month-view">
                <Toolbar
                    view={view} 
                    refDay={refDay}
                    period={period} 
                    doctors={doctors} 
                    doctor={this.state.doctor} 
                    handleSeeAll={this.setSeeAll}
                    handleViewClick={this.handleViewClick} 
                    handleMoveClick={this.handleMoveClick} 
                    funcNewItem={this.props.funcNewItem} 
                    funcUpdDoctor={this.updateSelDoctor}
                    clientSearch={this.props.clientSearch}
                />
                <Grid container className="jsc-body">
                    <Grid item className="jsc-body-main">
                        <div>{this.showSelectedEvents()}</div>
                        <div className="jsc-month-ref">{mesesNomes[firstMonthDay.getMonth()]} {firstMonthDay.getFullYear()}</div>
                        <div className="jsc-header">
                            {_locale._weekdaysShort.map((d,i)=>{
                                const day = d.toUpperCase()
                                if(day==="DOM")
                                    return <></>

                                return(<div className="jsc-header-cell" key={i}>{day}</div>) })
                            }
                        </div>
                        <div className="jsc-month-body">
                            { days.map((d,i)=>{
                                if((new Date(d.date)).getDay()===0)
                                    return <></>

                                return(
                                    <div key        ={i} 
                                        alt         ={d.day}
                                        time        ={d.date}
                                        onClick     ={this.dayClick}
                                        className   ={"jsc-month-body-cell"+(d.day!=="" ? " sel" : "")+(this.isToday(d.date) ? " active" : "")}>
                                            <span className="jsc-month-events">{this.getTotalEventsDay(new Date(d.date))}</span></div>
                                ) 
                            }) 
                            }
                        </div>
                    </Grid>
                    <Grid item className="jsc-body-panel">
                        <Typography variant="h6" className="jsc-col-title">LISTA DE ESPERA</Typography>
                        {this.getWaitEvent()}
                    </Grid>
                </Grid>
            </div>
        ) 
    }

    render() {
        const app       = this,
            stt         = app.state,
            pps         = app.props;
        let {moment}    = pps,
            {view,
             seeAll}    = stt,
            theView     = <></>;

            
        switch(view){
            case "month":
                theView = this.buildMonth(moment)
                break
            case "week":
                theView = this.buildWeek(moment)
                break
            case "day":
                theView = this.buildDay(moment)
                break
            default: 
        }
        //console.log(this.state,moment())
        return (
            <>
            <div className="jsc-block">
                {theView}
            <div>
                çsalfkasdkl 
            </div>
            </div>
            </>
        )
    }
}

export default Calendar

import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { isNull } from 'util';
import {
    DateRangeOutlined,
    ClassOutlined,
    PersonOutlineOutlined,
    PermDataSettingOutlined, 
    ExitToAppOutlined} from '@material-ui/icons';
import { Avatar, Button } from '@material-ui/core';
import { getInicials } from './functions';

export class Slidebar extends Component {
    state = {
        wait: false,
        menu: 2,
    }
    
    static getDerivedStateFromProps(nextProps, prevState){
        const { select } = nextProps.obj.data;
        return select===prevState.menu ? {wait: false} : {menu: select}
    }
    getMenuItem = (event, attr) => {
        let el = event.target,
            op = el.getAttribute(attr);

        while( isNull(op) ){
            el = el.parentElement 
            op = el.getAttribute(attr)
        }
        return op*1
    }
    handleMenuClick = event => {
        const { obj } = this.props, stt =this.state,
            { menuClick } = obj.funcs,
            op=this.getMenuItem(event,'alt');
        op !== stt.menu && !stt.wait && this.setState({menu: op , wait: true})
        menuClick(op)
    }
    render() {
        //const classes = useStyles();
        const menuOptions = [
            //{id:1, text:"Painel Inicial",   icon: faTachometerAlt,  link: "/adm/inicial",       selected:true},
            {id:2, text:"Agendamentos",     icon: <DateRangeOutlined />,      link: "/adm/agendamentos", }, //faCalendarCheck
            {id:3, text:"Especialistas",      icon: <ClassOutlined />,          link: "/adm/prestadores", }, //faUserMd
            {id:4, text:"Pacientes",        icon: <PersonOutlineOutlined />,  link: "/adm/pacientes", }, //faUsers
            {id:5, text:"Preferências",     icon: <PermDataSettingOutlined />,link: "/adm/preferencias", }, //faCogs
         ],
         { menu, wait } = this.state,
         { user } = this.props.obj.data;

        //console.log(user, this.props)
         
        return (
            <div className="dock">
                <p className="logo">
                    <img src="./img/logo-sem-texto.png" alt="Núcleo do Ser"/>
                </p>
                
                {menuOptions.map(m => {
                    return (
                        <div 
                            alt         ={m.id} 
                            className   ={(menu===m.id ?"item selected"+(wait ? " wait":"") :"item")} 
                            onClick     ={this.handleMenuClick} 
                            key         ={m.id}
                            title={m.text}
                         >
                            <Link to={m.link}>
                                <span className="img">
                                    {m.icon}
                                </span>
                            </Link>
                        </div>
                    )
                })}
                <div className="divider"/>
                <div className="user-options">
                    <Avatar className="avatar avatar-mediun" title={user.nome}>{user.nome ? getInicials(user.nome) : ""}</Avatar>
                    <Button onClick={this.props.obj.funcs.logOut} title="Sair">
                        <ExitToAppOutlined/>
                    </Button>
                    
                </div>

            </div>
        )
    }
}

export default Slidebar


/*
                <div className="info">
                    <div className="image">
                        <img src="./img/person.png" alt="" className={"invert sem"}/>
                    </div>
                    <div className="content">
                        <p className="user">{user.nome}</p>
                        <p className="type">{
                            //user.tipo
                        }</p>
                    </div>
                </div>
*/
import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'

import {dateInterval, formatDateHM} from '../functions'

import EventoC from '../forms/consulta'
import Eventos from '../forms/fastEvent'
import MyDialog from '../dialog'


import swal from 'sweetalert2'
import '../sweetAlert.scss'
import { Button, Dialog, List, DialogTitle, ListItem, Badge } from '@material-ui/core'
import { eventStatus, myServer } from '../../_constants'
import Axios from 'axios'

export class Card extends Component {
    state ={
        dados       : {},
        doctors     : [],
        update      : false,
        changeStatus: false
    }
    componentDidMount(){
        /*const app       = this,
            pps         = app.props,
            { dados }   = pps;
        if(dados) app.setState({dados})*/
    }
    static getDerivedStateFromProps(nextProps, prevState){
        const { dados, doctors }  = nextProps;
        return prevState && dados ? {dados, doctors} : null
    }

    handleFreeClick = () => {
        console.log(this.props.eventSel)
        this.setState({update:true})
    }

    handleClick = () => {
        const app       = this,
            stt         = app.state,
            { dados }   = stt,
            item        = dados.data,
            title       = item.status,
            end         = new Date(item.dtFim),
            start       = new Date(item.dtIni);
            
        let dt = dateInterval(start, end)
        
        swal.fire({
            title: `<strong>${title}</strong>`,
            type: 'info',
            html:   `<h5 style='padding:8px;text-align:left'><p'><b>Paciente</b>: ${item.paciente.nome}</p>`+
                    `<p><b>Prestador</b>: ${item.prestador.nome}</p>`+
                    `<p><b>Especialidade</b>: ${item.especialidade}</p>`+
                    `<p><b>Consulta</b>: ${item.consulta}</p>`+
                    `<p'><b>Horário</b>: ${dt}</p>`+
                    `<p><b>Observação</b>: ${item.observacao}</p></h5>`,
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: '<big><i class="fa fa-edit"></i> Editar</big>',
            cancelButtonText: '<big> &nbsp; <i class="fa fa-thumbs-up"></i> OK &nbsp; </big>',
            confirmButtonAriaLabel: ''
          }).then((result) => {
            if (result.value) {
                app.setState({update:true})
            }
          })
    }
    
    dialogExit      = () => {
        this.setState({update:false})
    }

    handleInsAgenda = () => document.getElementById('newAgenda').click()

    handleUpdAgenda = obj => {
        let doctor = {"_id": obj.prestador.id}

        this.dialogExit()
        this.props.funcEventsReload(doctor)
    }
    setWait = (wait) => {

    }
    handleNewStatus = async status => {
        const {dados} = this.state
        const {data} = await Axios.post(`${myServer}/agenda/compromisso/update/${dados.data._id}`, {status})
        
        if(data && data.agenda && data.agenda.ok && data.agenda.n){
            this.handleUpdAgenda(dados.data)
            this.hideStatusDialog()
        }
        //console.log(status, dados, data)
    }
    getDialog = () => {
        const { dados, doctors, update, changeStatus } = this.state
        let evnt = <></>

        if(update){
            let config = {
                showCancel:     true,
                showConfirm:    true,
                showClose:      true,
                cancelText:     "Cancelar",
                confirmText:    "Atualizar",
                titleText:      <></>,
                classAdd:       "col-xs-12 col-sm-11 col-md-10 col-lg-9"
            }
            
            if(dados && dados.data && dados.data.prestador){
                const dr = dados.data.prestador
                config.titleText = <> &nbsp; <FontAwesomeIcon icon={faCalendar} /> &nbsp; Alteração de Compromisso para <span className="cor5">{dr.nome}</span></>
                const cntt       = dados.data.paciente && dados.data.paciente.id ? <EventoC id={dados.data._id} prestadores={doctors} dataHora={dados.dtIni} funcSetWait={this.setWait} funcSuccess={this.handleUpdAgenda} submit={false}/> : <Eventos id={dados.data._id} prestadores={doctors} dataHora={dados.dtIni} funcSetWait={this.setWait} funcSuccess={this.handleUpdAgenda} submit={false}/>;
                evnt        = <MyDialog config={config} content={cntt}  exit={this.dialogExit} confirm={this.handleInsAgenda}/>
            }else if(this.props.eventSel && this.props.eventSel.length){
                const dt = this.props.eventSel[0], dr = dt.prestador;
                config.titleText = <> &nbsp; <FontAwesomeIcon icon={faCalendar} /> &nbsp; Alteração de Compromisso para <span className="cor5">{dr.nome}</span></>
                const cntt       = dt.paciente && dt.paciente.id ? <EventoC id={dt._id} prestadores={doctors} dataHora={dados.date} funcSetWait={this.setWait} funcSuccess={this.handleUpdAgenda} submit={false}/> : <Eventos id={dt._id} prestadores={doctors} dataHora={dados.date} funcSetWait={this.setWait} funcSuccess={this.handleUpdAgenda} submit={false}/>;
                evnt        = <MyDialog config={config} content={cntt}  exit={this.dialogExit} confirm={this.handleInsAgenda}/>
            }else{
                config.titleText = <> &nbsp; <FontAwesomeIcon icon={faCalendar} /> &nbsp; Inserção de Compromisso para <span className="cor5">{dados.doctor.nome}</span></>
                const cntt       = <Eventos id="" prestadores={[dados.doctor]} dados={{planos:dados.doctor.plano, status:{label:"Agendamento"}, dtIni: new Date(dados.date)}} dataHora={dados.date} funcSetWait={this.setWait} funcSuccess={this.handleUpdAgenda} submit={false}/>;
                evnt             = <MyDialog config={config} content={cntt}  exit={this.dialogExit} confirm={this.handleInsAgenda}/>
            }
        }else if(changeStatus){
            evnt = (
                <Dialog onClose={this.hideStatusDialog} open={changeStatus}>
                    <DialogTitle id="simple-dialog-title">ALTERAÇÃO DE SITUAÇÃO</DialogTitle>
                    <List>
                        {
                            eventStatus.filter(e => e.label !== dados.data.status).map((e,i) => 
                                (<ListItem key={i}>
                                    <Button fullWidth onClick={()=>{this.handleNewStatus(e.label)}}>{e.label}</Button>
                                </ListItem>)
                            )
                        }
                    </List>
                </Dialog>
            )
        }

        return evnt
    }

    hideStatusDialog = () => { this.setState({changeStatus:false}) }

    handleChangeStatus = () => this.setState({changeStatus:true})

    handleSelectEvent = (e) => { this.props.funcSelEvent(this.state.dados.data) }

    getContent = () => {
        const {dados} = this.state
        let      info = "",
              content = <></>

        switch(dados.type){
            case "free":
                if(dados.data && dados.data.events && dados.data.events.length){
                    const qtde =dados.data.events.length
                    if(qtde>1){
                        info = <div className="text-center"><b>{qtde}</b><small> MARCAÇÕES</small></div>
                    }else{
                        const {paciente} = dados.data.events[0]
                        info = <div className="text-center"><small>{paciente.nome}</small></div>
                    }
                    //info = <div className="text-center"><b>{qtde}</b><small> MARCAÇ{qtde>1 ? "ÕES" : "ÃO"}</small></div>
                    content = (<div
                                className="jsc-card busy aqua"
                                style={{height: dados.height+"em"}}
                                onClick={()=>{this.props.funcSelDay(this.props.id)}}
                                alt={"MARCAR ÀS "+dados.time}>
                                    { info }
                            </div>)
                }else{
                    info = <div className="text-center"><small>LIVRE</small></div>
                    content = (<div
                                className="jsc-card free aqua"
                                style={{height: dados.height+"em"}}
                                onClick={this.handleFreeClick}
                                alt={"MARCAR ÀS "+dados.time}>
                                    { info }
                            </div>)
                }
                break;
            case "off":
                const qtde = dados.data.events ? dados.data.events.length : 0
                const text = qtde ? <Badge badgeContent={qtde} color="error"><small>INDISPONÍVEL</small></Badge> : <small>INDISPONÍVEL</small> 
                info = <div className="text-center">{text}</div>
                content = <div className="jsc-card off" style={{height: dados.height+"em"}}>{info}</div>
                break;
            case "over":
                if(dados.data && dados.data.events && dados.data.events.length){
                    const qtde =dados.data.events.length
                    if(qtde>1){
                        info = <><b>{qtde}</b><small> MARCAÇÕES</small></>
                    }else{
                        const {paciente} = dados.data.events[0]
                        info = <><small>{paciente.nome}</small></>
                    }
                    info = (
                        <div
                            className="jsc-card-over"
                            onClick={()=>{this.props.funcSelDay(this.props.id)}}
                        >
                            {info}
                        </div>
                    )
                    content = ( 
                            <div className="jsc-card over" style={{height: dados.height+"em"}}>
                                { info }
                            </div>)
                }else{
                    info = <div className="text-center"><small>VENCIDO</small></div> 
                    content = <div className="jsc-card over" style={{height: dados.height+"em"}}>{info}</div>
                }
                break;
            case "busy":
                const   status  = dados.data.status,
                        nome    = <b>{dados.data.paciente.nome}</b>,
                        tipo    = <div>{formatDateHM(dados.data.dtIni)} &nbsp;<small>{status.toUpperCase()}</small></div>,
                        obs     = this.props.view === "day" ? <div className="text-center"><small className="child">{ (dados.data.prestador && dados.data.prestador.phone ? dados.data.prestador.phone+" " : "") + dados.data.observacao }</small></div> : <></>
                //console.log(dados.data)
                content = ( <div
                             //onClick={this.handleClick}
                             style={{height: dados.height+"em"}}
                             className={"jsc-card busy "+((/encaixe/gi).test(status) ? "" : "aqua")}
                            >
                                <div className="card-status">
                                    { tipo }
                                </div>
                                <div className="card-name">
                                    <small className="child">{ nome }</small>
                                </div>
                                {obs}
                                <div className="card-options">
                                    <Button onClick={this.handleSelectEvent}>REMARCAR<br/>PACIENTE</Button>
                                    <Button onClick={this.handleChangeStatus}>ALTERAR<br/>SITUAÇÃO</Button>
                                    <Button onClick={this.handleClick}>VER<br/>DETALHES</Button>
                                </div>
                            </div> )
                break;
            case "time":
                info = <div className="text-center">{dados.time}</div>
                content = <div className="jsc-card time scale" style={{height: dados.height+"em"}}>{info}</div>
                break;
            default:
                content = <div className="jsc-card time" style={{height: dados.height+"em"}}></div>
        }
        return content
    }

    render() {
        const evnt      = this.getDialog(),
            { date }   = this.state.dados,
            content     = this.getContent();
            
        return (
            <>
                {evnt}
                <div date={date} className="jsc-week-cel">
                    {content}
                </div>
            </>
        )
    }
}

export default Card

import React from 'react';
import { HashRouter , Route } from "react-router-dom" 
import Adm from './components/adm'
import Login from './components/login'

import {appVer, checkAppVerReload} from './components/functions'

export class App extends React.Component {
  componentDidMount(){
    const getAppVer = async () => {
        const serverAppVer = await appVer();
        checkAppVerReload(serverAppVer)
    }
    getAppVer()
  }

  render (){
    return (
      <HashRouter>
        <div className="Agenda">
          <Route exact path="/adm" component={Adm} />
          <Route exact path="/adm/:menu" component={Adm} />
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
        </div>
      </HashRouter>
    );
  }
}

export default App;

import React, { Component } from 'react'
import Axios from 'axios'

import {myServer} from '../../_constants'

import swal from 'sweetalert2'
import '../sweetAlert.scss'

Axios.defaults.withCredentials = true

const sendSweetAlert = (message, type='success', time=2000) => {
    swal.fire({
        type                : type,
        title               : message,
        showConfirmButton   : false,
        timer               : time
    })
}
export class FormAviso extends Component {
    state={
        _id             :   "",
        titulo          :   "",
        texto           :   "",
        err             :   {},
        publico         :   true
    }
    componentDidMount(){
    }
    
    onInputChange = (event) => {
        let {name, value}   = event.target,
            {err}           = this.state;
        if(value.trim().length>2 && err[name]) delete err[name]
        this.setState({ [name]: value, err });
    }

    handleSubmit = event  => {
        event.preventDefault()
        let {titulo, texto} = this.state,
            err         = {}, 
            hasError    = false;

        texto   = texto.trim()
        titulo  = titulo.trim()

        if(titulo.length<2){
            err["titulo"]=true; hasError=true
        }else if(texto.length<2){
            err["texto"]=true;  hasError=true
        }

        if(hasError) this.setState({err})
        else{
            this.props.funcSetWait(true)
            const insert = async () => {
                    const   {data}  = await Axios.post(`${myServer}/agenda/aviso/insert`,{ titulo, texto }),
                            {item}  = data;

                    if(item){
                        this.props.funcSuccess(item)
                        sendSweetAlert('Aviso enviado!')
                    }else{
                        this.props.funcSetWait(false)
                        sendSweetAlert('Envio não realizado!','error')
                    }
                }
            insert()
        }
    }
    render() {
        const { titulo,
                texto,
                err     }  = this.state
        
        return (
            <form id="formAviso" className="form box" onSubmit={this.handleSubmit}>
                <label className= {"col-xs-12"+(err.titulo ? " err": "")}>
                    <b>Título<sup>*</sup></b>
                    <input
                        name                ="titulo"
                        placeholder         =""
                        onChange            ={this.onInputChange}
                        value               ={titulo}
                    />
                </label>

                <label className={"col-xs-12 "+(err.texto ? " err": "")}>
                    <b>Texto<sup>*</sup></b>
                    <textarea 
                        name        = "texto"
                        placeholder =""
                        onChange    ={this.onInputChange}
                        value       ={texto}
                    />
                </label>
                <button type="submit" id="formAvisoSubmit" style={{display:"none"}}/>
            </form>
        )
    }
}

export default FormAviso

import Axios from 'axios'
import React, { Component } from 'react'
import { Redirect } from "react-router-dom"
import {isLogged, logOut} from './functions'
import Slidebar from './slidebar'
import Main from './main'

import './adm.scss'

export class Adm extends Component {
    state={
        user:{},
        redirect:"",
        menu: 0
    }
    componentDidMount(){
        let menu= this.props.match.params.menu
        menu = menu ? menu.toLowerCase() : ""
        switch(menu){
            case "agendamentos":    menu=2; break
            case "prestadores":     menu=3; break
            case "pacientes":       menu=4; break
            case "preferencias":    menu=5; break
            default: menu = 2
        }
        this.setState({menu})
        const logged = async () => {
            const user = await isLogged()
            if(user){
               Axios.defaults.withCredentials = true
               //let resp = await Axios.post(`${Constant.myServer}/users/shops`,{})
                this.setState({user, menu})
            }else this.setState({redirect:'/login'})
        }
        logged()
    }
    handleMenuClick = (op) => {
        op!==this.state.menu && this.setState({ menu: op })
    }
    logout = () => { console.log("oi")
        const goOut = async () => {
            const resp = await logOut()
            resp.data && resp.data.logout==="Ok" && this.setState({redirect:'/login'})
        }
        goOut()
    }
    render() {
        const {
            user,
            menu,
            redirect
         } = this.state,

         propsSlideBar = {
            data: {user, select: menu},
            funcs:{menuClick:this.handleMenuClick, logOut:this.logout}
         },
         
         propsMain = {
            data: {user, select: menu},
            funcs:{logOut:this.logout}
         };
         
        if(redirect.length)
            return <Redirect to={redirect} />

        return (
            <>
            <div className="board">
                <div className="menu"><Slidebar obj={propsSlideBar}/></div>    
                <Main obj={propsMain}/>           
            </div>
            
        </>
        )
    }
}

export default Adm

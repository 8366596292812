import React, { Component } from 'react'
import Axios from 'axios'
import NumberFormat from 'react-number-format'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faEdit, faEye } from '@fortawesome/free-regular-svg-icons'

import TooltipTrigger from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";

import './itemsList.scss'
import * as Constant from '../../_constants'
import { Phone, MailOutline } from '@material-ui/icons'
import { Divider } from '@material-ui/core'

export class ClientsList extends Component {
    state={
        clients:    []
    }
    componentDidMount() { //console.log("listar")
        const listar = async () => {
            const {data} = await Axios.get(`${Constant.myServer}/agenda/pacientes/list/all/?t=`+Math.random())
            if(data.pacientes==="Ok"){
                const clients = data.item
                this.setState({clients})
            }
        }
        listar()
    }
    
    showTollTip = (txt, btn) => {
        const tooltip = ({
            getTooltipProps,
            getArrowProps,
            tooltipRef,
            arrowRef,
            placement
         }) => {
            return (
            <div
                {...getTooltipProps({
                ref: tooltipRef,
                className: "tooltip-container" 
                })}
            >
                <div
                {...getArrowProps({
                    ref: arrowRef,
                    "data-placement": placement,
                    className: "tooltip-arrow"
                })}
                />
                <p>{txt}</p>
            </div>
            );
        }
        const trigger =  ({ getTriggerProps, triggerRef }) => {
            return (
                <span
                    {...getTriggerProps({
                    ref: triggerRef,
                    className: "trigger"
                    })}
                >
                   {btn}
                </span>
            );
        } 
    
        return(
            <TooltipTrigger  placement="top"  trigger="hover"  tooltip={tooltip}>
                {trigger}
            </TooltipTrigger>
        )
    }

    getClients = () => {
        const   pps         = this.props,
                stt         = this.state,
                {clients}   = stt,
                filter      = pps.filter ? Constant.semAcentos(pps.filter.toLowerCase()) : "";
        let     result     = <></>;
        
        if(clients.length){
            result = (
                    <>
                    {
                        clients.map(d => {
                            const   nome    = Constant.semAcentos(d.nome.toLowerCase()),
                                    plans   = JSON.stringify(d.plano).replace(/"|\[|\]/g,'').replace(/,/g,', ')
                            
                            if(filter==="" || filter===undefined || nome.indexOf(filter)>-1)
                             return (
                                <div key={d._id} className="col-sm-12 col-md-6 col-lg-4 list-item">
                                    <div className="item" onClick={()=>{pps.funcEditDoctor(d._id)}}>
                                        <div className="btns d-none">
                                            { this.showTollTip("Excluir",   <button><FontAwesomeIcon icon={faTrashAlt} /></button>) }
                                            { this.showTollTip("Visualizar",<button ><FontAwesomeIcon icon={faEye} /></button>) }
                                            { this.showTollTip("Editar",    <button onClick={()=>{pps.funcEditDoctor(d._id)}}><FontAwesomeIcon icon={faEdit} /></button>) }
                                        </div>
                                        <div className="image">
                                            <img src="./img/person.png" alt="" className={"invert sem"}/>
                                        </div>
                                        <h1 className="titulo">
                                            {d.nome}<br/>
                                            <small className="d-none">CPF:
                                                <b>
                                                    <NumberFormat 
                                                        value               ={d.cpf}
                                                        format              ={"###.###.###-##"}
                                                        displayType         ={"text"}
                                                    />
                                                </b>
                                            </small>
                                        </h1>
                                        <div className="dados" style={{flexDirection: "column"}}>
                                            <div className="mt-3 mb-3"><Divider /></div>
                                            <div className="painel">
                                            <b className="icon"><Phone /></b>
                                            <NumberFormat 
                                                value               ={d.celular}
                                                format              ={d.celular.length===10 ? "(##) ####-####" :"(##) # ####-####"}
                                                displayType         ={"text"}
                                            /> &nbsp;
                                            <NumberFormat 
                                                value               ={d.telefone}
                                                format              ={d.telefone.length===10 ? "(##) ####-####" :"(##) # ####-####"}
                                                displayType         ={"text"}
                                            />
                                            <br/>
                                            <b className="icon mail"><MailOutline /></b>{d.mail}
                                            

                                            </div> 
                                            <div className="painel d-none">
                                                <small>Plano</small>
                                                <b>{d.particular ? "Particular" : plans}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                         })
                    }
                </>
            )
        }
        return result
    }
    render() {
        return (
            <div id="itemsList" className="list-body">
                <div className="row">
                    {this.getClients()}
                </div>
            </div>
        )
    }
}

export default ClientsList

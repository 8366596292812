import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import Select from 'react-select'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faMobileAlt } from '@fortawesome/free-solid-svg-icons'

const convertDataToSelectReact = (obj, value, label) => { 
    let labels = []
    obj.map(i => {labels.push({value: i[value] || i._id, label: i[label]}); return true })
    return labels
}
const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : { ...base, backgroundColor: '#f2921d' };
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
  };
export class FormClient extends Component {
    state={
        _id             :   "",
        nome            :   "",
        cpf             :   "",
        telefone        :   '',
        celular         :   '',
        planos          :   [],
        mail            :   "",
        particular      :   true,
        masks           :   {telefone:true, celular:false} //true = telefone e false=celular
    }
    componentDidMount(){
        const {data} = this.props
        if(data){
            let stt = {...data}, plano=[];
            stt["masks"] = this.state.masks
            for(const e in stt.plano){
                plano.push({value:stt.plano[e], label:stt.plano[e]})
            }
            stt.plano = plano
            this.setState(stt)
        }
    }
    handleTypePhone = (event) => {
        let stt             = this.state,
            {masks}         = stt,
            {name, value}   = event.currentTarget;

        masks[name] = value==="1"
        stt.masks[name]!==masks[name] && this.setState({masks})
        
        //delete stt.masks
        this.props.funcUpd({...stt})
    }
    onParticularClick = event => {
        const {name}    = event.currentTarget,
                stt     = this.state;
        let particular = name==="sim" ? true : false
        if(stt.particular !== particular) {
            let {plano} = stt
            if(particular)
                plano = []
            this.setState({particular, plano})
            this.props.funcUpd({...this.state, particular, plano})
        }
    }
    onInputChange = (event) => {
        let {name, value, alt} = event.target
        //console.log(name,value, event.target)
        if(alt==="tel") value = value.replace(/\(|\)|\s|-/g,'')
        else if(name==="cpf") value = value.replace(/\.|-/g,'')
        else if(name==="crm") value = value.replace(/\./g,'')
        this.setState({ [name]: value });
        this.props.funcUpd({...this.state, [name]: value })
    }
    onPlanosChange = (value, { name }) => { 
        this.setState({ [name]: value });
        this.props.funcUpd({...this.state, [name]: value })
      }
    render() {
        const { lists, err }    = this.props,
            {   nome,
                cpf,
                telefone,
                celular,
                plano,
                mail,
                particular,
                masks         } = this.state

        
        return (
            <form id="form" className="box">
                <label className= {"col-xs-12 col-sm-6"+(err.nome ? " err": "")}>
                    <b>Nome<sup>*</sup></b>
                    <input
                        name                ="nome"
                        placeholder         =""
                        onChange            ={this.onInputChange}
                        value               ={nome}
                    />
                </label>
                <label className={"col-xs-12 col-sm-6"+(err.cpf ? " err": "")}>
                    <b>CPF<sup>*</sup></b>
                    <NumberFormat 
                        name                ="cpf"
                        alt                 ="text"
                        format              ={"###.###.###-##"}
                        onChange            ={this.onInputChange}
                        value               ={cpf}
                        //prefix              ="R$ "
                        //decimalSeparator    =","
                        //thousandSeparator   ={"."}
                    />
                </label>
                <label className= {"col-xs-12 col-sm-6"+(err.mail ? " err": "")}>
                    <b>E-mail<sup></sup></b>
                    <input 
                        name                ="mail"
                        type                ="text"
                        onChange            ={this.onInputChange}
                        value               ={mail}
                    />
                </label>
                <label className= {"col-xs-6 col-sm-2"}>
                    <b>Particular?</b>
                    <div className="btn-group btn-group-justified" role="group" aria-label="particular">
                        <div class="btn-group" role="group">
                            <button
                                type="button"
                                name="sim"
                                onClick={this.onParticularClick}
                                className={"btn " + (particular? "btn-success" : " btn-default")}>SIM</button>
                        </div>
                        <div class="btn-group" role="group">
                            <button
                                type="button"
                                name="nao"
                                onClick={this.onParticularClick}
                                className={"btn " + (particular? "btn-default" : "btn-danger")}>NÃO</button>
                        </div>
                    </div>
                </label>
                <label className={"col-xs-6 col-sm-4"+(err.plano ? " err": "")}>
                    <b>Plano<sup>*</sup></b>
                    <Select
                        isMulti
                        value                   ={plano}
                        styles                  ={styles}
                        isDisabled              ={particular}
                        onChange                ={this.onPlanosChange}
                        placeholder             ="Selecione..."
                        name                    ="plano"
                        className               ="mySelect"
                        classNamePrefix         ="_"
                        noOptionsMessage        ={() => "Opções não disponíveis"}
                        options                 ={convertDataToSelectReact(lists.planos,"label","label")}
                    />
                </label>
                
                <label className={"col-xs-12 col-sm-6"+(err.celular ? " err": "")}>
                    <b>Telefone<sup>*</sup></b>
                    <div className="input-group">
                        <div className="btns">
                            <button
                                type        ="button"
                                name        ="celular"
                                value         ="1"
                                onClick     ={this.handleTypePhone}
                                className   ={"btn "+(masks.celular?"btn-success":"btn-default")}
                             >
                                <FontAwesomeIcon icon={faPhoneAlt} />
                            </button>
                            <button
                                type        ="button"
                                name        ="celular"
                                value         ="0"
                                onClick     ={this.handleTypePhone}
                                className   ={"btn "+(masks.celular?"btn-default":"btn-success")}
                             >
                                <FontAwesomeIcon icon={faMobileAlt} />
                            </button>
                        </div>
                        <NumberFormat 
                            name                ="celular"
                            alt                 ="tel"
                            format              ={ masks.celular ? "(##) ####-####" : "(##) # ####-####"}
                            onChange            ={this.onInputChange}
                            value               ={celular}
                            className           ="form-control"
                            //prefix              ="R$ "
                            //decimalSeparator    =","
                            //thousandSeparator   ={"."}
                        />
                    </div>
                </label>
                <label className={"col-xs-12 col-sm-6"}>
                    <b>Telefone<sup></sup></b>
                    <div className="input-group">
                        <div className="btns">
                            <button
                                type        ="button"
                                name        ="telefone"
                                value         ="1"
                                onClick     ={this.handleTypePhone}
                                className   ={"btn "+(masks.telefone?"btn-success":"btn-default")}
                             >
                                <FontAwesomeIcon icon={faPhoneAlt} />
                            </button>
                            <button
                                type        ="button"
                                name        ="telefone"
                                value         ="0"
                                onClick     ={this.handleTypePhone}
                                className   ={"btn "+(masks.telefone?"btn-default":"btn-success")}
                             >
                                <FontAwesomeIcon icon={faMobileAlt} />
                            </button>
                        </div>
                        <NumberFormat 
                            name                ="telefone"
                            alt                 ="tel"
                            format              ={ masks.telefone ? "(##) ####-####":"(##) # ####-####"}
                            onChange            ={this.onInputChange}
                            value               ={telefone}
                            //prefix              ="R$ "
                            //decimalSeparator    =","
                            //thousandSeparator   ={"."}
                            className="form-control"
                        />
                    </div>
                </label>
            </form>
        )
    }
}

export default FormClient

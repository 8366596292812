import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const defaultConfig ={
    showCancel:     true,
    showConfirm:    true,
    showClose:      true,
    cancelText:     "Cancelar",
    confirmText:    "Ok",
    titleText:      "Título",
    classAdd:       ""
}
export class Dialog extends Component {
    handleClose = () => { 
        this.props.exit()
     }
     handleConfirm = () => {
        this.props.confirm()
      }
    render() {
        const styles ={
                cortina: {
                    display: "flex",
                    position: "fixed",
                    top: "0",
                    left: "0",
                    overflowY: "scroll",
                    width: "100%",
                    height: "100%",
                    background: "rgba(0,0,0,0.4)",
                    zIndex: "999",
                    justifyContent: "center",
                    alignItems: "flex-start"
                }, info: {
                    background: "#FFF",
                    padding: "0",
                    border: "0 solid #FFF",
                    borderRadius: "5px",
                    boxShadow: "0 0 5px #999",
                    margin: "3% auto"
                }, header: {
                    color: "#333",
                    minWidth: "300px",
                    fontSize: "1.2em",
                    padding: "13px 10px",
                    borderBottom: "1px solid #DDD"
                }, footer: {
                    color: "#333",
                    padding: "13px 10px",
                    borderTop: "1px solid #DDD",
                    display: "flex",
                    justifyContent: "flex-end"
                }, ml2:{
                    marginLeft:"8px"
                }, button:{
                    padding:"10px",
                    background: "transparent",
                    border: "1px solid #AAA",
                    borderRadius: "3px",
                    marginLeft:"8px"
                }, content:{
                    color: "#000",
                    padding: "1em",
                }
            };

        //Substituindo configurações de exibição
        let config=defaultConfig;
        if(this.props.config)
            for (const key in this.props.config)
                if (config.hasOwnProperty(key))
                    config[key]=this.props.config[key]

        //console.log(config,styles)

        let btnCancel, btnConfirm;
        if(config.showCancel) btnCancel = <button className="exit" style={styles.button} onClick={this.handleClose}>{config.cancelText}</button>
        if(config.showConfirm) btnConfirm = <button className="confirm" style={styles.button}  onClick={this.handleConfirm}>{config.confirmText}</button>
        
        return (
            <div id="dialog" style={styles.cortina}>
                <div style={styles.info} className={config.classAdd}>
                    <header style={styles.header}>
                        <b>{config.titleText}</b>
                        <span
                            className="close" 
                            onClick={this.handleClose}
                            style={{display: config.showClose ? undefined : "none"}}>
                                <FontAwesomeIcon icon={faTimes} /></span>
                    </header>
                    <section style={styles.content}>
                        {this.props.content}
                    </section>
                    <footer style={styles.footer}>
                        {btnCancel}
                        {btnConfirm}
                    </footer>
                </div>
            </div>
        )
    }
}

export default Dialog

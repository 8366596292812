import React, { Component } from 'react'
import Axios from 'axios'
import SelectR from 'react-select'
import CreatableSelect from 'react-select/creatable'

//import { withSwalInstance } from 'sweetalert2-react';
import swal from 'sweetalert2';
import '../sweetAlert.scss'

//import TextField from '@material-ui/core/TextField'
import {validPhone, convertDataToSelectReact, myMask, getRecurrency, formatDateDMY} from '../functions'
import {myServer, eventStatus, timeSteps, weekDayFull} from '../../_constants'

import './datetime.scss'

import Datetime from 'react-datetime'
import moment, { isDate } from 'moment'
import { TextField, FormControlLabel, Radio, RadioGroup, FormControl } from '@material-ui/core';
require("moment/min/locales.min");
moment.locale('pt-br')

//require('react-datetime')

Axios.defaults.withCredentials = true

export class FastEvent extends Component {
    state={
        dtIni:              null,
        hrIni:              null,
        dtFim:              null,
        prestador:          null,
        especialidade:      null,
        paciente:           null,
        consulta:           null,
        phone:              '',
        observacao:         '',
        status:             {label:"Lista de Espera"},
        tempo:              {value: 30, label: '30 min'},
        recorrencia:        {tipo:"", fim: null},
        err:                {},
        especialidades:     [],
        pacientes:          [],
        prestadores:        [],
        planos:             [],
        horarios:           [],
        tipos:              eventStatus,
        intervalos:         timeSteps,
        flags:              {
            submit:             true,
            validado:           false,
            inserted:           false,
            enviando:           false
        }
    }

    componentDidMount(){
        let stt       = this.status,
            pps         = this.props,
             {  id,
                dataHora,
                dados,
                submit,
                hrIni,
                dtIni } = pps,
            dt          = new Date(),
            y           = dt.getFullYear(),
            M           = (dt.getMonth()+1),
            d           = dt.getDate(),
            h           = dt.getHours(),
            m           = dt.getMinutes(),
            a           = m + 5 - m % 5,
            dtNow       = new Date(y+'-'+M+'-'+d+' '+( a<60 ? h : (h<23 ? h+1 : 0))+':'+(a<60 ? a : 0) );
        let prestadores = [],
            pacientes   = [],
            planos      =  [{label:"Particular"}];
        
        const espec = async () => {
            let resp = await Axios.get(`${myServer}/agenda/pacientes/list/all`)
            pacientes = resp.data.item ? resp.data.item.sort((a,b) => {if(a.nome<b.nome){return -1}else if(a.nome>b.nome){return 1}else{return 0}}) : []
            //resp = await Axios.get(`${myServer}/agenda/planos/list`) //resp.data.item ? convertDataToSelectReact(resp.data.item.) : []
            //resp.data.item && resp.data.item.map(i=> planos.push({label:i.nome}))

            if(pps && pps.prestadores && pps.prestadores.length){
                prestadores = pps.prestadores
            }else{
                resp  = await Axios.get(`${myServer}/agenda/prestadores/list/all`)
                prestadores = resp.data.item ? resp.data.item : []
            }

            if(id && id.length){
                resp  = await Axios.get(`${myServer}/agenda/compromisso/${id}`)

                const prest = prestadores.filter(p=> resp.data.agenda.prestador.id===p._id)
                if(prest.length){  
                    prest[0].plano.map(p => planos.push({label:p}))
                 }
                const intervalos = this.getHorarios(dtIni,prest[0])
                
                let a = resp.data.agenda,
                    t = ((new Date(a.dtFim)).getTime() - (new Date(a.dtIni)).getTime())/60000,
                    s = {
                        id: id,
                        consulta: {label: a.consulta},
                        status: {label: a.status},
                        especialidade: {label: a.especialidade},
                        phone:  a.paciente.phone,
                        paciente: a.paciente.id ? {value: a.paciente.id, label: a.paciente.nome} : a.paciente.nome , //+ ' - CPF: '+a.paciente.cpf
                        prestador: {value: a.prestador.id, label: a.prestador.nome + ' - CRM: '+a.prestador.crm},
                        observacao: a.observacao,
                        recorrencia: a.recorrencia,
                        dtIni: new Date(dataHora ? dataHora : a.dtIni),
                        hrIni: new Date(dataHora ? dataHora : a.dtIni),
                        tempo: {value: t, label: t<60 ? t+" min" : "1 hora" },
                        planos, prestadores, pacientes, intervalos, ...dados, flags:{...stt, submit}
                    }
                //console.log(resp.data.agenda, s)
                this.setState(s)
            }else{
                //console.log("pps.prestadores",pps.prestadores)
                dados["prestador"] = {value: pps.prestadores[0]._id, label: pps.prestadores[0].nome + ' - CRM: '+pps.prestadores[0].crm}
                dados["especialidade"] = {label: pps.prestadores[0].especialidade[0]}
                dados && dados.planos && dados.planos.length && dados.planos.map(p => planos.push({label: p}) ) 
                dados["planos"]=planos
                planos=[]; pps.prestadores[0].especialidade.map(p => planos.push({label: p}) ) 
                dados["especialidades"] = planos
                planos=pps.prestadores[0].horarios.tempoConsulta
                dados["tempo"]={value: planos, label: planos<60 ? planos+" min" : "1 hora"}
                if(!dados.consulta) dados["consulta"] = {label:"Particular"}
                
                if(dtIni && hrIni){ 
                    const intervalos = this.getHorarios(dtIni,pps.prestadores[0])
                    this.setState({dtIni, hrIni, prestadores, pacientes, intervalos,...dados, flags:{...stt, submit}})
                }else{ 
                    dtIni = dados.dtIni && dados.dtIni != "Invalid Date" ? dados.dtIni : dtNow
                    hrIni = dtIni
                    const intervalos = this.getHorarios(dtIni,pps.prestadores[0])
                    this.setState({prestadores, pacientes, intervalos,...dados, dtIni, hrIni, flags:{...stt, submit}})
                }    
            }
            
        }
        espec()
    }

    getHorarios = (data,prestador) =>{
        let range = []
        try{
            const {tempoConsulta, intervalos } = prestador.horarios,
                horarios = intervalos.fixo[0].range.filter(r => r.day === data.getDay() )
            
            if(horarios.length){
                let ini, fim;
                horarios.forEach( h => {
                    ini = data.toString()
                    fim = data.toString()

                    ini = ini.substr(0,16) + h.ini + ":00" + ini.substr(24,ini.length)
                    fim = ini.substr(0,16) + h.fim + ":00" + ini.substr(24,ini.length)
                    
                    ini = new Date(ini)
                    fim = new Date(fim)
                    while(ini<fim){
                        range.push({label: ini.toString().substr(16,5)})
                        ini = new Date(ini.getTime()+tempoConsulta*60000)
                    }
                })
            }
        }catch(e){}
        //console.log(data.getDay(),horarios, intervalos)/
        return range
    }

    handleDoctorChange = obj => {
        try{
            let { prestadores }   = this.state,
                    prestador       = prestadores.filter(p => obj.value === p._id.toString());
            let   especialidades    = [], 
                  {especialidade}   = this.state;
            
            if(prestador.length && prestador[0].especialidade){
                prestador[0].especialidade.map(e => {
                    especialidades.push({label:e})
                })
                especialidade = especialidades[0]
            }
            prestador = obj
            
            this.setState({especialidades, especialidade, prestador})
        }catch(e){
            this.setState({especialidades:[], especialidade:null})
        }
    }

    handleSelectRChange = (obj, event) => {
        const {name} = event
        this.setState({[name]:obj})
    }

    handleIniDTchangeRec = event => this.setState({recorrencia:{...this.state.recorrencia, fim: event && event.value._isValid ? event.value._d : this.state.dtIni}})

    handleSelectRChangeRec = (obj, event) => this.setState({status:{label:"Agendamento"}, recorrencia:{...this.state.recorrencia, [event.name]:obj ? obj.label : ""}}) 

    setInputTelChange = () =>{
        const el = document.getElementsByClassName('swal2-input')[0]
        el.setAttribute("maxlength", 16)
        el.addEventListener("keyup", this.addInputTelMask, false);
        
    }

    addInputTelMask = () => {
        const el = document.getElementsByClassName('swal2-input')[0]
        el.value = myMask(el.value,"(99) 9 9999-9999")
        //console.log("mask",el.value, myMask(el.value,"(99) 9 9999-9999"))
    }

    handleClientChange = (obj) => { //console.log(obj, action)
        try{
            let {   pacientes,
                    consulta    }   = this.state
                
                let paciente = obj.target.value
                this.setState({paciente})
            
                
        }catch(e){
            this.setState({consulta:null})
        }
    }

    handleClientSelChange = (obj,{action}) => { //console.log(obj, action)
        try{
            let {   pacientes,
                    consulta    }   = this.state
            //console.log(obj,action)
            
            if(action==="clear"){
                this.setState({paciente:"", paciente_id:""})
            }else if(action==="create-option"){
                if(obj.label){
                    this.setState({paciente:obj.label, paciente_id:""})
                }
            }else if(action==="select-option"){
                if(obj.value){ 
                    let paciente = pacientes.filter(p => obj.value === p._id.toString())[0]
                    if(paciente){
                        this.setState({paciente_id: paciente._id, paciente:paciente.nome, phone:paciente.celular && paciente.celular.length ? paciente.celular :  paciente.telefone})
                    }
                }
            }
        }catch(e){
            this.setState({consulta:null})
        }
    }

    handleIniDTchange = event => this.setState({dtIni: event && event.value._isValid ? event.value._d : this.state.dtIni})

    handleTAchange = (event) => {
        const { name, value} = event.target
        this.setState({[name]:value})
    }

    handleIniHRchange = event =>{
        if(event && event._isValid){
            let hr = event._d.toString().substr(16,8),
                dt = new Date(this.state.dtIni.toString().replace(/..\:..\:../,hr))
            this.setState({hrIni: event._d, dtIni: dt})
        }
    }

    handleIniDTchange2 = event => {
        if(event && event.value._isValid){
            let hr = "00:00:00",
                dt = new Date(event.value._d.toString().replace(/..\:..\:../,hr)),
                intervalos = this.getHorarios(dt, this.state.prestadores[0]);

            this.setState({hrIni: null, dtIni: dt, intervalos})
            //console.log(dt,intervalos,this.state.prestadores[0])
        }
        //this.setState({dtIni: event && event.value._isValid ? event.value._d : this.state.dtIni})
    }

    handleIniHRchange2 = event =>{
        let hr = "00:00:00"
        if(event && event.label)
            hr = event.label+":00"

        let dt = new Date(this.state.dtIni.toString().replace(/..\:..\:../,hr))
        this.setState({hrIni: hr === "00:00:00" ? null : dt, dtIni: dt})
    }

    insertInAgenda =  objeto => {
        const   stt = this.state,
                pps = this.props;
                
        pps.funcSetWait(true)
        
            try{
                let start = [objeto.dtIni], end = [objeto.dtFim], resp;
                if(objeto.recorrencia && objeto.recorrencia.tipo!==""){
                    start = getRecurrency(objeto.recorrencia.tipo, objeto.dtIni, objeto.recorrencia.fim)
                    end = getRecurrency(objeto.recorrencia.tipo, objeto.dtFim, objeto.recorrencia.fim)
                }
                
                start.forEach(async (d, i) => {
                    const insert = async () => {
                        let obj = {...objeto}
                        obj.dtIni = d
                        obj.dtFim = end[i]
                        
                        resp =  await Axios.post(`${myServer}/agenda/compromisso/insert`,obj)
                        if(i+1===end.length){
                            const {data} = resp
                            if(data && data.agenda){
                                this.setState({flags:{...stt.flags, validado:false, inserted:true}})
                                pps.funcSuccess(data.agenda)
                            }
                            pps.funcSetWait(false)
                        }
                    }
                    insert()
                })
            }catch(e){}
    }
    
    updateAgenda =  obj => {
        const   stt = this.state,
                pps = this.props;
                
        pps.funcSetWait(true)
        const insert = async () => {
            try{
                const {data} = await Axios.post(`${myServer}/agenda/compromisso/update/${stt.id}`,obj)
                if(data && data.agenda){
                    this.setState({flags:{...stt.flags, validado:false, inserted:true}})
                    pps.funcSuccess({...obj, '_id':stt.id })
                }
            }catch(e){}
            pps.funcSetWait(false)
        }
        insert()
    }

    handleSubmit = () => {
        this.setState({err:{},flags:{...this.state.flags, inserted:false, validado:false}})

        let stt = this.state,
            {
                recorrencia,
                prestadores,
                consulta,
                dtIni,
                phone,
                especialidade,
                observacao,
                paciente,
                paciente_id,
                prestador,
                status,
                tempo       }   = stt,
            dtFim               = new Date(),
            data                = {},
            err                 = {},
            ok                  = true;

        consulta        = consulta && consulta.label            ? consulta.label        : ""
        especialidade   = especialidade && especialidade.label  ? especialidade.label   : ""
        status          = status && status.label                ? status.label          : ""        

        
        if(!paciente || paciente.trim().length<2) paciente = null
        if(!phone || phone.trim().length<16) phone = null

        if(prestador && prestador.value){
            prestador = prestadores.filter(p => prestador.value === p._id.toString())[0]
            prestador = {id: prestador._id, nome: prestador.nome, crm: prestador.crm}
        }else prestador = null

        // verificação do preenchimento dos campos
        if(consulta==="")       err["consulta"]         = true
        if(especialidade==="")  err["especialidade"]    = true
        if(status==="")         err["status"]           = true
        if(paciente===null)     err["paciente"]         = true
        if(prestador===null)    err["prestador"]        = true
        if(tempo===null)        err["tempo"]            = true
        if(dtIni===null)        err["dtIni"]            = true
        if(phone===null)        err["phone"]            = true

        if( recorrencia.tipo!=="" && !this.state.id)
            if( !isDate(recorrencia.fim) )
                err["termino"] = true

        //VERIFICANDO EXISTÊNCIA DE ERROS
        for(let e in err){ ok=false; break }

        if(ok){
            dtFim = new Date(dtIni.getTime()+tempo.value*60000)
            data = {
                consulta,
                especialidade,
                status,
                paciente:{nome: paciente, phone},
                prestador,
                recorrencia:{...recorrencia, codigo: (new Date().getTime())},
                dtIni,
                dtFim,
                observacao
            }
            if(paciente_id && paciente_id.length===24)
                data.paciente["id"] = paciente_id
            //console.log( data)
            if(this.state.id){
                delete data.recorrencia
                this.updateAgenda(data)
            }else this.insertInAgenda(data)
        }else {
            this.setState({err, flags:{...this.state.flags, validado:true}})
            swal.fire({
                //position: 'top-start',
                type: 'error',
                title: 'Verifique os campos obrigatórios',
                showConfirmButton: false,
                timer: 3000
            })
        }

        
    }

    handlePhoneChange = (e) => {
        const {value} = e.target
        this.setState({phone: myMask(value,"(99) 9 9999-9999")})
    }
    handleStatusChange = (e) => { this.setState({status:{label: e.currentTarget.value}}) }

    render() {
        //const {prestadores}     = this.props
        const {
                paciente,
                pacientes,
                status,
                observacao,
                dtIni,
                hrIni,
                phone,
                //prestador,
                intervalos,
                prestadores,
                recorrencia,
                id,
                err,
                flags     }     = this.state
        const timeConfig        ={
            hours: { min: 8, max: 20 },
            minutes: { step: 5 }
        }, doctor = prestadores[0]
        
        //console.log(prestadores)
        if(flags.inserted)
            swal.fire({
                //position: 'top-start',
                type: 'success',
                title: id && id.length ? 'Dados Atualizados' : 'Dados enviados',
                showConfirmButton: false,
                timer: 2000
            })

        const descRecorrencia = recorrencia && recorrencia.tipo ? "Ocorre "+ recorrencia.tipo.toLowerCase() + " a cada "+weekDayFull[(new Date(dtIni)).getDay()] + ( recorrencia.fim ? " até "+formatDateDMY(new Date(recorrencia.fim)) : "") : ""

        let statusOptions = <>
                <FormControlLabel
                    value="Agendamento"
                    control={<Radio color="primary" />}
                    label="Agendamento"
                    labelPlacement="end"
                />
                <FormControlLabel
                    value="Encaixe"
                    control={<Radio color="primary"/>}
                    label="Encaixe"
                    labelPlacement="end"
                    style={{visibility: doctor && doctor.encaixe ? "visible" : "hidden"}}
                />
                <FormControlLabel
                    value="Lista de Espera"
                    control={<Radio color="primary" />}
                    label="Lista de Espera"
                    labelPlacement="end"
                />
            </>
        if(("Agendamento, Encaixe, Lista de Espera").indexOf(status.label)<0){
            statusOptions = <>
                <FormControlLabel
                    value={status.label}
                    control={<Radio color="primary" />}
                    label={status.label}
                    labelPlacement="end"
                />
            </>
        }
        
        //console.log(descRecorrencia)
        return (
            <form id="form" className="box" style={{background:'#FFF', color:'#000', padding: '1em'}}>
                <label className={"col-xs-12 col-sm-6"+(flags.validado ? (err.paciente ? ' err' : ' ok') :'')}><b>Paciente<sup>*</sup></b>
                    <input 
                        type                ="text"
                        value               ={paciente}
                        onChange            ={this.handleClientChange}
                        style               ={{display:"none"}}
                    />
                    <CreatableSelect
                        cashOptions
                        className           ="mySelect col-lg-3 text-left"
                        classNamePrefix     ="_"
                        placeholder         ="Selecione o Paciente"
                        value               ={paciente ? {label:paciente} : null}
                        isSearchable        ={true}
                        isClearable         ={true}
                        onChange            ={this.handleClientSelChange}
                        noOptionsMessage    ={() => "Opções não disponíveis"}
                        options             ={convertDataToSelectReact(pacientes)} //,"cpf"
                    />
                </label>

                <label className={"col-xs-12 col-sm-6"+(flags.validado ? (err.phone ? ' err' : ' ok') :'')}><b>Celular<sup>*</sup></b>
                    <input 
                        type="text"
                        value               ={phone}
                        onChange            ={this.handlePhoneChange}
                    />
                </label>
                
                <label className={"col-xs-12 col-sm-6"+(flags.validado ? (err.status ? ' err' : ' ok') :'')}><b style={{marginBottom:"1.2em"}}>Situação<sup>*</sup></b>
                
                    <RadioGroup aria-label="position" name="position" value={status.label} onChange={this.handleStatusChange} row style={{justifyContent:"space-between"}}>
                        {statusOptions}
                    </RadioGroup>
                </label>
                
                <label className={"col-xs-12 col-sm-6"+(flags.validado ? (err.dtIni ? ' err' : ' ok') :'')} style={{display: status.label!=="Agendamento" ? null : "none"}}><b>Data e Hora<sup>*</sup></b>
                    <Datetime 
                        timeFormat          ={true}
                        locale              ="pt-br" 
                        className           ="dtIni"
                        value               ={new Date(dtIni)}
                        timeConstraints     ={timeConfig}
                        onChange            ={this.handleIniDTchange}
                        inputProps={{disabled: status.label==="Lista de Espera" }}
                    />
                </label>
                
                <span className="col-xs-12 col-sm-6" style={{padding:0, display: status.label==="Agendamento" ? null : "none"}}>
                    <label className={"col-xs-6"+(flags.validado ? (err.dtIni ? ' err' : ' ok') :'')}><b>Data<sup>*</sup></b>
                        <Datetime 
                            timeFormat          ={false}
                            locale              ="pt-br" 
                            className           ="dtIni"
                            value               ={new Date(dtIni)}
                            timeConstraints     ={timeConfig}
                            onChange            ={this.handleIniDTchange2}
                            inputProps={{disabled: status.label==="Lista de Espera" }}
                        />
                    </label>
                    <label className={"col-xs-6"+(flags.validado ? (err.dtIni ? ' err' : ' ok') :'')}><b>Hora<sup>*</sup></b>
                        <SelectR
                            cashOptions
                            name                ="hora"
                            className           ="mySelect col-lg-3 text-left"
                            classNamePrefix     ="_"
                            placeholder         ="Selecione uma opção"
                            //isSearchable        ={true}
                            isClearable         ={true}
                            //isDisabled          ={this.state.id}
                            onChange            ={this.handleIniHRchange2}
                            noOptionsMessage    ={() => "Opções não disponíveis"}
                            value               ={hrIni ? {label: hrIni.toString().substr(16,5)} : null}
                            options             ={intervalos}
                        />
                    </label>
                </span>

                <div style={{width:"100%", display: this.state.id && this.state.id.length===24 || status.label!=="Agendamento" ? "none" : null}}>
                    <label className={"col-xs-12 col-sm-6"}><b>Repetir Consulta<sup></sup></b>
                        <SelectR
                            cashOptions
                            name                ="tipo"
                            className           ="mySelect col-lg-3 text-left"
                            classNamePrefix     ="_"
                            placeholder         ="Selecione uma opção"
                            //isSearchable        ={true}
                            isClearable         ={true}
                            //isDisabled          ={this.state.id}
                            onChange            ={this.handleSelectRChangeRec}
                            noOptionsMessage    ={() => "Opções não disponíveis"}
                            value               ={recorrencia && recorrencia.tipo ? {label:recorrencia.tipo} : null}
                            options             ={[{label:"Semanalmente"},{label:"Quinzenalmente"},{label:"Mensalmente"}]}
                        />
                    </label>

                    <label className={"col-xs-12 col-sm-6"+(flags.validado ? (err.termino ? ' err' : ' ok') :'')}><b>Até<sup>*</sup></b>
                        <Datetime 
                            timeFormat          ={false}
                            locale              ="pt-br" 
                            className           ="dtIni"
                            value               ={recorrencia &&  recorrencia.fim ? new Date(recorrencia.fim) : null}
                            onChange            ={this.handleIniDTchangeRec}
                            inputProps={{disabled: recorrencia && recorrencia.tipo.length===0 }}
                        />
                    </label>
                </div>

                <div className="col-xs-12" style={{marginBottom:'1em'}}>
                    <b>{descRecorrencia}</b>
                </div>

                <label className="col-xs-12"><b>Observações<sup></sup></b>
                    <textarea name="observacao" value={observacao} onChange={this.handleTAchange}/>
                </label>

                <label className="col-xs-12" style={{display: 'none'}}>
                    <button type="button" id="newAgenda" className="submit" onClick={this.handleSubmit}>CADASTRAR</button>
                </label>
            </form>
        )
    }
}

export default FastEvent
/*

                <label className={"col-xs-12 col-sm-6"+(flags.validado ? (err.prestador ? ' err' : ' ok') :'')}><b>Prestador<sup>*</sup></b>
                    <SelectR
                        cashOptions
                        className           ="mySelect col-lg-3 text-left"
                        classNamePrefix     ="_"
                        placeholder         ="Selecione um Médico"
                        value               ={prestador}
                        isSearchable        ={true}
                        isClearable         ={true}
                        onChange            ={this.handleDoctorChange}
                        noOptionsMessage    ={() => "Opções não disponíveis"}
                        options             ={convertDataToSelectReact(prestadores, "crm")} />
                </label>
                
                <label className={"col-xs-12 col-sm-6"+(flags.validado ? (err.hrIni ? ' err' : ' ok') :'')}><b>Hora<sup>*</sup></b>
                    <Datetime 
                        dateFormat          ={false}
                        locale              ="pt-br" 
                        className           ="hrIni"
                        value               ={new Date(hrIni)}
                        timeConstraints     ={timeConfig}
                        onChange            ={this.handleIniHRchange}
                    />
                </label>
*/